<template>
    <div>
        <div style="position:relative; top:200px; text-align:center; font-size: 30px; font-weight: 700; color: #03a9f4">
            Oops! You are stuck.<br>
        </div>
        <div style="position:relative; top:220px; text-align:center; font-size: 17px; font-weight: 500; line-height: 1.6;">
            Something's wrong!<br>
            It looks as though we've broken something on our system.<br>
            Don't panic, we are fixing it! Please come back in a while.<br><br>
            <button class="jazeButton jazeButton--primary" type="button" @click="redirect">Return Home</button>
        </div>
            
    </div>
</template>

<script setup>

    function redirect(){
        window.location.href="/";
    }

</script>
