<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader">
          <div class="unifiSettingsHeader__left">
            <div class="unifiSettingsHeader__title">Billing Period</div>
          </div>
          <div class="unifiSettingsHeader__right">
            <div class="appActionBarFilters py-1">
              <div class="appActionBarFilters">
                <button @click="billingPeriodAddModal=true" class="jazeButton jazeButton--primary" title="Add Billing Period">
                  <span class="jaze-icon--plus"></span> &nbsp;Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <fieldset class="appFieldset appFieldset--quiet">
          <div class="unifiSettingsSection">
            <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
              <thead>
                <tr>
                  <th>
                    <span>Value</span>
                  </th>
                  <th>
                    <span>Units</span>
                  </th>
                  <th>
                  </th>
                  <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                </tr>
              </thead>
              <tbody class="jazeTableBody">
                <tr v-if="billingPeriodDetails.periods != undefined && billingPeriodDetails.periods.length !=0" v-for="period in billingPeriodDetails.periods">
                  <td>
                    <span> {{period.value}} </span>
                  </td>
                  <td>
                    <span v-if="period.periodUnitId == 1 && period.value == 1"> month </span>
                    <span v-else-if="period.periodUnitId == 1 && period.value != 1"> months </span>
                    <span v-else-if="period.periodUnitId == 2 && period.value == 1"> week </span>
                    <span v-else-if="period.periodUnitId == 2 && period.value != 1"> weeks </span>
                    <span v-else-if="period.periodUnitId == 3 && period.value == 1"> day </span>
                    <span v-else-if="period.periodUnitId == 3 && period.value != 1"> days </span>
                    <span v-else-if="period.periodUnitId == 4 && period.value == 1"> year </span>
                    <span v-else-if="period.periodUnitId == 4 && period.value != 1"> years </span>
                  </td>
                  <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                    <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                      <div class="jazeButtonGroup">
                        <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteBillingPeriod(period)">
                          <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteConfirmModal" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete Billing Period </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteConfirmModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure you want to delete this billing period ?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deleteConfirmModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deletePeriodConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="billingPeriodAddModal" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Add Billing Period </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="billingPeriodAddModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <label class="jazeLabel jazeLabel--primary">
                      Enter the billing period fields
                    </label><br>
                    <div class="row">
                      <form ref="addBillingForm" id="addBillingForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Enter Value</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" name="periodValue" required oninvalid="setCustomValidity(' ')" oninput="setCustomValidity('')" maxlength="100" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Select Unit</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" v-model="billingPeriodUnit" name="periodUnits">
                                <option value="3">Day/s </option>
                                <option value="2">Week/s </option>
                                <option value="1">Month/s </option>
                                <option value="4">Year/s </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="billingPeriodAddModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addBillingPeriod">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const billingPeriodDetails = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const periodArr = ref( [] )
  const deleteConfirmModal = ref( false )
  const billingPeriodAddModal = ref( false )
  const billingPeriodUnit = ref( null )
  // const billingPeriodUnit = ref( 3 )

  const settingsStore = usesettingsStore()
  const { billingPeriodData } = storeToRefs(settingsStore)
  const billingPeriodGetter = computed(() => billingPeriodData.value)

  onMounted(() => {
    getBillingPeriodData();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    billingPeriodDetails.value = billingPeriodGetter.value;
  })

  const getBillingPeriodData = () => {
    if (Object.keys(billingPeriodGetter.value).length == 0) {
      // $store.dispatch("fetchBillingPeriodData");
      settingsStore.fetchBillingPeriodData();
    } else {
      billingPeriodDetails.value = billingPeriodGetter.value;
    }
  }
  const addBillingPeriod = (e) => {
      e.preventDefault();
    successtoaster.value = false;
    errortoaster.value = false;
    var form = document.getElementById('addBillingForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    var status = checkForm(postData);
    if (status == "error") {
      formClass.value = "is-form-validation-visible";
      return;
    }
    errorShow.value = false;
    // $store.dispatch("addBillingPeriodSubmit", postData).then(
    settingsStore.addBillingPeriodSubmit(postData).then(  
      response => {
        billingPeriodAddModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          5000
        );
      },
      error => {
        billingPeriodAddModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          1500
        );
        console.log(error);
      }
    );
  }
  const checkForm = (postData) => {
    if (postData.periodValue == "") {
      errorMessage.value = "Value is Required";
      errorShow.value = true;
      return "error";
    }
    if (isNaN(postData.periodValue)) {
      errorMessage.value = "Please enter only digits.";
      errorShow.value = true;
      return "error";
    }
  }
  const deleteBillingPeriod = (period) => {
    periodArr.value = period;
    deleteConfirmModal.value = true;
  }
  const deletePeriodConfirm = () => {
    var data = {
      periodId: periodArr.value.id
    };
    // $store.dispatch("deleteBillingPeriodSubmit", data).then(
    settingsStore.deleteBillingPeriodSubmit(data).then(  
      response => {
        deleteConfirmModal.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          5000
        );
      },
      error => {
        deleteConfirmModal.value = false;
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          1500
        );
        console.log(error);
      }
    );
  }

</script>
