<template>
  <!-- <div> -->
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="expressConfigForm" id="expressConfigForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Express Configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow" v-if="expressDetails.jazePlatform=='hardware'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">MAC must be visibe while requesting access</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.macVisibleWhileRequestAccess==true" type="checkbox" id="macVisibleWhileRequestAccess" name="macVisibleWhileRequestAccess" checked>
                  <input v-else type="checkbox" id="macVisibleWhileRequestAccess" name="macVisibleWhileRequestAccess">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Enforce Unique restriction to all my Zones</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Enforce Unique restriction to all my Accounts</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.uniqueOrganizationnameForMyAccounts==true" type="checkbox" id="uniqueOrganizationnameForMyAccounts" name="uniqueOrganizationnameForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueOrganizationnameForMyAccounts" name="uniqueOrganizationnameForMyAccounts">
                  <label for="uniqueOrganizationnameForMyAccounts"> Organization Name </label> &nbsp;

                  <input v-if="expressDetails.uniqueUsernameForMyAccounts==true" type="checkbox" id="uniqueUsernameForMyAccounts" name="uniqueUsernameForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueUsernameForMyAccounts" name="uniqueUsernameForMyAccounts">
                  <label for="uniqueUsernameForMyAccounts"> Username </label> &nbsp;

                  <input v-if="expressDetails.uniqueEmailIdForMyAccounts==true" type="checkbox" id="uniqueEmailIdForMyAccounts" name="uniqueEmailIdForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueEmailIdForMyAccounts" name="uniqueEmailIdForMyAccounts">
                  <label for="uniqueEmailIdForMyAccounts"> Email ID </label> &nbsp;

                  <input v-if="expressDetails.uniqueMobileNumberForMyAccounts==true" type="checkbox" id="uniqueMobileNumberForMyAccounts" name="uniqueMobileNumberForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueMobileNumberForMyAccounts" name="uniqueMobileNumberForMyAccounts">
                  <label for="uniqueMobileNumberForMyAccounts"> Mobile Number </label> &nbsp;

                  <input v-if="expressDetails.uniqueIdProofForMyAccounts==true" type="checkbox" id="uniqueIdProofForMyAccounts" name="uniqueIdProofForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueIdProofForMyAccounts" name="uniqueIdProofForMyAccounts">
                  <label for="uniqueIdProofForMyAccounts"> ID Proof </label> &nbsp;

                  <input v-if="expressDetails.uniqueCafNumberForMyAccounts==true" type="checkbox" id="uniqueCafNumberForMyAccounts" name="uniqueCafNumberForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueCafNumberForMyAccounts" name="uniqueCafNumberForMyAccounts">
                  <label for="uniqueCafNumberForMyAccounts"> Caf Number </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Use this Zones billing address for all my Zones invoices?</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Use this Accounts billing address for all my Accounts invoices? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.uniqueBillingAddressForMyAccounts==true" type="checkbox" id="uniqueBillingAddressForMyAccounts" name="uniqueBillingAddressForMyAccounts" checked>
                  <input v-else type="checkbox" id="uniqueBillingAddressForMyAccounts" name="uniqueBillingAddressForMyAccounts">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Use this Zones email subject for all my Zones invoices?</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Use this Accounts email subject for all my Accounts invoices? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.uniqueEmailSubjectForMyAccounts==true" type="checkbox" id="uniqueEmailSubjectForMyAccounts" name="subaccountsCannotCreatePins" checked>
                  <input v-else type="checkbox" id="uniqueEmailSubjectForMyAccounts" name="uniqueEmailSubjectForMyAccounts">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Use this Zones billing address for all my Zones Ledgers?</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Use this Accounts billing address for all my Accounts Ledgers? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.uniqueBillingAddressForMyAccountsLedgers==true" type="checkbox" id="uniqueBillingAddressForMyAccountsLedgers" name="uniqueBillingAddressForMyAccountsLedgers" checked>
                  <input v-else type="checkbox" id="uniqueBillingAddressForMyAccountsLedgers" name="uniqueBillingAddressForMyAccountsLedgers">
                </div>
              </div> 
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Use this Zones billing address for all my Zones CAF</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Use this Accounts billing address for all my Accounts CAF ? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.uniqueBillingAddressForMyAccountsCaf==true" type="checkbox" id="uniqueBillingAddressForMyAccountsCaf" name="uniqueBillingAddressForMyAccountsCaf" checked>
                  <input v-else type="checkbox" id="uniqueBillingAddressForMyAccountsCaf" name="uniqueBillingAddressForMyAccountsCaf">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="expressDetails.edition=='isp'">Sub-Zones Cannot Create Pins?</label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>Sub-Accounts Cannot Create Pins? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.subaccountsCannotCreatePins != undefined && expressDetails.subaccountsCannotCreatePins=='1'" type="checkbox" id="subaccountsCannotCreatePins" name="subaccountsCannotCreatePins" checked>
                  <input v-else type="checkbox" id="subaccountsCannotCreatePins" name="subaccountsCannotCreatePins">
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.usePinForPayment == 'yes'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable Payment through pins? </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.allowOnlyPinPayments=='yes'" type="checkbox" id="allowOnlyPinPayments" name="allowOnlyPinPayments" checked>
                  <input v-else type="checkbox" id="allowOnlyPinPayments" name="allowOnlyPinPayments">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Allow User to choose Payment Gateway(Customer Portal)?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.allowUserToChoosePaymentGateway=='yes'" type="checkbox" id="allowUserToChoosePaymentGateway" name="allowUserToChoosePaymentGateway" checked @click="toggleAllowShow">
                  <input v-else type="checkbox" id="allowUserToChoosePaymentGateway" name="allowUserToChoosePaymentGateway" @click="toggleAllowShow">
                </div>
              </div>
              <div class="appRow" v-if="allowShow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Allowed Payment Gateway?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="paymentGatewaySelect" value="allowAll" v-if="expressDetails.paymentGatewaySelect == 'allowAll' || showPaymentGatewayOptions==false" checked @click="showPaymentGatewayOptions=false">
                      <input type="radio" name="paymentGatewaySelect" value="allowAll" v-else @click="showPaymentGatewayOptions=false">
                      <label for="paymentGatewaySelect"> Allow All </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="paymentGatewaySelect" value="allowCertain" v-if="(expressDetails.paymentGatewaySelect == 'allowCertain' && showPaymentGatewayOptions == true) || showPaymentGatewayOptions==true" checked @click="showPaymentGatewayOptions=true">
                      <input type="radio" name="paymentGatewaySelect" value="allowCertain" v-else @click="showPaymentGatewayOptions=true">
                      <label for="paymentGatewaySelect"> Select list of Payment Gateways to allow </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow" v-if="showPaymentGatewayOptions==true && allowShow==true">
                <div class="col--sm4"> </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <treeselect name="selectedPaymentGateways" v-model="selectedPaymentVal" :multiple="true" :options="paymentGatewaysOptionsFranchise" :searchable="true" :clearable="false" :disable-branch-nodes="true"></treeselect>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.parentFranchiseHead=='yes' || expressDetails.franchiseHead == 'yes'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Allow Franchise to choose Payment Gateway?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.allowFranchiseToChoosePaymentGateway=='yes'" type="checkbox" id="allowFranchiseToChoosePaymentGateway" name="allowFranchiseToChoosePaymentGateway" checked @click="toggleAllowShowFrance">
                  <input v-else type="checkbox" id="allowFranchiseToChoosePaymentGateway" name="allowFranchiseToChoosePaymentGateway" @click="toggleAllowShowFrance">
                </div>
              </div>
              <div class="appRow" id="showAllowedPaymentGatewayFranchise" v-if="allowShowFranchise==true && (expressDetails.parentFranchiseHead=='yes' || expressDetails.franchiseHead == 'yes')">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Allowed Payment Gateway Franchise?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="franchisePaymentGatewaySelect" value="allowAll" v-if="expressDetails.franchisePaymentGatewaySelect == 'allowAll' || showPaymentGatewayOptionsFranchise==false" checked @click="showPaymentGatewayOptionsFranchise=false">
                      <input type="radio" name="franchisePaymentGatewaySelect" value="allowAll" v-else @click="showPaymentGatewayOptionsFranchise=false">
                      <label for="franchisePaymentGatewaySelect"> Allow All </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="franchisePaymentGatewaySelect" value="allowCertain" v-if="(expressDetails.franchisePaymentGatewaySelect == 'allowCertain' && showPaymentGatewayOptionsFranchise==true) || showPaymentGatewayOptionsFranchise==true" checked @click="showPaymentGatewayOptionsFranchise=true">
                      <input type="radio" name="franchisePaymentGatewaySelect" value="allowCertain" v-else @click="showPaymentGatewayOptionsFranchise=true">
                      <label for="franchisePaymentGatewaySelect"> Select list of Payment Gateways to allow </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow" v-if="((expressDetails.parentFranchiseHead=='yes' || expressDetails.franchiseHead == 'yes') && showPaymentGatewayOptionsFranchise==true) && allowShowFranchise==true">
                <div class="col--sm4"> </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <treeselect name="franchiseSelectedPaymentGateways" :multiple="true" v-model="selectedPaymentValFranchise" :options="paymentGatewaysOptions" :searchable="true" :clearable="false" :disable-branch-nodes="true"></treeselect>
                </div>
              </div>

              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Renewal can be done through pins</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.renewalThroughPins=='yes'" type="checkbox" id="renewalThroughPins" name="renewalThroughPins" checked>
                  <input v-else type="checkbox" id="renewalThroughPins" name="renewalThroughPins">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Add custom radius attributes for my Zone?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.customReplyZone=='yes'" type="checkbox" id="customReplyZone" name="customReplyZone" checked @click="toggleCustomButton">
                  <input v-else type="checkbox" id="customReplyZone" name="customReplyZone" @click="toggleCustomButton"> &nbsp;
                  <button v-if="showCustomButton" class="appMainButton appMainButton--tertiary appMainButton--transparent" type="button" id="customReplyZoneConfigButton" @click="zoneConfigureShow = !zoneConfigureShow">
                    <div class="">Add Attributes</div>
                  </button>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.edition == 'cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Allow User to select group while login ?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.allowUserToSelectGroup=='yes'" type="checkbox" id="allowUserToSelectGroup" name="allowUserToSelectGroup" checked @click="toggleSelectGroup">
                  <input v-else type="checkbox" id="allowUserToSelectGroup" name="allowUserToSelectGroup" @click="toggleSelectGroup">
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.edition == 'cloud' && showSelectGroupOption==true">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Select Groups </label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <treeselect name="selectedUserGroups" v-model="showAllowedGroups" :multiple="true" :options="showAllowedGroupsOptions" :searchable="true" :clearable="false" :disable-branch-nodes="true"></treeselect>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.edition == 'cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Use Same Voucher for new paid Voucher Template?</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.useSameVoucherForPaidTemplate=='yes'" type="checkbox" id="useSameVoucherForPaidTemplate" name="useSameVoucherForPaidTemplate" checked>
                  <input v-else type="checkbox" id="useSameVoucherForPaidTemplate" name="useSameVoucherForPaidTemplate">
                </div>
              </div>
              <div v-if="expressDetails.jazePlatform=='hardware' && expressDetails.enableLoginAttempt!=undefined">
                <div class="appRow">
                  <div class="col--sm4">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Admin Login Security Policy</label>
                  </div>
                  <div class="col--sm8 appFormGroup appFormGroup--cozy">
                    <input v-if="expressDetails.enableLoginAttempt=='yes'" type="checkbox" id="enableLoginAttempt" name="enableLoginAttempt" checked>
                    <input v-else type="checkbox" id="enableLoginAttempt" name="enableLoginAttempt">
                  </div>
                </div>
                <div id="showLoginSecurityDiv">
                  <div class="appRow">
                    <div class="col--sm4">
                      <label class="appLabel appLabel--primary appLabel--boxAlign">Number of times a admin can attempt login failure</label>
                    </div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input type="text" name="loginAttemptCount" id="loginAttemptCount" v-model="loginAttemptFailureCount">
                    </div>
                  </div>
                  <div class="appRow">
                    <div class="col--sm4">
                      <label class="appLabel appLabel--primary appLabel--boxAlign">Next login time gap</label>
                    </div>
                    <div class="col--sm8 appFormGroup appFormGroup--cozy">
                      <input type="text" name="loginAttemptTimpeGap" id="loginAttemptTimpeGap" v-model="loginAttemptTimpeGap">
                      <label> Minutes </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.edition=='cloud' || expressDetails.edition=='isp'">
                <div class="col--sm4">
                  <label v-if="expressDetails.edition=='isp'" class="appLabel appLabel--primary appLabel--boxAlign">Move Expired Users to default group after Churned period</label>
                  <label v-else class="appLabel appLabel--primary appLabel--boxAlign">Move Expired Vouchers to default group and make vouchers active</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.moveVoucherToDefaultGroup=='yes'" type="checkbox" id="moveVoucherToDefaultGroup" name="moveVoucherToDefaultGroup" checked @click="toggleSelectDefaultGroup">
                  <input v-else type="checkbox" id="moveVoucherToDefaultGroup" name="moveVoucherToDefaultGroup" @click="toggleSelectDefaultGroup">
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails.edition=='cloud' || expressDetails.edition=='isp') && showSelectDefaultGroupOption" id="moveVoucherToDefaultGroupDiv">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Select group</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <treeselect name="moveVoucherToDefaultGroupId" v-model="moveVoucherToDefaultGroupId" :multiple="false" :options="expressDetails.userGroupOption" :searchable="true" :clearable="false"></treeselect>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Do not show all accounts in customer portal</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" id="showAllAccountsCustomerPortal" name="showAllAccountsCustomerPortal" checked>
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">BBPS Encryption Key</label>
                </div>
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <input type="text" name="bbpsEncrptionKey" v-model="expressDetails.bbpsEncrptionKey">
                </div>
                <div v-if="!(expressDetails.bbpsEncrptionKey != '' && expressDetails.bbpsEncrptionKey != null && expressDetails.bbpsEncrptionKey != 'null')" class="col--sm4 appFormGroup appFormGroup--cozy">
                  <button class="jazeButton jazeButton--primary" type="button" @click="generateKey">Generate Key</button>
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">BBPS Encryption Type</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="radio" name="bbpsEncrptionType" value='jar' v-model="expressDetails.bbpsEncrptionType">
                  <label for="bbpsEncrptionType"> Jar </label>
                  <input class="ml-4" type="radio" name="bbpsEncrptionType" value='plainAes' v-model="expressDetails.bbpsEncrptionType">
                  <label for="bbpsEncrptionType"> Plain AES </label>
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">BBPS Bill Period Type</label>
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <select class="input--full lineHeight--normal without-standard-validation" v-model="expressDetails.bbpsBillPeriodType" name="bbpsBillPeriodType">
                    <option value="numeric">Numeric </option>
                    <option value="alphanumeric">Alphanumeric</option>
                  </select>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.bbpsEncrptionType == 'plainAes' && (expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Mode</label>
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <select class="width-full" name="bbpsEncrptionMode" v-model="expressDetails.bbpsEncrptionMode">
                    <option value="ECB" selected>ECB</option>
                    <option value="CBC">CBC</option>
                  </select>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.bbpsEncrptionType == 'plainAes' && (expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Key Size</label>
                </div>
                <div class="col--sm4 appFormGroup appFormGroup--cozy">
                  <select class="width-full" name="bbpsEncrptionKeySize" v-model="expressDetails.bbpsEncrptionKeySize">
                    <option value="128">128</option>
                    <option value="192">192</option>
                    <option value="256">256</option>
                  </select>
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.bbpsEncrptionType == 'plainAes' && expressDetails.bbpsEncrptionMode == 'CBC' && (expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">IV</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="text" name="bbpsEncrptionIv" v-model="expressDetails.bbpsEncrptionIv">
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.bbpsEncrptionType == 'plainAes' && (expressDetails.jazePlatform=='hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform=='cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Output Format</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="radio" name="bbpsEncrptionOutputFormat" value='Base64' v-model="expressDetails.bbpsEncrptionOutputFormat">
                  <label for="bbpsEncrptionOutputFormat"> Base64 </label>
                  <input class="ml-4" type="radio" name="bbpsEncrptionOutputFormat" value='Hex' v-model="expressDetails.bbpsEncrptionOutputFormat">
                  <label for="bbpsEncrptionOutputFormat"> Hex </label>
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails.jazePlatform == 'hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform == 'cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">BBPS Send Full Unpaid Amount</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.bbpsSendFullUnpaidAmount == 'yes'" type="checkbox" id="bbpsSendFullUnpaidAmount" name="bbpsSendFullUnpaidAmount" checked>
                  <input v-else type="checkbox" id="bbpsSendFullUnpaidAmount" name="bbpsSendFullUnpaidAmount">
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails != undefined && expressDetails.jazePlatform != undefined && expressDetails.jazePlatform == 'hardware' && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform == 'cloud'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Disable BBPS</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.disableBbps == 'yes'" type="checkbox" id="disableBbps" name="disableBbps" checked>
                  <input v-else type="checkbox" id="disableBbps" name="disableBbps">
                </div>
              </div>
              <div class="appRow" v-if="(expressDetails != undefined && expressDetails.jazePlatform != undefined && expressDetails.jazePlatform == 'hardware' && expressDetails.thisAccountIsFranchiseTopAccount == 1 && (expressDetails.parent==null || expressDetails.parent=='')) || expressDetails.jazePlatform == 'cloud' && expressDetails.thisAccountIsFranchiseTopAccount == 1">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Enable BBPS Split</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.enableBbpsSplit == 'yes'" type="checkbox" id="enableBbpsSplit" name="enableBbpsSplit" checked>
                  <input v-else type="checkbox" id="enableBbpsSplit" name="enableBbpsSplit">
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.parentFranchiseHead=='yes' || expressDetails.franchiseHead == 'yes'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Split payment accountId</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="text" name="splitPaymentAccountId" v-model="splitPaymentAccountId">
                </div>
              </div>
              <div class="appRow" v-if="expressDetails.parentFranchiseHead=='yes' || expressDetails.franchiseHead == 'yes'">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Corporate Account Number</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input type="text" name="corporateAccountNumber" v-model="corporateAccountNumber">
                </div>
              </div>
            </div>
            <div class="appRow">
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Palladium Export</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.enablePalladiumExport == 'yes'" type="checkbox" id="enablePalladiumExport" name="enablePalladiumExport" checked>
                  <input v-else type="checkbox" id="enablePalladiumExport" name="enablePalladiumExport">
                </div>
              </div>
            <div class="appRow" v-if="expressDetails.checkboxEnableOtpForAdminLogin == 'no'" >
                <div class="col--sm4">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Enable OTP Login For Admin</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input v-if="expressDetails.enableAdminOtp == 'yes'" type="checkbox" id="enableAdminOtp" name="enableAdminOtp" checked @click="toggleAdminOtp">
                  <input v-else type="checkbox" id="enableAdminOtp" name="enableAdminOtp" @click="toggleAdminOtp">
                </div>
            </div>
            <div class="appRow" v-if="allowAdminOtp">
              <div class="col--sm4">
                <label class="appLabel appLabel--primary appLabel--boxAlign"></label>
              </div>
              <div class="col--sm8 appFormGroup appFormGroup--cozy">
                <div class="appInputGroup verticalRhythmTop--condensed">
                  <div class="appInputGroup appInputGroup--cozy inlineBlock">
                    <input type="radio" name="adminOtpOption" value="onlyAdminUsername" v-if="expressDetails.adminOtpOption == 'onlyAdminUsername'" checked>
                    <input v-else type="radio" name="adminOtpOption" value="onlyAdminUsername" >
                    <label for="adminOtpOption"> Only Username </label>
                  </div>
                  <div class="appInputGroup appInputGroup--cozy inlineBlock">
                    <input type="radio" name="adminOtpOption" value="onlyOtp" v-if="expressDetails.adminOtpOption == 'onlyOtp'" checked>
                    <input v-else type="radio" name="adminOtpOption" value="onlyOtp" >
                    <label for="adminOtpOption"> Only OTP </label>
                  </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                    <input type="radio" name="adminOtpOption" value="bothUsernameAndOtp" v-if="expressDetails.adminOtpOption == 'bothUsernameAndOtp'" checked>
                    <input v-else type="radio" name="adminOtpOption" value="bothUsernameAndOtp" >
                    <label for="adminOtpOption"> Both </label>
                  </div>
                </div>
              </div>                
            </div>
             <div class="appRow" v-if="allowAdminOtp">
              <span class="row" v-for="(n,index) in adminOtpUrlLength" :key="n" >
                <div class="col--sm4">
                  <label v-show="index == 0" class="appLabel appLabel--primary appLabel--boxAlign">OTP Login Applicable Url's</label>
                </div>
                <div class="col--sm8 appFormGroup appFormGroup--cozy">
                  <input  class="mr-5 " type="text" name="adminUrl[]" v-model="adminUrls[index]" >
                  <button type="button" title="Extra Token"  v-show="index == adminOtpUrlLength-1" class="jazeButton jazeButton--primary btn-xs"  @click="addIpRow(index)">
                      <span class="jaze-icon jaze-icon--plus"></span>
                  </button>
                </div>
                </span>
              </div>

          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveExpressConfig"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <zoneConfigureModal @extend-clicked-zone-detail="onclickclose" :vendors="expressDetails.vendorsAll" :editData="expressDetails.editData" :billAccountId="expressDetails.billAccountId" v-if="zoneConfigureShow"></zoneConfigureModal>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
  <!-- </div> -->
</template>
<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import axios from "@/api/index";
import qs from "qs";
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import zoneConfigureModal from "@/components/Settings/ZoneConfigure.vue";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import   '../../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
import { usesettingsStore } from '@/store/settingsstore'

    const expressDetails = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const showPaymentGatewayOptions = ref( false )
    const showPaymentGatewayOptionsFranchise = ref( false )
    const showSelectGroupOption = ref( false )
    const paymentGatewaysOptions = ref( [] )
    const paymentGatewaysOptionsFranchise = ref( [] )
    const showAllowedGroupsOptions = ref( [] )
    const selectedPaymentVal = ref( new Array() )
    const selectedPaymentValFranchise = ref( new Array() )
    const showAllowedGroups = ref( new Array() )
    const tableShow = ref( false )
    const zoneConfigureShow = ref( false )
    const showCustomButton = ref( false )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const allowShow = ref( false )
    const allowAdminOtp = ref( false )
    const allowShowFranchise = ref( false )
    const loginAttemptFailureCount = ref( "" )
    const loginAttemptTimpeGap = ref( "" )
    const splitPaymentAccountId = ref( "" )
    const corporateAccountNumber = ref( "" )
    const adminOtpUrlLength = ref( "" )
    const adminUrls = ref( [] )
    const index = ref( "" )
    const showSelectDefaultGroupOption = ref( false )
    const moveVoucherToDefaultGroupId = ref( null )

    const settingsStore = usesettingsStore()
    const { expressConfigDetails } = storeToRefs(settingsStore)
    const expressConfigGetter = computed(() => expressConfigDetails.value);

    const applyMixin = () => {
      const mixin = overrideIsSelected();
      const context = {
        $options: {
          components: { Treeselect },
        },
      };
      mixin.created.call(context);
    };
    onMounted(() => {
      getExpressDetails();
      applyMixin();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })
    //watchEffect(() => {
    watch(expressConfigGetter,() => {
      expressDetails.value = expressConfigGetter.value;
      if(expressDetails.value.moveVoucherToDefaultGroupId != undefined && expressDetails.value.moveVoucherToDefaultGroupId != '' && expressDetails.value.moveVoucherToDefaultGroupId != null){
        moveVoucherToDefaultGroupId.value = expressDetails.value.moveVoucherToDefaultGroupId;
      }
      if(expressDetails.value.moveVoucherToDefaultGroup != undefined && expressDetails.value.moveVoucherToDefaultGroup == 'yes'){
        showSelectDefaultGroupOption.value = true;
      }
      if (expressDetails.value.customReplyZone == "yes") {
        showCustomButton.value = true;
      }
      if (
        expressDetails.value.paymentGatewaySelect == "allowCertain" &&
        expressDetails.value.allowUserToChoosePaymentGateway == "yes"
      ) {
        showPaymentGatewayOptions.value = true;
      }
      if (
        expressDetails.value.allowUserToChoosePaymentGateway == "yes" &&
        expressDetails.value.allowUserToChoosePaymentGateway == "yes"
      ) {
        allowShow.value = true;
      }
       if (
        expressDetails.value.enableAdminOtp == "yes" &&
        expressDetails.value.enableAdminOtp == "yes"
      ) {
        allowAdminOtp.value = true;
      }
      if (
        (expressDetails.value.parentFranchiseHead == "yes" ||
          expressDetails.value.franchiseHead == "yes") &&
        expressDetails.value.allowFranchiseToChoosePaymentGateway == "yes"
      ) {
        allowShowFranchise.value = true;
      }
      if (
        (expressDetails.value.parentFranchiseHead == "yes" ||
          expressDetails.value.franchiseHead == "yes") &&
        expressDetails.value.franchisePaymentGatewaySelect == "allowCertain" &&
        expressDetails.value.allowFranchiseToChoosePaymentGateway == "yes"
      ) {
        showPaymentGatewayOptionsFranchise.value = true;
      }
      loginAttemptFailureCount.value = expressDetails.value.loginAttemptFailureCount;
      loginAttemptTimpeGap.value = expressDetails.value.loginAttemptTimpeGap;
      splitPaymentAccountId.value = expressDetails.value.splitPaymentAccountId;
      corporateAccountNumber.value = expressDetails.value.corporateAccountNumber;
      var payArr = expressDetails.value.paymentGateways;
      var adminUrlArr =  expressDetails.value.adminUrls;

      if (
        expressDetails.value != undefined &&
        adminUrlArr != undefined &&
        adminUrlArr.length != 0
        ) {
          adminOtpUrlLength.value = adminUrlArr.length;
          adminUrls.value = adminUrlArr;
      } else {
        adminOtpUrlLength.value = 1
      }

      if (
        expressDetails.value != undefined &&
        payArr != undefined &&
        payArr.length != 0
      ) {
        paymentGatewaysOptions.value = [];
        for (let i = 0; i < payArr.length; i++) {
          if (payArr[i]["operatorValue"] == "disable") {
            continue;
          }
          if (expressDetails.value.selectedPaymentGateways.length != 0) {
            for (
              let j = 0;
              j < expressDetails.value.selectedPaymentGateways.length;
              j++
            ) {
              if (
                payArr[i]["name"] ==
                expressDetails.value.selectedPaymentGateways[j]
              ) {
                selectedPaymentVal.value.push(payArr[i]["name"]);
              }
            }
          }
          paymentGatewaysOptions.value.push({
            id: payArr[i]["name"],
            label: payArr[i]["name"],
          });
        }
      }

      var payArrFr = expressDetails.value.paymentGateways;
      if (
        expressDetails.value != undefined &&
        payArrFr != undefined &&
        payArrFr.length != 0
      ) {
        paymentGatewaysOptionsFranchise.value = [];
        for (let i = 0; i < payArrFr.length; i++) {
          if (payArrFr[i]["operatorValue"] == "disable") {
            continue;
          }
          if (
            expressDetails.value.franchiseSelectedPaymentGateways.length != 0
          ) {
            for (
              let j = 0;
              j < expressDetails.value.franchiseSelectedPaymentGateways.length;
              j++
            ) {
              if (
                payArrFr[i]["name"] ==
                expressDetails.value.franchiseSelectedPaymentGateways[j]
              ) {
                selectedPaymentValFranchise.value.push(payArrFr[i]["name"]);
              }
            }
          }
          paymentGatewaysOptionsFranchise.value.push({
            id: payArrFr[i]["name"],
            label: payArrFr[i]["name"],
          });
        }
      }

      if (expressDetails.value.allowUserToSelectGroup == "yes") {
        showSelectGroupOption.value = true;
      }
      var groupArr = expressDetails.value.allowedGroups;
      if (
        expressDetails.value != undefined &&
        expressDetails.value.userGroups != undefined &&
        expressDetails.value.userGroups.length != 0
      ) {
        for (let j = 0; j < expressDetails.value.userGroups.length; j++) {
          if (groupArr != null && groupArr.length != 0) {
            for (let i = 0; i < groupArr.length; i++) {
              if (
                expressDetails.value.userGroups[j].UserGroup.id == groupArr[i]
              ) {
                showAllowedGroups.value.push(groupArr[i]);
              }
            }
          }
          showAllowedGroupsOptions.value.push({
            id: expressDetails.value.userGroups[j].UserGroup.id,
            label: expressDetails.value.userGroups[j].UserGroup.name,
          });
        }
      }
    });

    const getExpressDetails = () => {
      if (Object.keys(expressConfigGetter.value).length == 0) {
        // $store.dispatch("fetchExpressDetails");
        settingsStore.fetchExpressDetails();
      } else {
        expressDetails.value = expressConfigGetter.value;
      }
    }
    const saveExpressConfig = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      var form = document.getElementById('expressConfigForm');
      const formData = new FormData(form);
      var statAdminUrl = [];
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
        if (key == "adminUrl[]") {
          if(val != undefined && val !="null" && val != null && val !=""){
            statAdminUrl.push(val);
          }  
        }
      }
      var bodyFormData = new FormData();
      postData.selectedPaymentGateways = selectedPaymentVal.value;
      postData.franchiseSelectedPaymentGateways = selectedPaymentValFranchise.value;
      postData.selectedUserGroups = showAllowedGroups.value;
      postData.adminUrls = statAdminUrl;
      var status = checkForm(postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      // $store.dispatch("expressConfigurationSubmit", postData).then(
      settingsStore.expressConfigurationSubmit(postData).then(  
        (response) => {
          errorShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const checkForm = (postData) => {
      if (
        (showPaymentGatewayOptions.value == true &&
          postData.selectedPaymentGateways.length == 0) ||
        (showPaymentGatewayOptionsFranchise.value == true &&
          postData.franchiseSelectedPaymentGateways.length == 0)
      ) {
        errorMessage.value = "List of payment gateways cannot be empty";
        errorShow.value = true;
        return "error";
      }
      if (
        showSelectGroupOption.value == true &&
        postData.selectedUserGroups.length == 0
      ) {
        errorMessage.value = "Selected groups cannot be empty";
        errorShow.value = true;
        return "error";
      }
      if (
        showSelectDefaultGroupOption.value == true &&
        postData.moveVoucherToDefaultGroupId.length == 0
      ) {
        errorMessage.value = "Move to default group cannot be empty";
        errorShow.value = true;
        return "error";
      }
      if (
        postData.bbpsEncrptionType == "plainAes" &&
        postData.bbpsEncrptionMode == "CBC" &&
        postData.bbpsEncrptionIv != "" &&
        postData.bbpsEncrptionIv.length < 16
      ) {
        errorMessage.value = "Length of initialization vector must be 16 with AES";
        errorShow.value = true;
        return "error";
      }
      if (
        postData.bbpsEncrptionType == "plainAes" &&
        postData.bbpsEncrptionKeySize == "128" &&
        postData.bbpsEncrptionKey.length < 16
      ) {
        errorMessage.value = "Length of encryption key should be 16 for 128 bits key size";
        errorShow.value = true;
        return "error";
      }
      if (
        postData.bbpsEncrptionType == "plainAes" &&
        postData.bbpsEncrptionKeySize == "192" &&
        postData.bbpsEncrptionKey.length < 24
      ) {
        errorMessage.value = "Length of encryption key should be 24 for 192 bits key size";
        errorShow.value = true;
        return "error";
      }
      if (
        postData.bbpsEncrptionType == "plainAes" &&
        postData.bbpsEncrptionKeySize == "256" &&
        postData.bbpsEncrptionKey.length < 32
      ) {
        errorMessage.value = "Length of encryption key should be 32 for 256 bits key size";
        errorShow.value = true;
        return "error";
      }
    }
    const onclickclose = (value) => {
      zoneConfigureShow.value = value;
    }
    const toggleCustomButton = (event) => {
      if (event.target.checked == true) {
        showCustomButton.value = true;
      } else {
        showCustomButton.value = false;
      }
    }
    const toggleSelectGroup = (event) => {
      if (event.target.checked == true) {
        showSelectGroupOption.value = true;
      } else {
        showSelectGroupOption.value = false;
      }
    }
    const toggleSelectDefaultGroup = (event) => {
      if (event.target.checked == true) {
        showSelectDefaultGroupOption.value = true;
      } else {
        showSelectDefaultGroupOption.value = false;
      }
    }
    const toggleAllowShow = (event) => {
      if (event.target.checked == true) {
        allowShow.value = true;
      } else {
        allowShow.value = false;
      }
    }
    const toggleAdminOtp = (event) => {
      if (event.target.checked == true) {
        allowAdminOtp.value = true;
      } else {
        allowAdminOtp.value = false;
      }
    }
    const toggleAllowShowFrance = (event) => {
      if (event.target.checked == true) {
        allowShowFranchise.value = true;
      } else {
        allowShowFranchise.value = false;
      }
    }
    const addIpRow = (ind) => {
      adminOtpUrlLength.value = ind + 2;
      return adminOtpUrlLength.value;
    }
    const generateKey = () => {
      if(expressDetails.value.bbpsEncrptionType == 'jar') {        
        keyGeneration('jar', '256');
      } 
      else if(expressDetails.value.bbpsEncrptionType == 'plainAes') {
        if(expressDetails.value.bbpsEncrptionKeySize == '256') {
          keyGeneration('plainAes', '256');
        } else if(expressDetails.value.bbpsEncrptionKeySize == '192') {
          keyGeneration('plainAes', '192');
        } else if(expressDetails.value.bbpsEncrptionKeySize == '128') {
          keyGeneration('plainAes', '128');          
        }
      }
    }
    const keyGeneration = (type, size) => {
      JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
      var data = {
        type: type,
        size: size
      }
      axios.post('/settings/get_bbps_encryption_key', qs.stringify(data, {
        parseArrays: false
      }))
        .then(response => {
          JsLoadingOverlay.hide();
          if (response.data.status == "success") {
            expressDetails.value.bbpsEncrptionKey = response.data.message;
          } else {
            errortoaster.value = true;
            errorMessage.value = "There is some error. Please try after some time";
            setTimeout(
              function () {
                errortoaster.value = false;
              }.bind(this),
              4000
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

</script>