<template>
  <div>
    <div v-if="headerWarnDetails.platform == 'hardware' && headerWarnDetails.licenseStatusCheck != undefined && headerWarnDetails.licenseStatusCheck != 'valid' && headerWarnDetails.Auth != undefined && (headerWarnDetails.Auth.User.Account.parent_id==null || headerWarnDetails.Auth.User.Account.parent_id=='')">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <a @click="$router.push('/license')" style="color: inherit;line-height: 8px">
          <span v-if="headerWarnDetails.licenseStatusCheck != 'valid'"> <i class="jaze-icon--key"></i> </span>

          <div v-if="headerWarnDetails.licenseStatusCheck == 'newBox'">
            <strong>Apply License:</strong> You have not yet applied a license key.
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'blacklist'">
            <strong>WARNING:</strong> Your license has been backlisted.
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'trialExpired'">
            <strong>WARNING:</strong> Validity of the trial license has expired. {{contact}}
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'fullExpired'">
            <strong>Notice:</strong>Validity of the license has expired. {{contact}}
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'noInternetTemporary'">
            <strong>Notice:</strong> Unable to access the internet to verify the validity of the license. Please ensure that Jaze Access Manager is able to access the internet within the next 7 days to avoid functionality being disabled.
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'noInternetBlocked'">
            <strong>WARNING:</strong> Core functionality has been disabled as validity of license could not be verified without internet access. Jaze Access Manager functionality will be re-enabled within an hour of internet access being setup.
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'pirated'">
            <strong>WARNING:</strong> Jaze Access Manager is pirated. Core functionality has been disabled.<br>
          </div>
          <div v-else-if="headerWarnDetails.licenseStatusCheck == 'gracePeriod'">
            <strong>WARNING:</strong> Validity of the license has expired. {{contact}} <br>
          </div>

          <div v-if="headerWarnDetails.smsLimitCheck!=undefined && headerWarnDetails.smsLimitCheck!=''">
            <a v-if="headerWarnDetails.smsLimitCheck == 1"><strong>WARNING:</strong> SMS quota reached. Messages won't be sent.</a>
            <a v-else-if="headerWarnDetails.smsLimitCheck == 2"><strong>WARNING:</strong> SMS usage is over 90%. Recharge soon to increase limit.</a>
          </div>
          <a v-if="headerWarnDetails.edition == 'isp' && headerWarnDetails.userLimit !=undefined && headerWarnDetails.userLimit <= headerWarnDetails.currentTotalUsers"><strong>WARNING:</strong> User Limit reached. Please Upgrade your License.</a>
        </a>
      </div>
    </div>
    <div v-if="headerWarnDetails.platform == 'hardware' && headerWarnDetails.Auth != undefined && headerWarnDetails.Auth.Dashboard.totalDiskUsage > 80 && (headerWarnDetails.Auth.User.Account.parent_id==null || headerWarnDetails.Auth.User.Account.parent_id=='')">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <a style='line-height: 8px'><strong>WARNING:</strong> Disk usage has exceeded {{headerWarnDetails.Auth.Dashboard.totalDiskUsage}} %.</a>
      </div>
    </div>
    <div v-if="headerWarnDetails.Auth != undefined && headerWarnDetails.Auth.User.HeaderError.payment != undefined && headerWarnDetails.Auth.User.HeaderError.payment == 'alert' && headerWarnDetails.Auth.User.admin_group_id == 1">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <i class="jaze-icon--close"></i> We have not yet received payment for the month
        <a butten type="submit" style="line-height: 8px" class="jazeButton jazeButton--primary btn-xs" @click="$router.push('/payment')">Pay Now</a>
      </div>
    </div>
    <div v-if="headerWarnDetails.Auth != undefined && headerWarnDetails.Auth.User.HeaderError.resellerPayment != undefined && headerWarnDetails.Auth.User.HeaderError.resellerPayment == 'alert' && headerWarnDetails.Auth.User.admin_group_id == 1">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <i class="jaze-icon--close"></i> We have not yet received payment for the month
        <a butten type="submit" style="line-height: 8px" class="jazeButton jazeButton--primary btn-xs" @click="$router.push('/franchise_management/reseller_payment_index_list')">Pay Now [Reseller]</a>
      </div>
    </div>
    <div v-if="headerWarnDetails.Auth != undefined && headerWarnDetails.Auth.Dashboard.natLogDiskUsage > 75 && headerWarnDetails.Auth.User.admin_group_id == 1 ">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <a style='line-height: 8px'><strong>WARNING:</strong> Disk usage exceeded {{headerWarnDetails.Auth.Dashboard.natLogDiskUsage}} % on your Natlog Server. Please take necessary action.</a>
      </div>
    </div>
    <div v-if="(headerWarnDetails.dashNatNotification == 'no' && headerWarnDetails.dashNatNotificationEnable  && headerWarnDetails.Auth.User.admin_group_id == 1) || (headerWarnDetails.natLogsGenerating == 'no' && headerWarnDetails.dashNatNotificationEnable  && headerWarnDetails.Auth.User.admin_group_id == 1)">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <i class="jaze-icon--close"></i> Nat logs are not generating
      </div>
    </div>
    <div v-if="headerWarnDetails.Auth != undefined && ((headerWarnDetails.Auth.User.HeaderError.secondaryBackUpAlert != undefined && headerWarnDetails.Auth.User.HeaderError.secondaryBackUpAlert == 'yes') || (headerWarnDetails.Auth.User.HeaderError.secondaryNatlogBackUpAlert != undefined && headerWarnDetails.Auth.User.HeaderError.secondaryNatlogBackUpAlert == 'yes')) && (headerWarnDetails.secondaryDataNotification || headerWarnDetails.secondaryNatNotification) && headerWarnDetails.Auth.User.admin_group_id == 1">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <div v-if="headerWarnDetails.Auth.User.HeaderError.secondaryBackUpAlert == 'yes' && headerWarnDetails.secondaryDataNotification">
          <i class="jaze-icon--close"></i> No secondary data backup is configured <a type="submit" style="line-height: 8px" class="jazeButton jazeButton--primary btn-xs " @click="$router.push('/system_tools/backup')">Configure </a>
        </div>
        <div v-if="headerWarnDetails.Auth.User.HeaderError.secondaryNatlogBackUpAlert == 'yes' && headerWarnDetails.secondaryNatNotification && !headerWarnDetails.natFtpEnabled">
          <i class="jaze-icon--close"></i> No secondary nat logs backup is configured <a type="submit" style="line-height: 8px" class="jazeButton jazeButton--primary btn-xs" @click="$router.push('/logs/nat_logs')">Configure </a>
        </div>
      </div>
    </div>
    <div v-if="headerWarnDetails.Auth != undefined && ((headerWarnDetails.Auth.User.HeaderError.ftpBackUpNotStoring != undefined && headerWarnDetails.Auth.User.HeaderError.ftpBackUpNotStoring == 'yes') || (headerWarnDetails.Auth.User.HeaderError.googleBackUpNotStoring != undefined && headerWarnDetails.Auth.User.HeaderError.googleBackUpNotStoring == 'yes') || (headerWarnDetails.Auth.User.HeaderError.amazons3BackUpNotStoring != undefined && headerWarnDetails.Auth.User.HeaderError.amazons3BackUpNotStoring == 'yes') || (headerWarnDetails.Auth.User.HeaderError.googleNatLogNotStoring != undefined && headerWarnDetails.Auth.User.HeaderError.googleNatLogNotStoring == 'yes') || (headerWarnDetails.Auth.User.HeaderError.amazons3NatLogNotStoring != undefined && headerWarnDetails.Auth.User.HeaderError.amazons3NatLogNotStoring == 'yes')) && (headerWarnDetails.secondaryDataNotification || headerWarnDetails.secondaryNatNotification) && headerWarnDetails.Auth.User.admin_group_id == 1">
      <div class="jazeAlert jazeAlert--danger" style="margin-bottom: 0px;padding: 0px;border-radius: 0px;text-align: center;border-radius:0px;">
        <div v-if="headerWarnDetails.Auth !=undefined && (headerWarnDetails.Auth.User.HeaderError.ftpBackUpNotStoring == 'yes' || headerWarnDetails.Auth.User.HeaderError.googleBackUpNotStoring == 'yes' || headerWarnDetails.Auth.User.HeaderError.amazons3BackUpNotStoring == 'yes') && headerWarnDetails.secondaryDataNotification">
          <i class="jaze-icon--close"></i> Secondary data backup is not storing in {{backUpNotStoring }}
        </div>
        <div v-if="headerWarnDetails.Auth !=undefined && (headerWarnDetails.Auth.User.HeaderError.googleNatLogNotStoring == 'yes' || headerWarnDetails.Auth.User.HeaderError.amazons3NatLogNotStoring == 'yes') && headerWarnDetails.secondaryNatNotification && ! headerWarnDetails.natFtpEnabled">
          <i class="jaze-icon--close"></i> Secondary nat logs is not storing in {{ backUpNotStoring }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,computed,watchEffect,onMounted } from 'vue'

import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";
import { useRouter,useRoute } from 'vue-router';

  const router = useRouter();
  const route = useRoute();
  const layoutStore = uselayoutStore()
  const { headerDetails } = storeToRefs(layoutStore)
  const headerWarnDetails=ref([]);
  const backUpNotStoring=ref(null);
  const contact=ref(null);
  const headerDetailsGettera= computed(() =>headerDetails.value);
    const getHeaderDetails = () => {
      if (Object.keys(headerDetailsGettera.value).length == 0) {
      layoutStore.fetchHeaderDetails()
    } else {
      headerWarnDetails.value = headerDetailsGettera.value;
      if (
        headerWarnDetails.value.Auth != undefined &&
        ((headerWarnDetails.value.Auth.User.HeaderError.ftpBackUpNotStoring !=
          undefined &&
          headerWarnDetails.value.Auth.User.HeaderError.ftpBackUpNotStoring ==
            "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .googleBackUpNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .googleBackUpNotStoring == "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .amazons3BackUpNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .amazons3BackUpNotStoring == "yes")) &&
        headerWarnDetails.value.secondaryDataNotification
      ) {
        var ftpBackUpNotStoring = headerWarnDetails.value.Auth.User.HeaderError
          .ftpBackUpNotStoring;
        var googleBackUpNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.googleBackUpNotStoring;
        var amazons3BackUpNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.amazons3BackUpNotStoring;
        var notGenerating = [];
        if (ftpBackUpNotStoring == "yes") {
          notGenerating.push("FTP");
        }
        console.log(googleBackUpNotStoring);
        if (googleBackUpNotStoring == "yes") {
          notGenerating.push("Google Drive");
        }
        if (amazons3BackUpNotStoring == "yes") {
          notGenerating.push("AmazonS3");
        }
        backUpNotStoring.value = notGenerating.join(",");
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        ((headerWarnDetails.value.Auth.User.HeaderError.googleNatLogNotStoring !=
          undefined &&
          headerWarnDetails.value.Auth.User.HeaderError.googleNatLogNotStoring ==
            "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .amazons3NatLogNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .amazons3NatLogNotStoring == "yes")) &&
        headerWarnDetails.value.Auth.User.HeaderError.secondaryNatNotification !=
          undefined &&
        headerWarnDetails.value.secondaryNatNotification &&
        !headerWarnDetails.value.natFtpEnabled
      ) {
        var googleNatLogNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.googleNatLogNotStoring;
        var amazons3NatLogNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.amazons3NatLogNotStoring;
        var notGenerating = [];
        if (googleNatLogNotStoring == "yes") {
          notGenerating.push("Google Drive");
        }
        if (amazons3NatLogNotStoring == "yes") {
          notGenerating.push("AmazonS3");
        }
        backUpNotStoring.value = notGenerating.join(",");
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        headerWarnDetails.value.Auth.setting.whitelabel == "false"
      ) {
        contact.value =
          " Contact Jaze Networks at contact@jazenetworks.com to upgrade the license.";
      } else {
        contact.value = "";
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        headerWarnDetails.value.Auth.User != undefined &&
        headerWarnDetails.value.Auth.User.HeaderError != undefined &&
        headerWarnDetails.value.Auth.User.HeaderError.payment == "overdue"
      ) {
          router.push("/payment/blocked");
      }
      if (
      headerWarnDetails.value.Auth != undefined &&
      headerWarnDetails.value.Auth.User != undefined &&
      headerWarnDetails.value.Auth.User.HeaderError != undefined &&
      headerWarnDetails.value.Auth.User.HeaderError.resellerPayment == "overdue"
    ) {
      router.push("/franchise_management/resellerBlocked");
    }
    }
    }
    watchEffect(() => {
    headerWarnDetails.value=headerDetailsGettera.value;
      if (
        headerWarnDetails.value.Auth != undefined &&
        ((headerWarnDetails.value.Auth.User.HeaderError.ftpBackUpNotStoring !=
          undefined &&
          headerWarnDetails.value.Auth.User.HeaderError.ftpBackUpNotStoring ==
            "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .googleBackUpNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .googleBackUpNotStoring == "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .amazons3BackUpNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .amazons3BackUpNotStoring == "yes")) &&
        headerWarnDetails.value.secondaryDataNotification
      ) {
        var ftpBackUpNotStoring = headerWarnDetails.value.Auth.User.HeaderError
          .ftpBackUpNotStoring;
        var googleBackUpNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.googleBackUpNotStoring;
        var amazons3BackUpNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.amazons3BackUpNotStoring;
        var notGenerating = [];
        if (ftpBackUpNotStoring == "yes") {
          notGenerating.push("FTP");
        }
        console.log(googleBackUpNotStoring);
        if (googleBackUpNotStoring == "yes") {
          notGenerating.push("Google Drive");
        }
        if (amazons3BackUpNotStoring == "yes") {
          notGenerating.push("AmazonS3");
        }
        backUpNotStoring.value = notGenerating.join(",");
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        ((headerWarnDetails.value.Auth.User.HeaderError.googleNatLogNotStoring !=
          undefined &&
          headerWarnDetails.value.Auth.User.HeaderError.googleNatLogNotStoring ==
            "yes") ||
          (headerWarnDetails.value.Auth.User.HeaderError
            .amazons3NatLogNotStoring != undefined &&
            headerWarnDetails.value.Auth.User.HeaderError
              .amazons3NatLogNotStoring == "yes")) &&
        headerWarnDetails.value.Auth.User.HeaderError.secondaryNatNotification !=
          undefined &&
        headerWarnDetails.value.secondaryNatNotification &&
        !headerWarnDetails.value.natFtpEnabled
      ) {
        var googleNatLogNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.googleNatLogNotStoring;
        var amazons3NatLogNotStoring = headerWarnDetails.value.Auth.User
          .HeaderError.amazons3NatLogNotStoring;
        var notGenerating = [];
        if (googleNatLogNotStoring == "yes") {
          notGenerating.push("Google Drive");
        }
        if (amazons3NatLogNotStoring == "yes") {
          notGenerating.push("AmazonS3");
        }
        backUpNotStoring.value = notGenerating.join(",");
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        headerWarnDetails.value.Auth.setting.whitelabel == "false"
      ) {
        contact.value =
          " Contact Jaze Networks at contact@jazenetworks.com to upgrade the license.";
      } else {
        contact.value = "";
      }
      if (
        headerWarnDetails.value.Auth != undefined &&
        headerWarnDetails.value.Auth.User != undefined &&
        headerWarnDetails.value.Auth.User.HeaderError != undefined &&
        headerWarnDetails.value.Auth.User.HeaderError.payment == "overdue"
      ) {
          router.push("/payment/blocked");
      }
      if (
      headerWarnDetails.value.Auth != undefined &&
      headerWarnDetails.value.Auth.User != undefined &&
      headerWarnDetails.value.Auth.User.HeaderError != undefined &&
      headerWarnDetails.value.Auth.User.HeaderError.resellerPayment == "overdue"
    ) {
      router.push("/franchise_management/resellerBlocked");
    }
    });
  onMounted(() => {
    getHeaderDetails()
  })

</script>
