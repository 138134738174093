<template>
  <div>
    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" style="display:flex" dialog-layout="confirmLayout" v-if="deleteModalShow" v-show="modalType != 'selectedType'">
      <div class="jazeModal unifiConfirm jazeModal--medium">
        <div class="jazeModal__header pb-0">
          <div class="jazeHeader jazeHeader--centered">
            <div class="jazeHeader__title">Push To Sub Zones</div>
            <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="onclosesubmit">
              <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
              </g>
            </svg>
          </div>
        </div>
        <div class="jazeModal__main" style="height:270px;overflow:auto;">
          <div class="jazeModalContent pb-1">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="form-group appRow">
              <div class="col--sm5">
                <label class="appLabel appLabel--primary appLabel--boxInline" v-show="edition == 'isp'">Select Zone</label>
                <label class="appLabel appLabel--primary appLabel--boxInline" v-show="edition != 'isp'">Select Account</label>

              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <treeselect v-model="value" virtual  :virtual-row-height="25" :default-expand-level="1" :multiple="true" :options="options" v-on:update:modelValue="getAccountid()" />
              </div>
            </div>
            <div class="form-group appRow" v-show="(modalType !='subAccountsOnly' && modalType !='selectedType')">
              <div class="col--sm5">
                <label class="appLabel appLabel--primary appLabel--boxInline" v-show="edition == 'isp'">Packages make visible in sub zones:</label>
                <label class="appLabel appLabel--primary appLabel--boxInline" v-show="edition != 'isp'">Packages make visible in sub Accounts:</label>

              </div>
              <div class="col--sm7 appFormGroup appFormGroup--cozy">
                <input type="checkbox" class="jazeCheckbox" @click="checkbox = !checkbox">
              </div>
            </div>
          </div>
        </div>
        <div class="jazeModal__footer">
          <div class="jazeButtonGroup jazeButtonGroup--cozy">
            <button class="jazeButton jazeButton--secondary" @click="onclosesubmit">Cancel</button>
            <button class="jazeButton jazeButton--primary submitForm" @click="extendSubmit">Ok</button>
          </div>
        </div>

      </div>
    </div>
    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteModalShow" v-show="modalType == 'selectedType'" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--medium unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Push To Sub Zones </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;height:500px;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="">
                      <div class="ml-1" >
                        <input  v-model='optionaAllSelect' value='all' type="radio" name="optionaAllSelect">All
                        <input  v-model='optionaAllSelect' value='selected' type="radio" name="optionaAllSelect">Selected
                      </div>
                      <div class="mt-2">
                      <td class="ml-2" v-if="optionaAllSelect =='selected'">
                          <treeselect v-model="dataSettingValue" :always-open="true" :default-expand-level="1" :multiple="true" :options="dataSetting" @update:modelValue="getAccountid()" />
                      </td>
                      <td class="ml-2">
                        <treeselect :multiple="true" :always-open="true" v-model="value" virtual :virtual-row-height="25" :options="options"  :default-expand-level="1" @update:modelValue="getAccountid()"></treeselect>
                      </td>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deleteModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addToAllSubAccounts()">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>
<script setup>
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import Treeselect from "jaze-vue3-treeselect";
import { useprofilesStore } from '@/store/profilesstore';
import { useexternalintegrationStore } from '@/store/externalintegrationstore';
import { usebillingStore } from '@/store/billingstore';
import { usehelpdeskStore } from '@/store/helpdeskstore';
import { usecpconfigurationStore } from '@/store/cpconfigurationstore';
import { usecpdatasStore } from '@/store/cpdatasstore';
import { usesettingsStore } from '@/store/settingsstore';
import { useleadStore } from '@/store/leadstore';
import { useannouncementsStore } from '@/store/announcementsstore';
import { useinventoryStore } from '@/store/inventorystore'
import { toRefs,toRef,ref,computed,watchEffect,onMounted,onBeforeUnmount,watch, defineProps, defineEmits} from 'vue'
    const props = defineProps(["subAccounts", "selected", "edition", "from", "modalType", "dataSetting"]);
    const emit = defineEmits(["toaster_show", "extend-clicked-push-detail", "clear-selected"]);
    const subAccounts = toRef(props,'subAccounts');
    const selected = toRef(props,'selected');
    const edition = toRef(props,'edition');
    const from = toRef(props,'from');
    const modalType = toRef(props,'modalType');
    const dataSetting = toRef(props,'dataSetting');
    const profileStore = useprofilesStore()  
    const externalintegrationStore = useexternalintegrationStore() 
    const billingStore = usebillingStore()
    const helpdeskStore = usehelpdeskStore()
    const cpconfigurationStore = usecpconfigurationStore()
    const cpdatasStore = usecpdatasStore()
    const settingsStore = usesettingsStore()
    const leadStore = useleadStore()
    const announcementsStore = useannouncementsStore()
    const inventoryStore = useinventoryStore()
    const deleteModalShow=ref(true);
      const successtoaster=ref(false);
      const successMessage=ref("");
      const errortoaster=ref(false);
      const errorMessage=ref("");
      const value=ref([]);
      const errorShow=ref(false);
      const checkbox=ref(false);
      const optionaAllSelect=ref("all");
      const selectedAccVal=ref(null)
      const dataSettingValue=ref(null)
     const  getaccId=ref([]);
      const options=ref([
        {
          id: "selectAll",
          label: "Select All",
          children: [],
        },
      ]);
    
      onMounted(()=>{
        getPushDetails();
      }) 
    const getPushDetails =()=> {
         if (from.value == "defaulPackageSetting") {
        for (let i = 0; i < subAccounts.value.length; i++) {
          var deactivated = subAccounts.value[i].Account.deactivated == 0 ? " (Deactivated)" : "";
          options.value[0].children.push({
            id: subAccounts.value[i].Account.id,
            label: subAccounts.value[i].Account.company_name + deactivated,
          });
        }
      }else{
        for (var i = 0; i < subAccounts.value.length -1; i++) {
        options.value[0].children.push({
          id: subAccounts.value[i].id,
          label: subAccounts.value[i].name,
        });
      }
      }
    }
    const onclosesubmit =()=> {
        deleteModalShow.value = false; 
      emit("extend-clicked-push-detail", deleteModalShow.value);
    }
     const getAccountid =()=> {
        if (value.value[0] != "selectAll") {
        getaccId.value = value.value;
      } else {
        for (var i = 0; i < subAccounts.value.length; i++) {
          getaccId.value.push(subAccounts.value[i].id);
        }
      }
     }
    const checkForm =(postData)=> {
        if (postData.accountIds.length == 0) {
        errorMessage.value = "Select Account is Required";
        errorShow.value  = true;
        return "error";
      }
      if (postData.profileIds.length == 0 && modalType.value  != 'subAccountsOnly' && modalType.value  !='selectedType') {
        errorMessage.value  = "Select Profile is Required";
        errorShow.value  = true;
        return "error";
      }
    }
    const extendSubmit =(e)=> {
       e.preventDefault();
      var data = {
        accountIds: getaccId.value,
        profileIds: selected.value,
      };
      var data1 = {
        value: getaccId.value,
      };
      if (checkbox.value) {
        data.displayinSubAccounts = "on";
      } else {
        data.displayinSubAccounts = "off";
      }
      var status = checkForm(data);
      if (status == "error") {
        return;
      }
      var dispatchFn ="pushToSubZone";
      if(from.value == 'iptv'){
       var dispatchFn ="iptvPushToSubZone";
      }else if (from.value == 'ott'){
       var dispatchFn ="ottPushToSubZone";
      }else if (from.value == 'IPTV'){
       var dispatchFn ="IPTVPushToSubZone";
      }else if (from.value == 'OTT'){
       var dispatchFn ="OTTPushToSubZone";
      }else if (from.value == 'VoicePhone'){
       var dispatchFn ="VoicePhonePushToSubZone";
      }else if (from.value == 'IVR'){
       var dispatchFn ="IVRPushToSubZone";
      }else if (from.value == 'enach'){
       var dispatchFn ="enachPushToSubZone";
      }else if (from.value == 'aadhaarKyc'){
       var dispatchFn ="AadhaarKycPushToSubZone";
      }else if (from.value == 'QuickBooks'){
       var dispatchFn ="QuickBooksPushToSubZone";
      }else if (from.value == 'GoogleDrive'){
       var dispatchFn ="GoogleDrivePushToSubZone";
      }else if (from.value == 'FTP'){
       var dispatchFn ="FTPPushToSubZone";
      }else if (from.value == 'paymentGateway'){
       var dispatchFn ="paymentGatewayPushToSubZone";
      }else if (from.value == 'S3'){
       var dispatchFn ="S3PushToSubZone";
      }else if (from.value == 'oneSignal'){
       var dispatchFn ="oneSignalPushToSubZone";
      }else if (from.value == 'webHooks'){
       var dispatchFn ="webHooksPushToSubZone";
      }else if (from.value == 'sms'){
       var dispatchFn ="smsPushToSubZone";
      }else if (from.value == 'whatsApp'){
       var dispatchFn ="whatsAppPushToSubZone";
      }else if (from.value == 'acs'){
       var dispatchFn ="acsPushToSubZone";
      }else if (from.value == 'einvoice'){
       var dispatchFn ="einvoicePushToSubZone";
      }else if (from.value == 'email'){
       var dispatchFn ="emailPushToSubZone";
      }else if (from.value == 'emailIn'){
       var dispatchFn ="emailInPushToSubZone";
      }else if (from.value == 'notifications'){
       var dispatchFn ="notificationsPushToSubZone";
      }else if (from.value == 'invoiceTemplate'){
       var dispatchFn ="invoiceTemplatePushToSubZone";
      }else if (from.value == 'receiptTemplate'){
       var dispatchFn ="receiptTemplatePushToSubZone";
      }else if (from.value == 'creditNote'){
       var dispatchFn ="creditNotePushToSubZone";
      }else if (from.value == 'CAFTemplate'){
       var dispatchFn ="CAFTemplatePushToSubZone";
      }else if (from.value == 'ledgerTemplate'){
       var dispatchFn ="ledgerTemplatePushToSubZone";
      }else if (from.value == 'ticketRules'){
       var dispatchFn ="ticketRulesPushToSubZone";
      }else if (from.value == 'ticketSLA'){
       var dispatchFn ="ticketSLAPushToSubZone";
      }else if (from.value == 'ticketConfig'){
       var dispatchFn ="ticketConfigPushToSubZone";
      }else if (from.value == 'cpConfig'){
       var dispatchFn ="cpConfigPushToSubZone";
      }else if (from.value == 'userportal'){
       var dispatchFn ="userportalPushToSubZone";
      }else if (from.value == 'customizeCaptiveportal'){
       var dispatchFn ="customizeCaptiveportalPushToSubZone";
      }else if (from.value == 'payasyougoSettings'){
       var dispatchFn ="payasyougoSettingsPushToSubZone";
      }else if (from.value == 'userfieldSetting'){
       var dispatchFn ="userfieldSettingPushToSubZone";
      }else if (from.value == 'customRules'){
       var dispatchFn ="customRulesPushToSubZone";
      }else if (from.value == 'additionalFields'){
       var dispatchFn ="additionalFieldsPushToSubZone";
      }else if (from.value == 'dashboardConfig'){
       var dispatchFn ="dashboardConfigPushToSubZone";
      }else if (from.value == 'defaulPackageSetting'){
       var dispatchFn ="defaulPackageSettingPushToSubZone";
      }else if (from.value == 'leadClassification'){
       var dispatchFn ="leadClassificationPushToSubZone";
      }else if (from.value == 'assignLeads'){
       var dispatchFn ="assignLeadsPushToSubZone";
      }else if (from.value == 'leadSla'){
       var dispatchFn ="leadSlaPushToSubZone";
      }else if (from.value == 'triggers'){
       var dispatchFn ="triggersPushToSubZone";
      }else if (from.value == 'fupTemplate'){
       var dispatchFn ="fupTemplatePushToSubZone";
      }else if (from.value == 'inventoryConfig'){
       var dispatchFn ="inventoryConfigPushToSubZone";
      }
     
      if(dispatchFn =="pushToSubZone"){
       profileStore.pushToSubZone(data).then(
        (response) => {
          successtoaster.value = true;
          successMessage.value = response.message;
          deleteModalShow.value = false;
          if (response.status == "success") {
            emit("clear-selected", "clearSelected");
          }
          setTimeout(
            function () {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        (error) => {
          deleteModalShow.value = false;
         errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    
      }
      if(dispatchFn =="iptvPushToSubZone"){
        profileStore.iptvPushToSubZone(data).then(
        (response) => {
          successtoaster.value = true;
          successMessage.value = response.message;
          deleteModalShow.value = false;
          if (response.status == "success") {
            emit("clear-selected", "clearSelected");
          }
          setTimeout(
            function () {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        (error) => {
          deleteModalShow.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
             errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    
      }
      if(dispatchFn =="ottPushToSubZone"){
        profileStore.ottPushToSubZone(data).then(
        (response) => {
          successtoaster.value = true;
         successMessage.value = response.message;
          deleteModalShow.value = false;
          if (response.status == "success") {
            emit("clear-selected", "clearSelected");
          }
          setTimeout(
            function () {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        (error) => {
          deleteModalShow.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    
      }
      if(dispatchFn =="IPTVPushToSubZone"){
        externalintegrationStore.addIptvToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="OTTPushToSubZone"){
        externalintegrationStore.addOttToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
         errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="VoicePhonePushToSubZone"){
        externalintegrationStore.addIpPhoneToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="IVRPushToSubZone"){
      externalintegrationStore.addIvrToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="enachPushToSubZone"){
        externalintegrationStore.addEnachToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="AadhaarKycPushToSubZone"){
         externalintegrationStore.addAadhaarkycToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
         errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="QuickBooksPushToSubZone"){
         externalintegrationStore.addQuickBookToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="GoogleDrivePushToSubZone"){
        externalintegrationStore.addGoogleDriveToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="FTPPushToSubZone"){
        externalintegrationStore.addFtpToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="paymentGatewayPushToSubZone"){
        externalintegrationStore.addPayGateToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="S3PushToSubZone"){
         externalintegrationStore.addAmazonS3ToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="oneSignalPushToSubZone"){
        externalintegrationStore.addOneSignalToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="webHooksPushToSubZone"){
         externalintegrationStore.addWebHooksToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="smsPushToSubZone"){
         externalintegrationStore.addSmsToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="whatsAppPushToSubZone"){
        externalintegrationStore.addWhatsappToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="acsPushToSubZone"){
      externalintegrationStore.addAcsToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="einvoicePushToSubZone"){
      externalintegrationStore.addEInvoiceToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="emailPushToSubZone"){
       externalintegrationStore.addEmailToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="emailInPushToSubZone"){
        externalintegrationStore.addInEmailToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="notificationsPushToSubZone"){
        billingStore.addNotificationToAllSubZones(data1).then(  
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          showErrorToaster("success", successMessage.value);
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          showErrorToaster("error", errorMessage.value);
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="invoiceTemplatePushToSubZone"){
        billingStore.addInvoiceTemplateToAllSubZones(data1).then(
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error +" ";
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="receiptTemplatePushToSubZone"){
         billingStore.addReceiptTemplateToAllSubZones(data1).then(
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="creditNotePushToSubZone"){
        billingStore.addCreditNoteTemplateToAllSubZones(data1).then(
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="CAFTemplatePushToSubZone"){
        billingStore.addCAFTemplateToAllSubZones(data1).then(
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
        
      }
      if(dispatchFn =="ledgerTemplatePushToSubZone"){
        billingStore.addLedgerTemplateToAllSubZones(data1).then(
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error +" ";
          setTimeout(
            function() {
             errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );

      }
      if(dispatchFn =="ticketRulesPushToSubZone"){
         helpdeskStore
        .addSubAccountTIcketRules(data1)
        .then(response => {
          console.log(response);
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response.genericBody;
          setTimeout(() => {
            successtoaster.value = false;
          }, 2000);
        })
        .catch(error => {
          setTimeout(() => {
            errortoaster.value = true;
            errorMessage.value = error;
          }, 4000);
        });
      }
      if(dispatchFn =="ticketSLAPushToSubZone"){
         helpdeskStore
        .addSubAccountTIcketSla(data1)
        .then(response => {
          console.log(response);
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response.message;
          setTimeout(() => {
            successtoaster.value = false;
          }, 2000);
        })
        .catch(error => {
          setTimeout(() => {
            errortoaster.value = true;
            errorMessage.value = error;
          }, 4000);
        });
      }
      if(dispatchFn =="ticketConfigPushToSubZone"){
        helpdeskStore.addToSubzoneSave(data1).then((res) => {  
        deleteModalShow.value = false;
        if (res.status == "success") {
          successtoaster.value = true;
          successMessage.value = res.genericBody;
        } else {
          errortoaster.value = true;
          errorMessage.value = res.message;
        }
        setTimeout(() => {
          successtoaster.value = false;
          errortoaster.value = false;
        }, 2000);
      });
      }
      if(dispatchFn =="cpConfigPushToSubZone"){
           cpconfigurationStore.applyToAllSubZone(data1).then(  
          (response) => {
            successtoaster.value = true;
            successMessage.value = response.genericBody;
            deleteModalShow.value = false;
            setTimeout(
              function () {
                successtoaster.value = false;
                emit("extend-clicked-push-detail", false);
              }.bind(this),
              1500
            );
          },
          (error) => {
            deleteModalShow.value = false;
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function () {
                errortoaster.value = false;
                emit("extend-clicked-push-detail", false);
              }.bind(this),
              1500
            );
            console.log(error);
          }
        );
      }
      if(dispatchFn =="userportalPushToSubZone"){
         cpdatasStore.applyToSubZone(data1).then(  
        response => {
          deleteModalShow.value = false;
          toasterShow("success", response.genericBody);
        },
        error => {
          deleteModalShow.value = false;
          toasterShow("error", error);
          console.log(error);
        }
      );
      }
      if(dispatchFn =="customizeCaptiveportalPushToSubZone"){
       cpdatasStore.savetosubzone(data1).then(  
        (response) => {
          deleteModalShow.value = false;
         showToaster("success", response.genericBody);
        },
        (error) => {
          showToaster("error", error);
          console.log(error);
        }
      );
      }
      if(dispatchFn =="payasyougoSettingsPushToSubZone"){
        settingsStore.addPayAsYouGoToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        },
      );
      }
      if(dispatchFn =="userfieldSettingPushToSubZone"){
         settingsStore.addUserSettingsToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="customRulesPushToSubZone"){
          settingsStore.addCustomRulesToAllSubZones(data1).then( 
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="additionalFieldsPushToSubZone"){
         settingsStore.addAdditionalSettingsToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
        );

      }
      if(dispatchFn =="dashboardConfigPushToSubZone"){
        settingsStore.addDashboardToAllSubZones(data1).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="defaulPackageSettingPushToSubZone"){
         settingsStore.addDefaultPackageSettingsToAllSubZones(data1).then(  
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
             successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="leadClassificationPushToSubZone"){
         leadStore.applyToAllSubZoneLeadClassification(data1).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response.genericBody;
          deleteModalShow.value = false;
          setTimeout(
            function() {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        error => {
          deleteModalShow.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="assignLeadsPushToSubZone"){
         leadStore.applyToAllSubZoneLeadAssign(data1).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response.genericBody;
         deleteModalShow.value = false;
          setTimeout(
            function () {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        (error) => {
          deleteModalShow.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="leadSlaPushToSubZone"){
         leadStore.addSubAccountLeadSla(data1)  
        .then(response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
         successMessage.value = response.genericBody;
          setTimeout(() => {
            successtoaster.value = false;
          }, 2000);
        })
        .catch(error => {
          setTimeout(() => {
            errortoaster.value = true;
            errorMessage.value = error;
          }, 4000);
        });
      }
      if(dispatchFn =="triggersPushToSubZone"){
        announcementsStore.applyToAllSubZoneAn(data1).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response.genericBody;
          deleteModalShow.value = false;
          setTimeout(
            function () {
              successtoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
        },
        (error) => {
          deleteModalShow.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
              emit("extend-clicked-push-detail", false);
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="fupTemplatePushToSubZone"){
        profileStore.addFupToAllSubZones(data1).then(
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if(dispatchFn =="inventoryConfigPushToSubZone"){
        inventoryStore.addInventoryToAllSubZones(data1).then(  
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }



    }
     const addToAllSubAccounts =()=> {
        successtoaster.value = false;
      errortoaster.value = false;
      var settingsvalue = [];

      if (
        dataSettingValue.value != undefined &&
        dataSettingValue.value != null &&
        dataSettingValue.value.length == 1 &&
        dataSettingValue.value[0] == "selectAll" &&
        dataSetting.value[0].children.length != 0
      ) {
        for (let i = 0; i < dataSetting.value[0].children.length; i++) {
          settingsvalue.push(dataSetting.value[0].children[i].id);

        }
      } else {
        settingsvalue = dataSettingValue.value;

      }
      var data = {
        value: getaccId.value,
        settingValue: settingsvalue,
        selection: optionaAllSelect.value,
      };
      if (from.value == 'billingSetting'){
        settingsStore.addBillingToAllSubZones(data).then(  
          response => {
            deleteModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
           errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
      }
      if (from.value == 'misconfigSetting'){
         settingsStore.addMiscellaneousToAllSubZones(data).then(  
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if (from.value == 'billingAddressSetting'){
        settingsStore.addBillingAddrToAllSubZones(data).then(  
        response => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if (from.value == 'leadSetting'){
         settingsStore.addLeadSettingsToAllSubZones(data).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
      if (from.value == 'reportVisibleColumnSetting'){
        settingsStore.addReportColumnToAllSubZones(data).then(  
          response => {
            deleteModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
      }
      if (from.value == 'tableHeaderConfig'){
        settingsStore.addTableConfigToAllSubZones(data).then(  
          response => {
            deleteModalShow.value = false;
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
              function() {
                successtoaster.value = false;
              }.bind(this),
              2000
            );
          },
          error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              15000
            );
            console.log(error);
          }
        );
      }
      if (from.value == 'userNotification'){
         settingsStore.addConsolidatedTemplatesToAllSubZones(data).then(  
        (response) => {
          deleteModalShow.value = false;
         successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            3500
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            3500
          );
          console.log(error);
        }
      );
      }
      if (from.value == 'paymentollection'){
        settingsStore.addPaymentcollectToAllSubZones(data).then(  
        (response) => {
          deleteModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
      }
     };
     const showErrorToaster = (method, message)=> {
      var data = {
        method: method,
        message: message
      };
      emit("toaster_show", data);
    }
</script>