export function overrideIsSelected() {
  return {
    created() {
      // console.log(this.$options.components.Treeselect.mixins[0].methods)
      this.$options.components.Treeselect.mixins[0].methods.isSelected = function (node) {
        //console.log(node)
        if (node !== undefined && node !== null) {
          return this.forest.selectedNodeMap[node.id] === true;
        } else {
          return false;
        }
      };
    },
  };
}