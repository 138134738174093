<template>
    <div v-if="showMessage">
        <form ref="defaultProfileSettingsForm" id="defaultProfileSettingsForm" class="appForm appForm--cozy is-validation-hidden">
            <div v-show="selectedTab == 'defaultPackageSettings'">
                <fieldset class="appFieldset appFieldset--quiet">
                    <div class="unifiSettingsSection">
                        <div class="appRow">
                            <h5>Auto Bind MAC</h5>
                            <hr>
                            <div class="col--sm5">
                                <label class="appLabel appLabel--primary appLabel--boxAlign"> Auto bind MAC to the user on first login </label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                <input type="checkbox" name="autoBind" v-model="autoBind" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.autoBind != undefined && packageSettingsData.defaultPackageSettings.autoBind == "on" ? true : false'>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>Connection Type</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                                    <label class="appLabel appLabel--primary appLabel--boxInline">Select Connection Type</label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <!-- <input type="checkbox" name="sessionTimeOut" v-model="sessionTimeOut" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.sessionTimeOut != undefined && packageSettingsData.defaultPackageSettings.sessionTimeOut == "on" ? true : false'> -->
                                    <select name="connectionType" class="width-full" v-model="connectionType">
                                      <option value="wireless" selected>Wireless</option>
                                      <option value="fiber">Fiber</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>MAC Restriction</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow only devices whose MAC addresses are configured in each user to login with the corresponding username </label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <input type="checkbox" name="macRestriction" v-model="macRestriction" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.macRestriction != undefined && packageSettingsData.defaultPackageSettings.macRestriction == "on" ? true : false'>
                                </div>
                            </div>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign">If MAC address is not specified for the user, then login will be permitted.</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>IP Restriction</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign">Allow only devices whose IP addresses are configured in each user to login with the corresponding username</label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <input type="checkbox" name="ipRestriction" v-model="ipRestriction" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.ipRestriction != undefined && packageSettingsData.defaultPackageSettings.ipRestriction == "on" ? true : false'>
                                </div>
                            </div>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign">If IP address is not specified for the user, then login will be permitted.</label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>Enable auto login</h5>
                            <hr>
                            <div class="appRow">
                                <div class="appRow">
                                    <div class="col--sm5">
                                        <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable auto login </label>
                                    </div>
                                    <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                        <input type="checkbox" name="autoLogin" v-model="autoLogin" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.autoLogin != undefined && packageSettingsData.defaultPackageSettings.autoLogin == "on" ? true : false'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>Simultaneous Devices</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign">Simultaneous Devices Login</label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <input type="checkbox" name="simultaneousLoginCheck" v-model="simultaneousLoginCheck" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.simultaneousLoginCheck != undefined && packageSettingsData.defaultPackageSettings.simultaneousLoginCheck == "on" ? true : false'>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>Idle Timeout</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5">
                                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Idle Timeout</label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <input type="checkbox" name="idleTimeOut" v-model="idleTimeOut" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.idleTimeOut != undefined && packageSettingsData.defaultPackageSettings.idleTimeOut == "on" ? true : false'>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="appRow">
                            <h5>Hard Timeout</h5>
                            <hr>
                            <div class="appRow">
                                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                                    <label class="appLabel appLabel--primary appLabel--boxInline">Enable Hard Timeout</label>
                                </div>
                                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                                    <input type="checkbox" name="sessionTimeOut" v-model="sessionTimeOut" true-value="on" false-value="off" :checked='packageSettingsData != undefined && packageSettingsData.defaultPackageSettings != undefined && packageSettingsData.defaultPackageSettings.sessionTimeOut != undefined && packageSettingsData.defaultPackageSettings.sessionTimeOut == "on" ? true : false'>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </form>
        <div v-show="selectedTab == 'defaultPackageSettings'" class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <div class="appFormFooter__right" v-if="packageSettingsData.defaultPackageSettings.visible == 'visible'">
              <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="edition == 'isp'"> SubZones Restrictions</button>
              <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else> SubAccounts Restrictions</button>
              <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="edition == 'isp'"> Add to All SubZones</button>
              <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>
              <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveDefaultProfileSettings($event)"> Save</button>
            </div>
          </div>
        </div>

        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
          <div class="pos-relative" dialog-window>
            <div dialog-content>
              <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
                <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
                  <div class="jazeModal__header pb-0">
                    <div class="jazeHeader jazeHeader--centered">
                      <div class="jazeHeader__title" v-if="edition =='isp'"> Subzone Restrictions </div>
                      <div class="jazeHeader__title" v-else> Subaccount Restrictions </div>
                      <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                        <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                          <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div class="jazeModal__main">
                    <div class="jazeModalContent pb-1">
                      <div class="container container--horizontal-cozy container--vertical-comfy">
                        <div class="row">
                          <div class="col jazeFormGroup jazeFormGroup--flex">
                            <label class="jazeLabel jazeLabel--primary" v-if="edition =='isp'">Allow/Deny sub-zones to change default package settings</label>
                            <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to default package settings</label>
                            <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                              <input type="checkbox" v-model="switchcheckboxDefaultPackageSettings" id="switchcheckboxDefaultPackageSettings">
                              <label for="switchcheckboxDefaultPackageSettings"></label>
                            </div>
                          </div>
                        </div>
                        <div class="appType--micro appType--quiet italicized" v-if="edition =='isp'">
                          Note: If it is 'ON', sub-zones can not change the default package settings.
                        </div>
                        <div class="appType--micro appType--quiet italicized" v-else>
                          Note: If it is 'ON', sub-accounts can not change the default package settings.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="jazeModal__footer">
                    <div class="jazeButtonGroup jazeButtonGroup--cozy">
                      <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                      <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
        <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
        <PushToSubZone v-if="pushModal" :selected='selected' :edition="edition" @extend-clicked-push-detail="onclickclose" :subAccounts="packageSettingsData.accountsArr" :from="'defaulPackageSetting'" :modalType="'subAccountsOnly'"></PushToSubZone>
    </div>
</template>

<script setup>
import { ref,toRefs,defineProps,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import { mapState } from "pinia";
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const props = defineProps(["edition","selectedTab"]);

  const { edition, selectedTab } = toRefs(props)
  const autoBind = ref( "" )
  const macRestriction = ref( "" )
  const ipRestriction = ref( "" )
  const autoLogin = ref( "" )
  const simultaneousLoginCheck = ref( "" )
  const idleTimeOut = ref( "" )
  const sessionTimeOut = ref( "" )
  const packageSettingsData = ref( [] )
  const switchcheckboxDefaultPackageSettings = ref( false )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const errorMessage = ref( "" )
  const errorShow = ref( false )
  const subZoneModalShow = ref( false )
  const subzoneType = ref( "" )
  const selectedAccVal = ref( null )
  const subAccOptions = ref( [
          {
            id: "selectAll",
            label: "Select All",
            children: []
          }
        ] )
  const connectionType = ref( 'wireless' )
  const pushModal = ref( false )
  const selected = ref( [] )
  const showMessage = ref ( false )

  const settingsStore = usesettingsStore()
  const { profileSettingsData } = storeToRefs(settingsStore)
  const profileSettingsGetter = computed(() => profileSettingsData.value);

  onMounted(() => {
    getProfileSettings();
  })

  watch(profileSettingsGetter,() => {
    packageSettingsData.value = profileSettingsGetter.value;
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.autoBind != undefined &&
      packageSettingsData.value.defaultPackageSettings.autoBind == "on"
    ) {
      autoBind.value = 'on';
    } else {
      autoBind.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.macRestriction != undefined &&
      packageSettingsData.value.defaultPackageSettings.macRestriction == "on"
    ) {
      macRestriction.value = 'on';
    } else {
      macRestriction.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.ipRestriction != undefined &&
      packageSettingsData.value.defaultPackageSettings.ipRestriction == "on"
    ) {
      ipRestriction.value = 'on';
    } else {
      ipRestriction.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.autoLogin != undefined &&
      packageSettingsData.value.defaultPackageSettings.autoLogin == "on"
    ) {
      autoLogin.value = 'on';
    } else {
      autoLogin.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.simultaneousLoginCheck != undefined &&
      packageSettingsData.value.defaultPackageSettings.simultaneousLoginCheck == "on"
    ) {
      simultaneousLoginCheck.value = 'on';
    } else {
      simultaneousLoginCheck.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.idleTimeOut != undefined &&
      packageSettingsData.value.defaultPackageSettings.idleTimeOut == "on"
    ) {
      idleTimeOut.value = 'on';
    } else {
      idleTimeOut.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.sessionTimeOut != undefined &&
      packageSettingsData.value.defaultPackageSettings.sessionTimeOut == "on"
    ) {
      sessionTimeOut.value = 'on';
    } else {
      sessionTimeOut.value = 'off';
    }
    if (
      packageSettingsData.value.defaultPackageSettings != undefined &&
      packageSettingsData.value.defaultPackageSettings.connectionType != undefined &&
      packageSettingsData.value.defaultPackageSettings.connectionType == "wireless"
    ) {
      connectionType.value = 'wireless';
    } else {
      connectionType.value = 'fiber';
    }
    if (
      packageSettingsData.value.defaultPackageSettings.subAccountsCanNotChangeDefaultPackageSettings == "Yes"
    ) {
      switchcheckboxDefaultPackageSettings.value = true;
    }
    if (
      packageSettingsData.value.accountsArr != undefined &&
      packageSettingsData.value.accountsArr.length != 0
    ) {
      for (let i = 0; i < packageSettingsData.value.accountsArr.length; i++) {
        var deactivated = packageSettingsData.value.accountsArr[i].Account.deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: packageSettingsData.value.accountsArr[i].Account.id,
          label: packageSettingsData.value.accountsArr[i].Account.company_name + deactivated,
        });
      }
    }
  })

  const getProfileSettings = () => {
    if (Object.keys(profileSettingsGetter.value).length == 0) {
      // $store.dispatch("fetchProfileSettings");
      settingsStore.fetchProfileSettings();
    } else {
      packageSettingsData.value = profileSettingsGetter.value;
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.autoBind != undefined &&
          packageSettingsData.value.defaultPackageSettings.autoBind == "on"
      ) {
          autoBind.value = 'on';
      } else {
          autoBind.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.macRestriction != undefined &&
          packageSettingsData.value.defaultPackageSettings.macRestriction == "on"
      ) {
          macRestriction.value = 'on';
      } else {
          macRestriction.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.ipRestriction != undefined &&
          packageSettingsData.value.defaultPackageSettings.ipRestriction == "on"
      ) {
          ipRestriction.value = 'on';
      } else {
          ipRestriction.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.autoLogin != undefined &&
          packageSettingsData.value.defaultPackageSettings.autoLogin == "on"
      ) {
          autoLogin.value = 'on';
      } else {
          autoLogin.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.simultaneousLoginCheck != undefined &&
          packageSettingsData.value.defaultPackageSettings.simultaneousLoginCheck == "on"
      ) {
          simultaneousLoginCheck.value = 'on';
      } else {
          simultaneousLoginCheck.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.idleTimeOut != undefined &&
          packageSettingsData.value.defaultPackageSettings.idleTimeOut == "on"
      ) {
          idleTimeOut.value = 'on';
      } else {
          idleTimeOut.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.sessionTimeOut != undefined &&
          packageSettingsData.value.defaultPackageSettings.sessionTimeOut == "on"
      ) {
          sessionTimeOut.value = 'on';
      } else {
          sessionTimeOut.value = 'off';
      }
      if (
          packageSettingsData.value.defaultPackageSettings != undefined &&
          packageSettingsData.value.defaultPackageSettings.connectionType != undefined &&
          packageSettingsData.value.defaultPackageSettings.connectionType == "wireless"
      ) {
          connectionType.value = 'wireless';
      } else {
          connectionType.value = 'fiber';
      }
      if (
        packageSettingsData.value.defaultPackageSettings.subAccountsCanNotChangeDefaultPackageSettings == "Yes"
      ) {
        switchcheckboxDefaultPackageSettings.value = true;
      }
      if (
        packageSettingsData.value.accountsArr != undefined &&
        packageSettingsData.value.accountsArr.length != 0
      ) {
        for (let i = 0; i < packageSettingsData.value.accountsArr.length; i++) {
          var deactivated = packageSettingsData.value.accountsArr[i].Account.deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: packageSettingsData.value.accountsArr[i].Account.id,
            label: packageSettingsData.value.accountsArr[i].Account.company_name + deactivated,
          });
        }
      }
    }
    setTimeout(() => {
      showMessage.value = true;
    },3000)   //delay for 2.5 seconds
  }
  const saveDefaultProfileSettings = (e) => {
    e.preventDefault();
    var form = document.getElementById('defaultProfileSettingsForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
    }
    postData.defaultPackageSettings = 'yes';
    // $store.dispatch("defaultPackageSettingsSubmit", postData).then(
    settingsStore.defaultPackageSettingsSubmit(postData).then(  
        response => {
            successtoaster.value = true;
            successMessage.value = response;
            setTimeout(
                function() {
                successtoaster.value = false;
                }.bind(this),
                2000
            );
        },
        error => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
                function() {
                errortoaster.value = false;
                }.bind(this),
                15000
            );
            console.log(error);
        }
    );
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxDefaultPackageSettings.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeDefaultPackageSettings",
      type: "defaultPackageSettings"
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      response => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const onclickclose = () => {
    pushModal.value = false;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>