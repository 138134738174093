<template>
  <div v-if="showMessage">
    <div :load='WorkData' v-if="action=='settings'" class="appRow">
      <div class="col--sm4">
        <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Working Hours</label>
      </div>
      <div class="col--sm8 appFormGroup appFormGroup--cozy">
        <input @click="enableCheck()" type="checkbox" name="enableWorkHrs" :checked="enableWrk!=undefined && enableWrk =='yes' ? true : false" /> &emsp;
      </div>
    </div>
    <div v-if="action=='admin' && enableWrk == 'yes'" class="appRow">
      <div class="col--sm4">
        <label class="appLabel appLabel--primary appLabel--boxAlign">Use General Working Hours</label>
      </div>
      <div class="col--sm8 appFormGroup appFormGroup--cozy">
        <input @click="generalCheck()" type="checkbox" name="generalWorkHrs" :checked="enableGeneral!=undefined && enableGeneral =='yes' ? true : false" /> &emsp;
      </div>
    </div>
    <div v-if="(enableWrk == 'yes' && action == 'settings') || (enableWrk == 'yes' && action == 'admin')">
      <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
        <div class="col-6">
          <strong>{{errorMessage}}</strong>
        </div>
      </div>
      <div class="form-group appRow col--sm12">
        <div class="col--sm4">
          <label class="appLabel appLabel--primary appLabel--boxAlign mandatory-field">Working Days</label>
        </div>
        <div class="col--sm6 appFormGroup">
          <treeselect :disabled="enable==false" v-model="daySelect" :multiple="true" :options="dayOption" />
        </div>
      </div>
      <div class="form-group appRow col--sm12">
        <div class="col--sm4">
          <label class="appLabel appLabel--primary appLabel--boxAlign mandatory-field">Working Time From</label>
        </div>
        <div class="col--sm6 appFormGroup">
          <VueCtkDateTimePicker :disabled="enable==false" v-model="customTimeFrom" name="from_time" :noLabel="true" label='Select Time' :format="'HH:mm'" :formatted="'HH:mm'" :only-time="true" :noClearButton="false" />
        </div>
      </div>
      <div class="form-group appRow col--sm12">
        <div class="col--sm4">
          <label class="appLabel appLabel--primary appLabel--boxAlign mandatory-field">Working Time To</label>
        </div>
        <div class="col--sm6 appFormGroup">
          <VueCtkDateTimePicker :disabled="enable==false" v-model="customTimeTo" name="to_time" :noLabel="true" label='Select Time' :format="'HH:mm'" :formatted="'HH:mm'" :only-time="true" :noClearButton="false" />
        </div>
      </div>
    </div>
    <div v-if="action == 'settings' || (enableWrk == 'yes' && enableGeneral == 'no' && action == 'admin')" class="appFormFooter__left">
      <button :disabled="enable==false" class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveEnableWorkConfig">Save</button>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,toRefs,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import VueCtkDateTimePicker from "jaze-ctk-date-time-picker";
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import axios from "@/api/index";
import qs from "qs";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
export default {
  setup(props) {
    const { action, adminId } = toRefs(props)
    const dayOption = ref( [] )
    const customTimeFrom = ref( null )
    const customTimeTo = ref( null )
    const enableWrk = ref( "yes" )
    const enableGeneral = ref( "yes" )
    const daySelect = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const errorShow = ref( false )
    const htmlContent = ref( false )
    const formClass = ref( "" )
    const enable = ref( true )
    const showMessage = ref( false )

    const settingsStore = usesettingsStore()
    const { workhrsData } = storeToRefs(settingsStore)
    const enableWorkingHrsGetters = computed(() => workhrsData.value);

    const WorkData = computed(() => {
      if (
        typeof enableWorkingHrsGetters.value != "undefined" &&
        Object.keys(enableWorkingHrsGetters.value).length > 0
      ) {
        customTimeFrom.value = enableWorkingHrsGetters.value.fromTime;
        customTimeTo.value = enableWorkingHrsGetters.value.toTime;
        enableWrk.value = enableWorkingHrsGetters.value.enableWrk;
        enableGeneral.value = enableWorkingHrsGetters.value.enableGeneral;
        if (enableGeneral.value == "yes") {
          enable.value = false;
        } else {
          enable.value = true;
        }
        return enableWorkingHrsGetters.value;
      }
    })
    const wrkDay = computed(() => {
      if (
        typeof enableWorkingHrsGetters.value != "undefined" &&
        Object.keys(enableWorkingHrsGetters.value).length > 0
      ) {
        return enableWorkingHrsGetters.value.days;
      }
    })

    onMounted(() => {
      getWorkHrs();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    const getWorkHrs = () => {
      if (adminId.value == ":id") {
        adminId.value = "this";
      }

      var postData = {
        adminId: adminId.value,
        action: action.value,
      };

      // $store.dispatch("fetchWorkHrs", postData);
      settingsStore.fetchWorkHrs(postData);
      setTimeout(
        function () {
          dayOption.value = [];
          if( wrkDay.value != undefined ){
            for (var i = 0; i < wrkDay.value.length; i++) {
              dayOption.value.push({
                id: wrkDay.value[i].name,
                label: wrkDay.value[i].name,
              });
            }
          }
          if (
            WorkData.value.dayval != undefined &&
            WorkData.value.dayval != null &&
            WorkData.value.dayval.length != 0
          ) {
            daySelect.value = [];
            for (var i = 0; i < WorkData.value.dayval.length; i++) {
              daySelect.value.push(WorkData.value.dayval[i]);
            }
          }
          showMessage.value = true;
        }.bind(this),
        1000
      );
    }
    const enableCheck = () => {
      if (enableWrk.value == "no") {
        enableWrk.value = "yes";
      } else {
        enableWrk.value = "no";
      }
    }
    const generalCheck = () => {
      if (enableGeneral.value == "no") {
        enableGeneral.value = "yes";
      } else {
        enableGeneral.value = "no";
      }
      if (adminId.value == ":id") {
        adminId.value = "this";
      }
      var postData = {
        enableGeneral: enableGeneral.value,
        adminId: adminId.value,
      };

      axios
        .post(
          "/settings/use_general_workHrs",
          qs.stringify(postData, {
            parseArrays: false,
          })
        )
        .then((res) => {
          daySelect.value = null;
          customTimeFrom.value = null;
          customTimeTo.value = null;
          enableGeneral.value = res.data.response.enableGeneral;
          if (enableGeneral.value == "yes") {
            enable.value = false;
            successtoaster.value = true;
            successMessage.value = res.data.message;
          } else {
            enable.value = true;
          }
          getWorkHrs();
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );

          (error) => {
            errortoaster.value = true;
            errorMessage.value = error;
            setTimeout(
              function () {
                errortoaster.value = false;
              }.bind(this),
              1500
            );
            console.log(error);
          };
        });
    }
    const checkForm = (e, postData) => {
      if (enableWrk.value == "yes") {
        if (
          postData.daySelect == "" ||
          postData.daySelect == null ||
          postData.daySelect == []
        ) {
          errorMessage.value = "Working Days Required";
          errorShow.value = true;
          return "error";
        }
         else{
          errorShow.value = false;
        }
        if (
          postData.customTimeFrom == "" ||
          postData.customTimeFrom == null ||
          postData.customTimeFrom == []
        ) {
          errorMessage.value = "From Time Required";
          errorShow.value = true;
          return "error";
        }
         else{
          errorShow.value = false;
        }
        if (
          postData.customTimeTo == "" ||
          postData.customTimeTo == null ||
          postData.customTimeTo == []
        ) {
          errorMessage.value = "To Time Required";
          errorShow.value = true;
          return "error";
        }
        else{
          errorShow.value = false;
        }
      }
    }
    const saveEnableWorkConfig = (e) => {
      e.preventDefault();
      if (adminId.value == ":id") {
        adminId.value = "this";
      }
      var postData = {
        enableWrk: enableWrk.value,
        enableGeneral: enableGeneral.value,
        customTimeFrom: customTimeFrom.value,
        customTimeTo: customTimeTo.value,
        daySelect: daySelect.value,
        action: action.value,
        adminId: adminId.value,
      };

      var status = checkForm(e, postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      // $store.dispatch("saveWrkData", postData).then(
      settingsStore.saveWrkData(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response.message;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
              errorShow.value = true;
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    }

    return {
      settingsStore,
      enableWorkingHrsGetters:workhrsData,
      WorkData,
      wrkDay,
      action,
      adminId,
      dayOption,
      customTimeFrom,
      customTimeTo,
      enableWrk,
      enableGeneral,
      daySelect,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      errorShow,
      htmlContent,
      formClass,
      enable,
      showMessage,
      getWorkHrs,
      enableCheck,
      generalCheck,
      checkForm,
      saveEnableWorkConfig,
    }
  },
  props: ["action", "adminId"],
  components: {
    ErrorToaster,
    SuccessToaster,
    Treeselect,
    VueCtkDateTimePicker,
  },
  mixins: [overrideIsSelected()],
};
</script>