<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader unifiSettingsHeader--withTabs noBorder">
          <ul class="appTabs appTabs--cozy appTabs--flexible" pageutils-nav-active-class="is-tab-selected">
            <li @click="switchTabs('payAsYouGo')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'payAsYouGo'}">
              <span>Pay As You Go Settings </span>
            </li>
            <li @click="switchTabs('hideOptions')" class="tabs__item" :class="{'is-tab-selected':selectedTab == 'hideOptions'}">
              <span>Hide Pay As You Go Options </span>
            </li>
          </ul>
        </div>
        <form ref="payAsyougoForm" id="payAsyougoForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <fieldset class="appFieldset appFieldset--quiet" v-show="selectedTab=='payAsYouGo'">
            <div class="unifiSettingsSection" v-if="Object.keys(payAsYouGoData).length != 0">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enable one click renew? </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" name="oneClickRenew" :checked="payAsYouGoData.SettingArr!=undefined && payAsYouGoData.SettingArr.Setting!=undefined && payAsYouGoData.SettingArr.Setting.value == '1' ? true : false" @click="toggleOneClickDiv($event)">
                </div>
              </div>
              <div v-if="showOneClickDiv">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Payment collected? </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="paymentCollected" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.paymentCollected!=undefined && payAsYouGoData.renewOptions.paymentCollected == '1' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew from immediate / after expiry? </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="renewFromPresentDate" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewFromPresentDate!=undefined && payAsYouGoData.renewOptions.renewFromPresentDate == '1' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew from previous Renewal? </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="groupChangefrompreviousRenewal" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.groupChangefrompreviousRenewal!=undefined && payAsYouGoData.renewOptions.groupChangefrompreviousRenewal == '1' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew only for paid users? </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="renewForPaidUsers" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewForPaidUsers!=undefined && payAsYouGoData.renewOptions.renewForPaidUsers == '1' ? true : false">
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew with days </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="renewWithDays" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewWithDays!=undefined && payAsYouGoData.renewOptions.renewWithDays == '1' ? true : false" @click="toggleDaysDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showNoOfDaysDiv">
                  <div class="col--sm3"> </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="col--sm3" type="number" name="payAsYouGoDays" v-model="payAsYouGoDays" maxlength="2">
                    <select class="col--sm5" name="renewPeriod">
                      <option value="days" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewPeriod!=undefined && payAsYouGoData.renewOptions.renewPeriod == 'days' ? true : false"> Day(s) </option>
                      <option value="months" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewPeriod!=undefined && payAsYouGoData.renewOptions.renewPeriod == 'months' ? true : false"> Month(s) </option>
                    </select>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Number of times to renew </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <select class="col--sm3" name="renewTimesNumber">
                      <option value="1" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '1' ? true : false"> 1 </option>
                      <option value="2" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '2' ? true : false"> 2 </option>
                      <option value="3" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '3' ? true : false"> 3 </option>
                      <option value="4" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '4' ? true : false"> 4 </option>
                      <option value="5" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '5' ? true : false"> 5 </option>
                      <option value="6" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '6' ? true : false"> 6 </option>
                      <option value="7" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '7' ? true : false"> 7 </option>
                      <option value="8" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '8' ? true : false"> 8 </option>
                      <option value="9" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '9' ? true : false"> 9 </option>
                      <option value="10" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '10' ? true : false"> 10 </option>
                      <option value="11" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '11' ? true : false"> 11 </option>
                      <option value="12" :selected="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesNumber!=undefined && payAsYouGoData.renewOptions.renewTimesNumber == '12' ? true : false"> 12 </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Consider renewal time of a user </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="userRenewalTimeTaken" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.userRenewalTimeTaken!=undefined && payAsYouGoData.renewOptions.userRenewalTimeTaken == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Extend Expiration Date </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm3" type="number" name="extendExpirationDate" v-model="extendExpirationDate" maxlength="2">
                  <label class="appLabel ml-2"> hours </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Balance to renew (customer Portal) </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="UseBalance" :checked="payAsYouGoData.renewalSetting!=undefined && payAsYouGoData.renewalSetting == 'yes' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show Previous Day For Users </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="showPreviousDayPayAsYouGo" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.showPreviousDayPayAsYouGo!=undefined && payAsYouGoData.renewOptions.showPreviousDayPayAsYouGo == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Enter no. of days for revert </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm3" type="number" name="revertDaysPayAsYouGo" v-model="revertDaysPayAsYouGo" maxlength="3">
                  <label class="appLabel ml-2"> days </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Extend Grace Period </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm3" type="number" name="extendGracePeriodRenew" v-model="extendGracePeriodRenew" maxlength="3">
                  <label class="appLabel ml-2"> days </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Maximum days for Grace Period/Pay Later </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm3" type="number" name="maximumGracePeriodOrPayLater" v-model="maximumGracePeriodOrPayLater" maxlength="3">
                  <label class="appLabel ml-2"> days </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Check/Uncheck Payment Collected </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="makePaymentCheck" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.makePaymentCheck!=undefined && payAsYouGoData.renewOptions.makePaymentCheck == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renewal Comments Mandatory </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewalCommentsMandatory" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewalCommentsMandatory!=undefined && payAsYouGoData.renewOptions.renewalCommentsMandatory == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Check/Uncheck Renew only for paid users </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" v-model="renewPaidUsersCheck" name="renewPaidUsersCheck" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewPaidUsersCheck!=undefined && payAsYouGoData.renewOptions.renewPaidUsersCheck == '1' ? true : false">
                </div>
              </div>
              <div class="appRow" v-if="renewPaidUsersCheck == 'on'">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Maximum Unpaid amount while renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm5" type="number" name="maximumUnpaidAmountWhileRenewing" v-model="maximumUnpaidAmountWhileRenewing" min="0"/>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Payment Type </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="selectPaymentTypes" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.selectPaymentTypes!=undefined && payAsYouGoData.renewOptions.selectPaymentTypes == '1' ? true : false" @click="togglePayment($event)">
                </div>
              </div>
              <div v-if="showUploadImage">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Upload image on Payment </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" true-value="on" false-value="off" name="uploadImageOnPaymentRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.uploadImageOnPaymentRenew!=undefined && payAsYouGoData.renewOptions.uploadImageOnPaymentRenew == '1' ? true : false">
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Billing cycle when renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="useBillingCycleRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.useBillingCycleRenew!=undefined && payAsYouGoData.renewOptions.useBillingCycleRenew == '1' ? true : false" @click="toggleBillingDiv($event)">
                </div>
              </div>
              <div class="appRow" v-if='showBillingDiv'>
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Billing cycle when renewing (pro rata basis) </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="useBillingCycleRenewProrataBasis" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.useBillingCycleRenewProrataBasis!=undefined && payAsYouGoData.renewOptions.useBillingCycleRenewProrataBasis == '1' ? true : false" @click="toggleBillingDaysDiv($event)">
                </div>
              </div>
              <div class="appRow" v-if='showBillingDaysDiv'>
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="col--sm3" type="text" name="useBillingCycleRenewProrataBasisDays" v-model="useBillingCycleRenewProrataBasisDays" maxlength="3">
                  <label class="appLabel ml-2"> days </label>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change previous invoice with pro-rata basis (customer portal) </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="renewprorataCustomerPortalDisplay" value="false" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay == undefined) || (payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay==false || payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay==0)) ? true : false">
                      <label for="renewprorataCustomerPortalDisplay"> No </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="renewprorataCustomerPortalDisplay" value="true" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay != undefined && (payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay==true || payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay==1)) ? true : false">
                      <label for="renewprorataCustomerPortalDisplay"> Yes </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="renewprorataCustomerPortalDisplay" value="newInvoice" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay != undefined && payAsYouGoData.renewOptions.renewprorataCustomerPortalDisplay=='newInvoice') ? true : false">
                      <label for="renewprorataCustomerPortalDisplay"> No, but apply difference as discount in new invoice </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change new invoice with pro-rata basis (customer portal) </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewprorataDateCustomerPortalDisplay" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewprorataDateCustomerPortalDisplay!=undefined && payAsYouGoData.renewOptions.renewprorataDateCustomerPortalDisplay == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renewal can be scheduled using after expiry (customer portal) </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="scheduleRenewalafterexpiry" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.scheduleRenewalafterexpiry!=undefined && payAsYouGoData.renewOptions.scheduleRenewalafterexpiry == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override price while renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="overrirdePriceWhileRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.overrirdePriceWhileRenew!=undefined && payAsYouGoData.renewOptions.overrirdePriceWhileRenew == '1' ? true : false" @click="toggleOverrideDiv($event)">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override Voice price while renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="overrirdeVoicePriceWhileRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.overrirdeVoicePriceWhileRenew!=undefined && payAsYouGoData.renewOptions.overrirdeVoicePriceWhileRenew == '1' ? true : false" @click="toggleOverrideDiv($event)">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override IPTV price while renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="overrirdeIptvPriceWhileRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.overrirdeIptvPriceWhileRenew!=undefined && payAsYouGoData.renewOptions.overrirdeIptvPriceWhileRenew == '1' ? true : false" @click="toggleOverrideDiv($event)">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Override OTT price while renewing </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="overrirdeOttPriceWhileRenew" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.overrirdeOttPriceWhileRenew!=undefined && payAsYouGoData.renewOptions.overrirdeOttPriceWhileRenew == '1' ? true : false" @click="toggleOverrideDiv($event)">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show Coupon entry in customer portal </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="showCouponCode" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.showCouponCode!=undefined && payAsYouGoData.renewOptions.showCouponCode == '1' ? true : false" @click="toggleOverrideDiv($event)">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Check/Uncheck SubUser Renewal </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="subUserRenewalCheck" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.subUserRenewalCheck!=undefined && payAsYouGoData.renewOptions.subUserRenewalCheck == '1' ? true : false">
                </div>
              </div>
              <div class="appRow" v-if="showOverrideDiv">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> While override price enabled </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="overrirdePriceintermsof" value="modifyProduct" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.overrirdePriceintermsof == undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.overrirdePriceintermsof=='modifyProduct') ? true : false">
                      <label for="overrirdePriceintermsof"> Change in product </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="overrirdePriceintermsof" value="discount" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.overrirdePriceintermsof !=undefined && payAsYouGoData.renewOptions.overrirdePriceintermsof== 'discount')) ? true : false">
                      <label for="overrirdePriceintermsof"> Discount/Penality </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Generate invoice </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="dontGenerateInvoiceRenew" value="0" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.dontGenerateInvoiceRenew == undefined) || (payAsYouGoData.renewOptions!=undefined && ((payAsYouGoData.renewOptions.dontGenerateInvoiceRenew != undefined && payAsYouGoData.renewOptions.dontGenerateInvoiceRenew == '0'))) ? true : false">
                      <label for="dontGenerateInvoiceRenew"> Always </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="dontGenerateInvoiceRenew" value="1" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.dontGenerateInvoiceRenew !=undefined && payAsYouGoData.renewOptions.dontGenerateInvoiceRenew== '1')) ? true : false">
                      <label for="dontGenerateInvoiceRenew"> Don't generate while renewing </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="dontGenerateInvoiceRenew" value="2" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.dontGenerateInvoiceRenew !=undefined && payAsYouGoData.renewOptions.dontGenerateInvoiceRenew== '2')) ? true : false">
                      <label for="dontGenerateInvoiceRenew"> Don't generate while renewing except from Customer Portal </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="dontGenerateInvoiceRenew" value="3" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.dontGenerateInvoiceRenew !=undefined && payAsYouGoData.renewOptions.dontGenerateInvoiceRenew== '3')) ? true : false">
                      <label for="dontGenerateInvoiceRenew"> Generate proforma invoice instead of invoice </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="dontGenerateInvoiceRenew" value="4" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.dontGenerateInvoiceRenew !=undefined && payAsYouGoData.renewOptions.dontGenerateInvoiceRenew== '4')) ? true : false">
                      <label for="dontGenerateInvoiceRenew"> Don't generate while adding user (payasyougo users)</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Clear Usage Policies </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="clearPolicies" value="yes" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.clearPolicies != undefined && payAsYouGoData.renewOptions.clearPolicies == 'yes')) ? true : false">
                      <label for="clearPolicies"> Yes </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="clearPolicies" value="no" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.clearPolicies == undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.clearPolicies != undefined && payAsYouGoData.renewOptions.clearPolicies == 'no') ? true : false">
                      <label for="clearPolicies"> No </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Always schedule the renewal when user select's after expiry </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="alwaysScheduleRenew" value="yes" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.alwaysScheduleRenew != undefined && payAsYouGoData.renewOptions.alwaysScheduleRenew == 'yes')) ? true : false">
                      <label for="alwaysScheduleRenew"> Yes </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="alwaysScheduleRenew" value="no" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.alwaysScheduleRenew == undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.alwaysScheduleRenew != undefined && payAsYouGoData.renewOptions.alwaysScheduleRenew == 'no') ? true : false">
                      <label for="alwaysScheduleRenew"> No </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Always schedule the OTT/IPTV/Voice  activations when user select's after expiry </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="alwaysScheduleotherPlatformRenew" value="yes" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.alwaysScheduleotherPlatformRenew != undefined && payAsYouGoData.renewOptions.alwaysScheduleotherPlatformRenew == 'yes')) ? true : false">
                      <label for="alwaysScheduleotherPlatformRenew"> Yes </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="alwaysScheduleotherPlatformRenew" value="no" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.alwaysScheduleotherPlatformRenew == undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.alwaysScheduleotherPlatformRenew != undefined && payAsYouGoData.renewOptions.alwaysScheduleotherPlatformRenew == 'no') ? true : false">
                      <label for="alwaysScheduleotherPlatformRenew"> No </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don't clear data while changing group with pro-rata basis </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="clearDataWhileGroupChanging" value="yes" :checked="(payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.clearDataWhileGroupChanging != undefined && payAsYouGoData.renewOptions.clearDataWhileGroupChanging == 'yes')) ? true : false">
                      <label for="clearDataWhileGroupChanging"> Yes </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="clearDataWhileGroupChanging" value="no" :checked="(payAsYouGoData.renewOptions == undefined || payAsYouGoData.renewOptions.clearDataWhileGroupChanging == undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.clearDataWhileGroupChanging != undefined && payAsYouGoData.renewOptions.clearDataWhileGroupChanging == 'no') ? true : false">
                      <label for="clearDataWhileGroupChanging"> No </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="appRow" v-if="payAsYouGoData.parentFranchiseHead == 'yes' && payAsYouGoData.franchiseRevenueSharing !='' && payAsYouGoData.franchiseRevenueSharing !=null && payAsYouGoData.franchiseExclude == 'no'"> -->
              <div class="appRow" v-if="(payAsYouGoData.parentFranchiseHead == 'yes' && payAsYouGoData.franchiseRevenueSharing !=null && payAsYouGoData.franchiseExclude == 'no') || (payAsYouGoData.franchiseHead == 'yes' && payAsYouGoData.franchiseExclude == 'no')">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Allow recharge through customer portal even if balance is zero or negative </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="allowCustomerNegativeRecharge" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.allowCustomerNegativeRecharge!=undefined && payAsYouGoData.renewOptions.allowCustomerNegativeRecharge == '1' ? true : false">
                </div>
              </div>
              <div class="appRow" >
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Use Package Price instead of old price while renew without change plan </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="usePackagePricewithoutChangeplanRenewal" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.usePackagePricewithoutChangeplanRenewal!=undefined && payAsYouGoData.renewOptions.usePackagePricewithoutChangeplanRenewal == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> If grace Period is enabled, show grace period date in customer portal </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="showGracePeriodDateInCP" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.showGracePeriodDateInCP!=undefined && payAsYouGoData.renewOptions.showGracePeriodDateInCP == '1' ? true : false">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Display OTT packages based on plan validity </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="displayOTTbasedOnPlan" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.displayOTTbasedOnPlan!=undefined && payAsYouGoData.renewOptions.displayOTTbasedOnPlan == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Don't apply Bundle and add on OTTs in a single renewal</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox"  true-value="on" false-value="off"  name="dontapplybundleandaddonott" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.dontapplybundleandaddonott!=undefined && payAsYouGoData.renewOptions.dontapplybundleandaddonott == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Enable Apply OTT by default</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox"  true-value="on" false-value="off"  name="enableApplyOttByDefault" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.enableApplyOttByDefault!=undefined && payAsYouGoData.renewOptions.enableApplyOttByDefault == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Hide OTT Billing Type</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox"  true-value="on" false-value="off"  name="hideOTTBillingType" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.hideOTTBillingType!=undefined && payAsYouGoData.renewOptions.hideOTTBillingType == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Default OTT Billing Type </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultOTTBillingType" value="single" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultOTTBillingType != undefined && payAsYouGoData.renewOptions.defaultOTTBillingType=='single') ? true : false">
                      <label for="defaultOTTBillingType"> Single Bill</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultOTTBillingType" value="separate" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultOTTBillingType != undefined && payAsYouGoData.renewOptions.defaultOTTBillingType=='separate') ? true : false">
                      <label for="defaultOTTBillingType"> Separate Bill </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultOTTBillingType" value="ottRenewOnly" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultOTTBillingType != undefined && payAsYouGoData.renewOptions.defaultOTTBillingType=='ottRenewOnly') ? true : false">
                      <label for="defaultOTTBillingType"> Renew OTT Only (Separate Bill) </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultOTTBillingType" value="none" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultOTTBillingType != undefined && payAsYouGoData.renewOptions.defaultOTTBillingType=='none') ? true : false">
                      <label for="defaultOTTBillingType"> None </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Display IPTV packages based on plan validity </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="displayIPTVbasedOnPlan" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.displayIPTVbasedOnPlan!=undefined && payAsYouGoData.renewOptions.displayIPTVbasedOnPlan == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Hide IPTV Billing Type</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="hideIPTVBillingType" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.hideIPTVBillingType!=undefined && payAsYouGoData.renewOptions.hideIPTVBillingType == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Default IPTV Billing Type </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultIPTVBillingType" value="single" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType != undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType=='single') ? true : false">
                      <label for="defaultIPTVBillingType"> Single Bill</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultIPTVBillingType" value="separate" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType != undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType=='separate') ? true : false">
                      <label for="defaultIPTVBillingType"> Separate Bill </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultIPTVBillingType" value="iptvRenewOnly" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType != undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType=='separate') ? true : false">
                      <label for="defaultIPTVBillingType"> Renew Iptv Only (Separate Bill) </label>
                    </div>
                    
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultIPTVBillingType" value="none" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType != undefined && payAsYouGoData.renewOptions.defaultIPTVBillingType=='none') ? true : false">
                      <label for="defaultIPTVBillingType"> None </label>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Display Voice packages based on plan validity </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="displayVoicebasedOnPlan" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.displayVoicebasedOnPlan!=undefined && payAsYouGoData.renewOptions.displayVoicebasedOnPlan == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Hide Voice Billing Type</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="hideVoiceBillingType" :checked="payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.hideVoiceBillingType!=undefined && payAsYouGoData.renewOptions.hideVoiceBillingType == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Default Voice Billing Type </label>
                </div>
                <div class="col--sm9 appFormGroup appFormGroup--cozy">
                  <div class="appInputGroup verticalRhythmTop--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultVoiceBillingType" value="single" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType != undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType=='single') ? true : false">
                      <label for="defaultVoiceBillingType"> Single Bill</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultVoiceBillingType" value="separate" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType != undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType=='separate') ? true : false">
                      <label for="defaultVoiceBillingType"> Separate Bill </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultVoiceBillingType" value="voiceRenewOnly" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType != undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType=='separate') ? true : false">
                      <label for="defaultVoiceBillingType">  Renew Voice Only (Separate Bill) </label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock">
                      <input type="radio" name="defaultVoiceBillingType" value="none" :checked="(payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType != undefined && payAsYouGoData.renewOptions.defaultVoiceBillingType=='none') ? true : false">
                      <label for="defaultVoiceBillingType">None </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show only its subplan on change plan (CP) </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="onlySubplanShow" :checked="payAsYouGoData.renewOptions != undefined && payAsYouGoData.renewOptions.onlySubplanShow != undefined && payAsYouGoData.renewOptions.onlySubplanShow == '1' ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Admin can't renew users if CAF not present </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="cafCheckOnRenew" :checked="payAsYouGoData.renewOptions != undefined && payAsYouGoData.renewOptions.cafCheckOnRenew != undefined && payAsYouGoData.renewOptions.cafCheckOnRenew == '1' ? true : false">
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="appFieldset appFieldset--quiet" v-show="selectedTab=='hideOptions'">
            <div class="unifiSettingsSection" v-if="Object.keys(payAsYouGoData).length != 0">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Payment Collected </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="paymentCollectedDisplay" :checked="(payAsYouGoData.renewOptions== undefined || payAsYouGoData.renewOptions.paymentCollectedDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.paymentCollectedDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renewal Comments </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewalCommentsDisplay" :checked="(payAsYouGoData.renewOptions== undefined || payAsYouGoData.renewOptions.renewalCommentsDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewalCommentsDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew from immediate / after expiry </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewPresentDateDisplay" :checked="payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.renewPresentDateDisplay!=undefined && payAsYouGoData.renewOptions.renewPresentDateDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew from previous renewal </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="groupChangefrompreviousRenewalDisplay" :checked="payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.groupChangefrompreviousRenewalDisplay!=undefined && payAsYouGoData.renewOptions.groupChangefrompreviousRenewalDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew only for paid users </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewPaidUsersDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewPaidUsersDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewPaidUsersDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Renew with days </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewWithDaysDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewWithDaysDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewWithDaysDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Number of times to renew </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewTimesDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewTimesDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewTimesDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Plan </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewChangePlanDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewChangePlanDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewChangePlanDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Plan According to Group </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewChangePlanGroupDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewChangePlanGroupDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewChangePlanGroupDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Group on Pro-rata basis </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewprorataDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewprorataDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewprorataDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Change Group on Pro-rata Date basis </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="renewprorataDateDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.renewprorataDateDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.renewprorataDateDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Schedule Renewal </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="scheduleRenewDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.scheduleRenewDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.scheduleRenewDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Apply Coupon </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="applyCuponDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.applyCuponDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.applyCuponDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show all options for super admin </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="superAdminDisplay" :checked="payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.superAdminDisplay!=undefined && payAsYouGoData.renewOptions.superAdminDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Show discount calculation </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="discountCalculationDisplay" :checked="payAsYouGoData.renewOptions!=undefined && (payAsYouGoData.renewOptions.discountCalculationDisplay!=undefined && payAsYouGoData.renewOptions.discountCalculationDisplay == '1') ? true : false">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Sub User Renew </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="subUserRenewDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.subUserRenewDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.subUserRenewDisplay == '1') ? true : false">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> IPTV Plans </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="iptvPlansDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.iptvPlansDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.iptvPlansDisplay == '1') ? true : false">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> OTT Plans </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="ottPlansDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.ottPlansDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.ottPlansDisplay == '1') ? true : false">
                </div>
              </div>
               <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign"> Voice Plans </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="checkbox" true-value="on" false-value="off" name="voicePlansDisplay" :checked="(payAsYouGoData.renewOptions==undefined || payAsYouGoData.renewOptions.voicePlansDisplay==undefined) || (payAsYouGoData.renewOptions!=undefined && payAsYouGoData.renewOptions.voicePlansDisplay == '1') ? true : false">
                </div>
              </div>
          </div>
           
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="payAsYouGoData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="payAsYouGoData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="payAsYouGoData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="savePayAsYouGo"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="payAsYouGoData.edition =='isp'">Allow/Deny sub-zones to change Pay As You Go settings</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change Pay As You Go settings</label> &emsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxPay" id="switchcheckboxPay" :checked="payAsYouGoData.subAccountsCanNotChangePayAsYouGoSettings == 'Yes' ? true : false">
                          <label for="switchcheckboxPay"> </label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="payAsYouGoData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change Pay As You Go settings .
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change Pay As You Go settings .
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="payAsYouGoData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'payasyougoSettings'" :modalType="'subAccountsOnly'"></PushToSubZone>

  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'

  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);
  const showBillingDiv = ref( false )
  const showBillingDaysDiv = ref( false )
  const useBillingCycleRenewProrataBasisDays = ref( 0 )
  // const payAsYouGoData = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const showOneClickDiv = ref( false )
  const showNoOfDaysDiv = ref( false )
  const showUploadImage = ref( false )
  const showOverrideDiv = ref( false )
  const payAsYouGoDays = ref( 30 )
  const extendExpirationDate = ref( 0 )
  const revertDaysPayAsYouGo = ref( 7 )
  const extendGracePeriodRenew = ref( 0 )
  const maximumGracePeriodOrPayLater = ref( 0 )
  const subZoneModalShow = ref( false )
  const selectedTab = ref( "payAsYouGo" )
  const selectedAccVal = ref( null )
  const switchcheckboxPay = ref( false )
  const pushModal = ref( false )
  const selected = ref( [] )
  // const subAccounts = ref( [] )
  const renewPaidUsersCheck = ref('off')
  const maximumUnpaidAmountWhileRenewing = ref(0)
  const settingsStore = usesettingsStore()
  const { payAsYouGoData } = storeToRefs(settingsStore)
  const payAsYouGoGetter = computed(() => payAsYouGoData.value);

  onMounted(() => {
    getPayAsYouGoDetails();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    payAsYouGoData.value = payAsYouGoGetter.value;
    if (
      payAsYouGoData.value.SettingArr != undefined &&
      payAsYouGoData.value.SettingArr.Setting != undefined &&
      payAsYouGoData.value.SettingArr.Setting.value == "1"
    ) {
      showOneClickDiv.value = true;
    }
    if (payAsYouGoData.value.renewOptions != undefined) {
      if (
        payAsYouGoData.value.renewOptions.selectPaymentTypes != undefined &&
        payAsYouGoData.value.renewOptions.selectPaymentTypes == "1"
      ) {
        showUploadImage.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.renewWithDays != undefined &&
        payAsYouGoData.value.renewOptions.renewWithDays == "1"
      ) {
        showNoOfDaysDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.overrirdePriceWhileRenew !=
          undefined &&
        payAsYouGoData.value.renewOptions.overrirdePriceWhileRenew == "1"
      ) {
        showOverrideDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.overrirdeVoicePriceWhileRenew !=
          undefined &&
        payAsYouGoData.value.renewOptions.overrirdeVoicePriceWhileRenew == "1"
      ) {
        showOverrideDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.overrirdeIptvPriceWhileRenew !=
          undefined &&
        payAsYouGoData.value.renewOptions.overrirdeIptvPriceWhileRenew == "1"
      ) {
        showOverrideDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.overrirdeOttPriceWhileRenew !=
          undefined &&
        payAsYouGoData.value.renewOptions.overrirdeOttPriceWhileRenew == "1"
      ) {
        showOverrideDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.payAsYouGoDays != undefined &&
        payAsYouGoData.value.renewOptions.payAsYouGoDays != ""
      ) {
        payAsYouGoDays.value = payAsYouGoData.value.renewOptions.payAsYouGoDays;
      }
      if (
        payAsYouGoData.value.renewOptions.useBillingCycleRenew != undefined &&
        payAsYouGoData.value.renewOptions.useBillingCycleRenew == "1"
      ) {
        showBillingDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions.useBillingCycleRenewProrataBasis !=
          undefined &&
        payAsYouGoData.value.renewOptions.useBillingCycleRenewProrataBasis ==
          "1"
      ) {
        showBillingDaysDiv.value = true;
      }
      if (
        payAsYouGoData.value.renewOptions
          .useBillingCycleRenewProrataBasisDays != undefined &&
        payAsYouGoData.value.renewOptions
          .useBillingCycleRenewProrataBasisDays != ""
      ) {
        useBillingCycleRenewProrataBasisDays.value =
          payAsYouGoData.value.renewOptions.useBillingCycleRenewProrataBasisDays;
      }
      if (
        payAsYouGoData.value.renewOptions.extendExpirationDate != undefined
      ) {
        extendExpirationDate.value =
          payAsYouGoData.value.renewOptions.extendExpirationDate;
      }
      if (
        payAsYouGoData.value.renewOptions.revertDaysPayAsYouGo != undefined
      ) {
        revertDaysPayAsYouGo.value =
          payAsYouGoData.value.renewOptions.revertDaysPayAsYouGo;
      }
      if (
        payAsYouGoData.value.renewOptions.extendGracePeriodRenew != undefined
      ) {
        extendGracePeriodRenew.value =
          payAsYouGoData.value.renewOptions.extendGracePeriodRenew;
      }
      if (
        payAsYouGoData.value.renewOptions.maximumGracePeriodOrPayLater !=
        undefined
      ) {
        maximumGracePeriodOrPayLater.value =
          payAsYouGoData.value.renewOptions.maximumGracePeriodOrPayLater;
      }        
      if (
        payAsYouGoData.value.renewOptions.maximumUnpaidAmountWhileRenewing !=
        undefined
      ) {
        maximumUnpaidAmountWhileRenewing.value =
          payAsYouGoData.value.renewOptions.maximumUnpaidAmountWhileRenewing;
      }
      if(payAsYouGoData.value.renewOptions != undefined && payAsYouGoData.value.renewOptions.renewPaidUsersCheck != undefined && payAsYouGoData.value.renewOptions.renewPaidUsersCheck == '1') {
        renewPaidUsersCheck.value = "on";
      }
      
    }
    if (
      payAsYouGoData.value.subAccountsCanNotChangePayAsYouGoSettings == "Yes"
    ) {
      switchcheckboxPay.value = true;
    }
  })

  const getPayAsYouGoDetails = () => {
    // $store.dispatch("fetchPayAsYouGoDetails");
    settingsStore.fetchPayAsYouGoDetails();
  }
  const toggleOneClickDiv = (event) => {
    if (event.target.checked == true) {
      showOneClickDiv.value = true;
    } else {
      showOneClickDiv.value = false;
    }
  }
  const toggleDaysDiv = (event) => {
    if (event.target.checked == true) {
      showNoOfDaysDiv.value = true;
    } else {
      showNoOfDaysDiv.value = false;
    }
  }
  const togglePayment = (event) => {
    if (event.target.checked == true) {
      showUploadImage.value = true;
    } else {
      showUploadImage.value = false;
    }
  }
  const toggleOverrideDiv = (event) => {
    if (event.target.checked == true) {
      showOverrideDiv.value = true;
    } else {
      showOverrideDiv.value = false;
    }
  }
  const toggleBillingDiv = (event) => {
    if (event.target.checked == true) {
      showBillingDiv.value = true;
    } else {
      showBillingDiv.value = false;
    }
  }
  const toggleBillingDaysDiv = (event) => {
    if (event.target.checked == true) {
      showBillingDaysDiv.value = true;
    } else {
      showBillingDaysDiv.value = false;
    }
  }
  const switchTabs = (tab) => {
    selectedTab.value = tab;
  }
  const savePayAsYouGo = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    var form = document.getElementById('payAsyougoForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    // $store.dispatch("payAsYouGoSettingsSubmit", postData).then(
    settingsStore.payAsYouGoSettingsSubmit(postData).then(  
      (response) => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (switchcheckboxPay.value == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangePayAsYouGoSettings",
      type: "payAsYouGo",
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      (response) => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function () {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      (error) => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function () {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const onclickclose = () => {
    pushModal.value = false;
  }
  const pushZonefn = () => {
    pushModal.value = !pushModal.value;
  }

</script>