<template>
   
  
  <div class="jazePanelContainer jazePanelContainer--vertical setLayout" style="overflow:auto;">
    <!-- Sidebar content starts-->
    <div class="jazeSidePanel jazeSidePanel--settings visible--mdUp">
      <div class="jazePanelContent jazePanelContent--scrollable settings">
        <div class="appViewSidePanel__title">Settings</div>
        <div class=" ml-0" align="left">
        <!-- Search bar -->
        <div class="appActionBarFilter appActionBarFilter--search ml-2" align="center">
          <form class="jazeFormGroup--headerSearch jazeFormGroup d-block ng-pristine ng-valid ng-valid-required jazeFormGroup--comfy">
            <div class="is-input-visible jazeIconInputContainer jazeIconInputContainer--comfy jazeIconInputContainer--transition">
              <input v-model="search" @input='searchData' placeholder="Search" class="jazeIconInput jazeIconInput--bookend jazeIconInput--comfy jazeIconInput--standalone">
              <div class="jazeIconInput__icon unifiAuraIcon">
                <div style="overflow:auto;">
                <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="jazeIcon jazeIcon--actionable is-hidden-when-form-group-is-inactive">
                  <g fill="none" fill-rule="evenodd">
                    <g transform="translate(-10 -9)">
                      <g transform="translate(10 9)">
                        <circle cx="7" cy="7" r="6.5" stroke="currentColor"></circle>
                        <rect transform="translate(13.725 13.725) rotate(-45) translate(-13.725 -13.725)" x="13.225" y="10.725" width="1" height="6" rx=".5" fill="currentColor"></rect>
                      </g>
                    </g>
                  </g>
                </svg>
                </div>
              </div>
            </div>
          </form>
        </div>
    <!-- Search bar ends -->
        </div>

        <ul class="appMenu appMenu--transparent appMenu--gradient appMenu--sidePanel">
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.general==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'general'}" @click="navigate('/settings/general')">
              <a>
                <span class="label-value">General Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.express==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'express'}" @click="navigate('/settings/express')">
              <a>
                <span class="label-value">Express Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.miscellaneous==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'miscellaneous'}" @click="navigate('/settings/miscellaneous')">
              <a>
                <span class="label-value">Miscellaneous Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.billing_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'billing_settings'}" @click="navigate('/settings/billing_settings')">
              <a>
                <span class="label-value">Billing</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.Billing==true && sidebarDetails.permissions.billing_address==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'billing_address'}" @click="navigate('/settings/billing_address')">
              <a>
                <span class="label-value">Billing Address</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.router_settings==true" @click="navigate('/settings/router_settings')">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'router_settings'}">
              <a>
                <span class="label-value">Router Visibility</span>
              </a>
            </li>
          </div>
          <li v-if="sidebarDetails.jazePlatform == 'hardware' && sidebarDetails.unifiEnabled && 0" class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'Ubiquiti Settings'}">
            <a href="/settings/ubiquiti_settings">
              <span class="label-value">Ubiquiti</span>
            </a>
          </li>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.apis==true">
          <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'apis'}" @click="navigate('/settings/apis')">
            <a>
              <span class="label-value">API</span>
            </a>
          </li>
          </div>
          <div v-if="sidebarDetails.edition=='cloud'">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'social_settings'}" @click="navigate('/settings/social_settings')">
              <a>
                <span class="label-value">Social Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.Billing==true && sidebarDetails.permissions.billing_period==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'billing_period'}" @click="navigate('/settings/billing_period')">
              <a>
                <span class="label-value">Billing Period</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.prefix_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'prefix_settings'}" @click="navigate('/settings/prefix_settings')">
              <a>
                <span class="label-value">Prefix Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.table_header_configuration==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'table_header_configuration'}" @click="navigate('/settings/table_header_configuration')">
              <a>
                <span class="label-value">Table Header Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.report_column_visible_configuration==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'report_column_visible_configuration'}" @click="navigate('/settings/report_column_visible_configuration')">
              <a>
                <span class="label-value">Report Column Visible Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails.superAdmin != undefined && sidebarDetails.superAdmin == 'yes'">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'admin_settings'}" @click="navigate('/settings/admin_settings')">
              <a>
                <span class="label-value">Admin User Groups Control</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.task_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'task_settings'}" @click="navigate('/settings/task_settings')">
              <a>
                <span class="label-value">Approval Tasks</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.FranchiseManagement==true && (sidebarDetails.franchiseHead == 'no' || sidebarDetails.franchiseExclude == 'yes') && sidebarDetails.permissions.profile_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'profile_settings'}" @click="navigate('/settings/profile_settings')">
              <a>
                <span class="label-value" v-if="sidebarDetails.edition=='isp'">Package Settings</span>
                <span class="label-value" v-else>Profile Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.FranchiseManagement==true && sidebarDetails.thisAccountIsFranchiseTopAccount == 1">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'tag_payment_gateway'}" @click="navigate('/settings/tag_payment_gateway')">
              <a>
                <span class="label-value">Tag Payment Gateway</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.payasyougo_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'payasyougo_settings'}" @click="navigate('/settings/payasyougo_settings')">
              <a>
                <span class="label-value">Pay As You Go Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.lead_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'lead_settings'}" @click="navigate('/settings/lead_settings')">
              <a>
                <span class="label-value">Lead Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.custom_rules==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'custom_rules'}" @click="navigate('/settings/custom_rules')">
              <a>
                <span class="label-value">Custom Rules Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.user_mandatory_fields==true">
            <li v-if="sidebarDetails.edition=='cloud'" class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'user_mandatory_fields'}" @click="navigate('/settings/user_mandatory_fields')">
              <a>
                <span class="label-value">Voucher Fields</span>
              </a>
            </li>
            <li v-else-if="(sidebarDetails.edition=='isp' || sidebarDetails.edition=='campus')" class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'user_mandatory_fields'}" @click="navigate('/settings/user_mandatory_fields')">
              <a>
                <span class="label-value">User Fields</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.user_template_notifications==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'user_notifications_settings'}" @click="navigate('/settings/user_notifications_settings')">
              <a>
                <span class="label-value"> User Notifications </span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.additional_fields==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'additional_fields'}" @click="navigate('/settings/additional_fields')">
              <a>
                <span class="label-value">Additional Fields</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.dashboard_configuration==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'dashboard_configuration'}" @click="navigate('/settings/dashboard_configuration')">
              <a>
                <span class="label-value">Dashboard Configuration</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.customer_portal_url==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'customer_portal_url'}" @click="navigate('/settings/customer_portal_url')">
              <a>
                <span class="label-value">Customer Portal URL</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.FranchiseManagement==true && sidebarDetails.thisAccountIsFranchiseTopAccount == 1">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'franchise_settings'}" @click="navigate('/settings/franchise_settings')">
              <a>
                <span class="label-value">Franchise Settings</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.pms==true && sidebarDetails.jazePlatform == 'hardware' && sidebarDetails.edition != 'isp'">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'pms_settings'}" @click="navigate('/settings/pms_settings')">
              <a>
                <span class="label-value">PMS</span>
              </a>
          </li>
          </div>
          <li v-if="(sidebarDetails.parentFranchiseHead == 'no' || sidebarDetails.franchiseHead == 'yes') && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.delete_account==true && sidebarDetails.onlyAccountsShow == 'yes'" class="appMenu__item clickable">
            <a @click="deleteAccountModalShow=true; getAccDetails()">
              <span class="label-value" v-if="sidebarDetails.edition=='isp'">Delete sub-zone</span>
              <span class="label-value" v-else>Delete sub-account</span>
            </a>
          </li>
          <li v-if="(sidebarDetails.parentFranchiseHead == 'no' || sidebarDetails.franchiseHead == 'yes') && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.deactivate_account==true && sidebarDetails.onlyAccountsShow == 'yes'" class="appMenu__item clickable">
            <a @click="deActivateAccountModalShow=true; getAccDetails()">
              <span class="label-value" v-if="sidebarDetails.edition=='isp'">Activate/Deactivate sub-zone</span>
              <span class="label-value" v-else>Activate/Deactivate sub-account</span>
            </a>
          </li>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.sms_management==true">
            <li v-if="(sidebarDetails.showSmsManagement != undefined && sidebarDetails.showSmsManagement == 'yes') && sidebarDetails.jazePlatform == 'hardware'" class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'sms_management'}" @click="navigate('/settings/sms_management')">
              <a>
                <span class="label-value">SMS Management</span>
              </a>
            </li>
            <li v-else-if="(sidebarDetails.cloudSmsManagement != undefined && sidebarDetails.cloudSmsManagement == 'yes') && sidebarDetails.jazePlatform != 'hardware'" class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'sms_managements'}" @click="navigate('/settings/sms_managements')">
              <a>
                <span class="label-value">SMS Management</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.payment_collection_settings==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'payment_collection_settings'}" @click="navigate('/settings/payment_collection_settings')">
              <a>
                <span class="label-value">Payment Collection</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.tally_informations==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'tally_informations'}" @click="navigate('/settings/tally_informations')">
              <a>
                <span class="label-value">Tally Configurations</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails.showMoveAccounts !=undefined && sidebarDetails.showMoveAccounts == 'yes' && sidebarDetails.permissions.move_accounts==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'move_accounts'}" @click="navigate('/settings/move_accounts')">
              <a>
                <span class="label-value" v-if="sidebarDetails.edition=='isp'">Move zone</span>
                <span class="label-value" v-else>Move Account</span>

              </a>
            </li>
          </div>
          <li class="appMenu__item clickable" v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.delete_account==true">
            <a @click="enableFranchiseModalShow=true; getAccDetails()">
              <span class="label-value">Enable franchise</span>
            </a>
          </li>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.admins_with_access==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'admins_with_access'}" @click="navigate('/settings/admins_with_access')">
              <a>
                <span class="label-value">Admins with access</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.enable_working_hours==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'enable_working_hours'}" @click="navigate('/settings/enable_working_hours')">
              <a>
                <span class="label-value"> Customize Working Hours</span>
              </a>
            </li>
          </div>
          <div v-if="sidebarDetails!=undefined && sidebarDetails.permissions!=undefined && sidebarDetails.permissions.payment_virtual_account==true">
            <li class="appMenu__item clickable" :class="{'is-menu-item-selected':title_for_layout == 'payment_virtual_account'}" @click="navigate('/settings/payment_virtual_account')">
              <a>
                <span class="label-value"> Payment Virtual Account</span>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <!-- Main content starts-->
    <generalConfig v-if="title_for_layout=='general'"> </generalConfig>
    <tallyConfig v-if="title_for_layout=='tally_informations'"> </tallyConfig>
    <expressConfig v-if="title_for_layout=='express'"> </expressConfig>
    <dashboardConfig v-if="title_for_layout=='dashboard_configuration'"></dashboardConfig>
    <paymentCollection v-if="title_for_layout=='payment_collection_settings'"></paymentCollection>
    <customerPortalURL v-if="title_for_layout=='customer_portal_url'"></customerPortalURL>
    <pmsSettings v-if="title_for_layout=='pms_settings'"></pmsSettings>
    <franchiseSettings v-if="title_for_layout=='franchise_settings'"></franchiseSettings>
    <adminsWithAccess v-if="title_for_layout=='admins_with_access'"> </adminsWithAccess>
    <miscellaneousConfig v-if="title_for_layout=='miscellaneous'"> </miscellaneousConfig>
    <billing v-if="title_for_layout=='billing_settings'"> </billing>
    <billingAddress v-if="title_for_layout=='billing_address'"> </billingAddress>
    <routerVisibility v-if="title_for_layout=='router_settings'"> </routerVisibility>
    <apiSettings v-if="title_for_layout=='apis'"> </apiSettings>
    <billingPeriod v-if="title_for_layout=='billing_period'"> </billingPeriod>
    <socialSettings v-if="title_for_layout=='social_settings'"> </socialSettings>
    <prefixSettings v-if="title_for_layout=='prefix_settings'"> </prefixSettings>
    <taskSettings v-if="title_for_layout=='task_settings'"> </taskSettings>
    <payAsYouGo v-if="title_for_layout=='payasyougo_settings'"> </payAsYouGo>
    <leadSettings v-if="title_for_layout=='lead_settings'"> </leadSettings>
    <customRules v-if="title_for_layout=='custom_rules'"> </customRules>
    <adminSettings v-if="title_for_layout=='admin_settings'"> </adminSettings>
    <profileSettings v-if="title_for_layout=='profile_settings'"> </profileSettings>
    <tagPaymentGateway v-if="title_for_layout=='tag_payment_gateway'"> </tagPaymentGateway>
    <userFieldSettings v-if="title_for_layout=='user_mandatory_fields'"> </userFieldSettings>
    <addFieldSettings v-if="title_for_layout=='additional_fields'"> </addFieldSettings>
    <smsManagementSettings v-if="title_for_layout=='sms_management'"> </smsManagementSettings>
    <smsManagementsSettings v-if="title_for_layout=='sms_managements'"> </smsManagementsSettings>
    <tableHeaderConfig v-if="title_for_layout=='table_header_configuration'"> </tableHeaderConfig>
    <reportColumnVisibleConfig v-if="title_for_layout=='report_column_visible_configuration'"> </reportColumnVisibleConfig>
    <moveAccounts v-if="title_for_layout=='move_accounts'"> </moveAccounts>
    <workingHours v-if="title_for_layout=='enable_working_hours'"> </workingHours>
    <userNotifications v-if="title_for_layout=='user_notifications_settings'"> </userNotifications>
    <paymentVirtualAccount v-if="title_for_layout=='payment_virtual_account'"> </paymentVirtualAccount>
    <settingsSearchResult @search-title-for-layout="searchTitleSet" :searchResult ="searchResult" v-if="title_for_layout=='search_settings'"> </settingsSearchResult>
   <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
  <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
 

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteAccountModalShow" style="display:flex">
    <div class="jazeModal unifiConfirm jazeModal--medium">
      <div class="jazeModal__header pb-0">
        <div class="jazeHeader jazeHeader--centered">
          <div class="jazeHeader__title" v-if="sidebarDetails.edition =='isp'">Delete Zone</div>
          <div class="jazeHeader__title" v-else>Delete Account</div>
          <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteAccountModalShow=false">
            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
              <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
            </g>
          </svg>
        </div>
      </div>
      <div class="jazeModal__main" style="height:300px;overflow:auto">
        <form class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="jazeModalContent pb-1">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div v-if="sidebarDetails.edition =='isp'">Select the zone to delete</div>
              <div v-else>Select the account to delete</div>
              <br />
              <treeselect v-model="accountToDelete" :options="zoneOptions" :always-open="true" :searchable="true" :default-expand-level="Infinity" :placeholder="(zoneOptions.length == 0) ? 'Loading...' : 'Search'" noOptionsText="Loading data from server" ></treeselect>
            </fieldset>
          </div>
        </form>
      </div>
      <div class="jazeModal__footer">
        <div class="jazeButtonGroup jazeButtonGroup--cozy">
          <button class="jazeButton jazeButton--secondary" @click="deleteAccountModalShow=false">Cancel</button>
          <button class="jazeButton jazeButton--primary submitForm" v-if="sidebarDetails.edition =='isp'" @click="delAccount"> Delete Zone </button>
          <button class="jazeButton jazeButton--primary submitForm" v-else @click="delAccount">Delete Account</button>
        </div>
      </div>
    </div>
  </div>

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deActivateAccountModalShow" style="display:flex">
    <div class="jazeModal unifiConfirm jazeModal--medium">
      <div class="jazeModal__header pb-0">
        <div class="jazeHeader jazeHeader--centered">
          <div class="jazeHeader__title" v-if="sidebarDetails.edition =='isp'">Activate/Deactivate Zone</div>
          <div class="jazeHeader__title" v-else>Activate/Deactivate Account</div>
          <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deActivateAccountModalShow=false">
            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
              <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
            </g>
          </svg>
        </div>
      </div>
      <div class="jazeModal__main" style="height:300px; overflow:auto;">
        <form class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="jazeModalContent pb-1">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div v-if="sidebarDetails.edition =='isp'">Select the zone to Activate/Deactivate</div>
              <div v-else>Select the account to Activate/Deactivate</div>
              <br />
              <treeselect v-model="accountToDeactivate" :options="zoneOptions" :always-open="true" :searchable="true" :default-expand-level="Infinity" :placeholder="(zoneOptions.length == 0) ? 'Loading...' : 'Search'" noOptionsText="Loading data from server"></treeselect>
            </fieldset>
          </div>
        </form>
      </div>
      <div class="jazeModal__footer">
        <div class="jazeButtonGroup jazeButtonGroup--cozy">
          <button class="jazeButton jazeButton--secondary" @click="deActivateAccountModalShow=false">Cancel</button>
          <button class="jazeButton jazeButton--primary submitForm" v-if="sidebarDetails.edition =='isp'" @click="deactivateAccount"> Activate/Deactivate Zone </button>
          <button class="jazeButton jazeButton--primary submitForm" v-else @click="deactivateAccount">Activate/Deactivate Account</button>
        </div>
      </div>
    </div>
  </div>

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="enableFranchiseModalShow" style="display:flex">
    <div class="jazeModal unifiConfirm jazeModal--medium">
      <div class="jazeModal__header pb-0">
        <div class="jazeHeader jazeHeader--centered">
          <div class="jazeHeader__title" v-if="sidebarDetails.edition =='isp'">Enable Franchise Zone</div>
          <div class="jazeHeader__title" v-else>Enable Franchise Account</div>
          <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="enableFranchiseModalShow=false">
            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
              <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
            </g>
          </svg>
        </div>
      </div>
      <div class="jazeModal__main" style="height:300px;overflow:auto">
        <form class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="jazeModalContent pb-1">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div v-if="sidebarDetails.edition =='isp'">Select the zone to enable franchise</div>
              <div v-else>Select the account to enable franchise</div>
              <br />
              <treeselect v-model="accountToEnableFranchise" :options="zoneOptions" :always-open="true" :searchable="true" :default-expand-level="Infinity" :placeholder="(zoneOptions.length == 0) ? 'Loading...' : 'Search'" noOptionsText="Loading data from server"></treeselect>
            </fieldset>
          </div>
        </form>
      </div>
      <div class="jazeModal__footer">
        <div class="jazeButtonGroup jazeButtonGroup--cozy">
          <button class="jazeButton jazeButton--secondary" @click="enableFranchiseModalShow=false">Cancel</button>
          <button class="jazeButton jazeButton--primary submitForm" @click="enableFranchise"> Enable Franchise</button>
        </div>
      </div>
    </div>
  </div>

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="delConfirmModal" style="display:flex">
    <div class="pos-relative" dialog-window>
      <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
        <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
          <div class="jazeModal__header pb-0">
            <div class="jazeHeader jazeHeader--centered">
              <div class="jazeHeader__title">Delete</div>
              <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="delConfirmModal=false">
                <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                  <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                </g>
              </svg>
            </div>
          </div>
          <div class="jazeModal__main">
            <div class="jazeModalContent pb-1">
              <div class="container container--horizontal-cozy container--vertical-comfy">
                <div v-if="sidebarDetails.edition =='isp'">Are you sure you want to permanently delete the selected zone?</div>
                <div v-else>Are you sure you want to permanently delete the selected account?</div>
              </div><br>
            </div>
          </div>
          <div class="jazeModal__footer">
            <div class="jazeButtonGroup jazeButtonGroup--cozy">
              <button class="jazeButton jazeButton--secondary" type="button" @click="delConfirmModal=false">Cancel</button>
              <button class="jazeButton jazeButton--primary submitForm" type="button" @click="delAccountConfirm">Yes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deactivateConfirmModal" style="display:flex">
    <div class="pos-relative" dialog-window>
      <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
        <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
          <div class="jazeModal__header pb-0">
            <div class="jazeHeader jazeHeader--centered">
              <div class="jazeHeader__title">{{actionType}}</div>
              <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deactivateConfirmModal=false">
                <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                  <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                </g>
              </svg>
            </div>
          </div>
          <div class="jazeModal__main">
            <div class="jazeModalContent pb-1">
              <div class="container container--horizontal-cozy container--vertical-comfy">
                <div v-if="sidebarDetails.edition =='isp'">Are you sure you want to permanently {{actionType}} the selected zone?</div>
                <div v-else>Are you sure you want to permanently {{actionType}} the selected account?</div>
              </div><br>
              <div class="container container--horizontal-cozy container--vertical-comfy" v-if="actionType=='Deactivate'">
                <input type="checkbox" v-model="terminateAllUsers" @click="showTerminateOption = !showTerminateOption">
                <label v-if="sidebarDetails.edition =='isp'">Terminate all active users present in the zone</label>
                <label v-else>Terminate all active users present in the account</label>
              </div><br>
              <div class="appType--micro appType--quiet italicized" v-if="showTerminateOption">
                Note: By clicking 'Yes', the security deposits payable to the customer (if any), will be lost.
              </div><br>
              <div class="container container--horizontal-cozy container--vertical-comfy" v-if="showTerminateOption">
                <div> What to do with the unpaid invoices? </div> <br>
                <div class="row col-12">
                  <div class="col-5">
                    <input type="radio" name="unpaidAct" value="doNothing" @click="unpaidAction='doNothing'">
                    <label for="unpaidAct"> Do Nothing </label>
                  </div>
                  <div class="col-5">
                    <input type="radio" name="unpaidAct" value="deleteInvoice" @click="unpaidAction='deleteInvoice'">
                    <label for="unpaidAct"> Delete </label>
                  </div>
                </div>
              </div>
              <div class="container container--horizontal-cozy container--vertical-comfy" v-if="actionType=='Activate'">
                <input type="checkbox" v-model="reactivateAllUsers">
                <label v-if="sidebarDetails.edition =='isp'">Reactivate all active users present in the zone</label>
                <label v-else>Reactivate all active users present in the account</label>
              </div><br>
            </div>
          </div>
          <div class="jazeModal__footer">
            <div class="jazeButtonGroup jazeButtonGroup--cozy">
              <button class="jazeButton jazeButton--secondary" type="button" @click="deactivateConfirmModal=false">Cancel</button>
              <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deactivateAccountConfirm">Yes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="enableConfirmModal" style="display:flex">
    <div class="pos-relative" dialog-window>
      <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
        <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
          <div class="jazeModal__header pb-0">
            <div class="jazeHeader jazeHeader--centered">
              <div class="jazeHeader__title">Enable Franchise</div>
              <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="enableConfirmModal=false">
                <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                  <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                </g>
              </svg>
            </div>
          </div>
          <div class="jazeModal__main">
            <div class="jazeModalContent pb-1">
              <div class="container container--horizontal-cozy container--vertical-comfy">
                <div v-if="sidebarDetails.edition =='isp'">Are you sure you want to enable Franchise for selected zone?</div>
                <div v-else>Are you sure you want to enable Franchise for selected account?</div>
              </div><br>
            </div>
          </div>
          <div class="jazeModal__footer">
            <div class="jazeButtonGroup jazeButtonGroup--cozy">
              <button class="jazeButton jazeButton--secondary" type="button" @click="enableConfirmModal=false">Cancel</button>
              <button class="jazeButton jazeButton--primary submitForm" type="button" @click="enableFranchiseConfirm">Yes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue';
import axios from "@/api/index";
import PageContainerLayout from "@/components/layout/PageContainerLayout.vue";
import Treeselect from "jaze-vue3-treeselect";
import generalConfig from "@/components/Settings/GeneralConfig.vue";
import tallyConfig from "@/components/Settings/TallyConfig.vue";
import dashboardConfig from "@/components/Settings/DashboardConfig.vue";
import paymentCollection from "@/components/Settings/PaymentCollection.vue";
import customerPortalURL from "@/components/Settings/CustomerPortalURL.vue";
import pmsSettings from "@/components/Settings/PMSSettings.vue";
import franchiseSettings from "@/components/Settings/FranchiseeSettings.vue";
import adminsWithAccess from "@/components/Settings/AdminsWithAccess.vue";
import expressConfig from "@/components/Settings/ExpressConfig.vue";
import miscellaneousConfig from "@/components/Settings/MiscellaneousConfig.vue";
import billing from "@/components/Settings/Billing.vue";
import billingAddress from "@/components/Settings/BillingAddress.vue";
import routerVisibility from "@/components/Settings/RouterVisibilty.vue";
import apiSettings from "@/components/Settings/Api.vue";
import billingPeriod from "@/components/Settings/BillingPeriod.vue";
import socialSettings from "@/components/Settings/SocialSettings.vue";
import prefixSettings from "@/components/Settings/PrefixSettings.vue";
import taskSettings from "@/components/Settings/TaskSettings.vue";
import payAsYouGo from "@/components/Settings/PayAsYouGoSettings.vue";
import leadSettings from "@/components/Settings/LeadSettings.vue";
import customRules from "@/components/Settings/CustomRules.vue";
import adminSettings from "@/components/Settings/AdminSettings.vue";
import profileSettings from "@/components/Settings/ProfileSettings.vue";
import tagPaymentGateway from "@/components/Settings/TagPaymentGateway.vue";
import userFieldSettings from "@/components/Settings/UserFieldSettings.vue";
import addFieldSettings from "@/components/Settings/AdditionalFields.vue";
import smsManagementSettings from "@/components/Settings/SmsManagement.vue";
import smsManagementsSettings from "@/components/Settings/SmsManagementCloud.vue";
import tableHeaderConfig from "@/components/Settings/TableHeaderConfig.vue";
import reportColumnVisibleConfig from "@/components/Settings/UserDetailsReportVisibleColumn.vue";
import moveAccounts from "@/components/Settings/MoveAccounts.vue";
import workingHours from "@/components/Settings/WorkingHours.vue";
import userNotifications from "@/components/Settings/UserNotifications.vue";
import paymentVirtualAccount from "@/components/Settings/paymentVirtualAccount.vue";
import settingsSearchResult from "@/components/Settings/SettingsSearchResult.vue";
import Nprogress from "nprogress";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import qs from "qs";

import { uselayoutStore } from '@/store/layoutstore'
import { storeToRefs } from "pinia";
import { useRouter,useRoute } from 'vue-router';
import { usesettingsStore } from '@/store/settingsstore'

const route = useRoute()
const router = useRouter()
const successtoaster = ref( false )
const successMessage = ref( "" )
const errortoaster = ref( false )
// const sidebarDetails = ref( [] )
const zoneOptions = ref( [] )
const title_for_layout = ref( "Settings" )
const formClass = ref( "" )
const errorShow = ref( false )
const errorMessage = ref( "" )
const deleteAccountModalShow = ref( false )
const deActivateAccountModalShow = ref( false )
const enableFranchiseModalShow = ref( false )
const accountToDelete = ref( null )
const accountToDeactivate = ref( null )
const accountToEnableFranchise = ref( null )
const delConfirmModal = ref( false )
const deactivateConfirmModal = ref( false )
const enableConfirmModal = ref( false )
const terminateAll = ref( false )
const unpaidInvoiceActionShow = ref( false )
const unpaidInvoiceAction = ref( "" )
const actionType = ref( "" )
const terminateAllUsers = ref( false )
const reactivateAllUsers = ref( false )
const showTerminateOption = ref( false )
const unpaidAction = ref( "" )
const search = ref( null )
const searchResult = ref( null )

const layoutStore = uselayoutStore()
const { headerDetails,subaccDetails,generateSubAccountsTreeVue } = storeToRefs(layoutStore)
const generateSubAccountsTreeVueGetter= computed(() => generateSubAccountsTreeVue.value);
const headerDetailsGetter = computed(() => headerDetails.value)
const subaccDetailsGetter= computed(() => subaccDetails.value);
const settingsStore = usesettingsStore()
const { sidebarDetails } = storeToRefs(settingsStore)
const sidebarDetailsGetter = computed(() => sidebarDetails.value)

onMounted(() => {
    getsidebarDetails();
})
onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
})

watchEffect(() => {
  sidebarDetails.value = sidebarDetailsGetter.value;
})
watchEffect(() => {
  // if(headerDetailsGetter.value.Auth != undefined) {
  //   zoneOptions.value = headerDetailsGetter.value.Auth.Dashboard.generateSubAccountsTreeVue;
  // }
})

const getsidebarDetails = () => {
  if (Object.keys(sidebarDetailsGetter.value).length == 0) {
    // $store.dispatch("fetchSidebarDetails");
    settingsStore.fetchSidebarDetails();
  } else {
    sidebarDetails.value = sidebarDetailsGetter.value;
  }
  var urlArr = route.path.split("/");
  title_for_layout.value = urlArr[2];
}
  watch(generateSubAccountsTreeVueGetter,()=>{
    if (generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0 && zoneOptions.value != undefined && zoneOptions.value.length == 0) {
      zoneOptions.value = generateSubAccountsTreeVueGetter.value;
    }
  });
const getAccDetails = () => {
  if(generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0) {
    zoneOptions.value = generateSubAccountsTreeVueGetter.value;
  }else{
    layoutStore.fetchGenerateSubAccountsTreeVue();
  }
}
const navigate = (path) => {
  router.push(path);
  var urlArr = path.split("/");
  title_for_layout.value = urlArr[2];
}
const delAccount = () => {
  if (accountToDelete.value == null) {
    errorMessage.value = "No account has been selected";
    errorShow.value = true;
    formClass.value = "is-form-validation-visible";
    return;
  }
  errorShow.value = false;
  delConfirmModal.value = true;
}
const delAccountConfirm = () => {
  const formData = new FormData();
  formData.append("account_id", accountToDelete.value);
  axios
    .post("/dashboard/delete_account", formData, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
    .then(response => {
      delConfirmModal.value = false;
      if (response.data.status == "success") {
        deleteAccountModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = 'Account deleted successfully';
         setTimeout(
        function () {
          successtoaster.value = false;
          window.location.reload();
        }.bind(this),
        2000
      );
        
      } else {
        errortoaster.value = true;
        errorMessage.value = response.data.message;
        errorShow.value = true;
        formClass.value = "is-form-validation-visible";
        return;
      }
    })
    .catch(error => {
      console.log(error);
    });
}
const deactivateAccount = () => {
  if (accountToDeactivate.value == null) {
    errorMessage.value = "No account has been selected";
    errorShow.value = true;
    formClass.value = "is-form-validation-visible";
    return;
  }
  if (subaccDetailsGetter.value.length != 0) {
    for (let i = 0; i < subaccDetailsGetter.value.length-1; i++) {
      if (
        subaccDetailsGetter.value[i].id == accountToDeactivate.value
      ) {
        if (subaccDetailsGetter.value[i].deactivated == 1) {
          actionType.value = "Deactivate";
        } else {
          actionType.value = "Activate";
        }
      }
    }
  }
  errorShow.value = false;
  deactivateConfirmModal.value = true;
}
const deactivateAccountConfirm = () => {
  const formData = new FormData();
  if (terminateAllUsers.value == true) {
    var terminate = "on";
  } else {
    var terminate = "off";
  }
  if (reactivateAllUsers.value == true) {
    var reactivate = "on";
  } else {
    var reactivate = "off";
  }
  formData.append("account_id", accountToDeactivate.value);
  formData.append("terminate_users", terminate);
  formData.append("reactivate_users", reactivate);
  formData.append("unpaidInvoicesdecesion", unpaidAction.value);
  axios
    .post("/dashboard/deactivate_account", formData, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
    .then(response => {
      deactivateConfirmModal.value = false;
      if (response.data.status == "success") {
        deActivateAccountModalShow.value = false;
        window.location.reload();
      } else {
        errorMessage.value = response.data.message;
        errorShow.value = true;
        formClass.value = "is-form-validation-visible";
        return;
      }
    })
    .catch(error => {
      console.log(error);
    });
}
const enableFranchise = () => {
  if (accountToEnableFranchise.value == null) {
    errorMessage.value = "No account has been selected";
    errorShow.value = true;
    formClass.value = "is-form-validation-visible";
    return;
  }
  errorShow.value = false;
  enableConfirmModal.value = true;
}
const enableFranchiseConfirm = () => {
  const formData = new FormData();
  formData.append("account_id", accountToEnableFranchise.value);
  axios
    .post("/dashboard/enable_franchise_account", formData, {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    })
    .then(response => {
      enableConfirmModal.value = false;
      if (response.data.status == "success") {
        deleteAccountModalShow.value = false;
        window.location.reload();
      } else {
        errorMessage.value = response.data.message;
        errorShow.value = true;
        formClass.value = "is-form-validation-visible";
        return;
      }
    })
    .catch(error => {
      console.log(error);
    });
}
const searchData = (e) => {
  search.value = e.target.value != "" ? e.target.value : null;
  if(search.value != "" && search.value != null){
    var path = "/settings/search_settings";
    router.push(path);
    var urlArr = path.split("/");
    title_for_layout.value = urlArr[2];
    searchSettings();
  }else{
    var path = "/settings/general";
    router.push(path);
    var urlArr = path.split("/");
    title_for_layout.value = urlArr[2];
  }    
}
const searchSettings = () => {
  var postData = {
    // accountId: accountId.value,
    searchData:search.value
  };
  Nprogress.start();
  axios.post('/settings/search_settings', qs.stringify(postData, {
    parseArrays: false
  }))
      .then(response => {
        Nprogress.done();
       searchResult.value=response.data.message;
      })
      .catch(error => {
        console.log(error);
      });
}
const searchTitleSet = (value) => {
  var path= "/settings/"+value;
  router.push(path);
  var urlArr = path.split("/");
  title_for_layout.value = value;
  search.value = "";
}

</script>