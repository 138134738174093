import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usehelpdeskStore = defineStore('helpdeskstore', {
    state: () => {
        return {
            dashBoardData: [],
            recentData: [],
            ticketsData: [],
            ticketDetails: [],
            ticketConfig: [],
            ticketSLA: [],
            ticketRules: []
        }
    },
    getters: {
        dashBoardDataState: (state) => state.dashBoardData,
        dashboardListGetters() {
            return this.dashBoardDataState;
        },
        recentDataState: (state) => state.recentData,
        recentDataListGetters() {
            return this.recentDataState;
        },
        ticketsDataState: (state) => state.ticketsData, 
        ticketsDataListGetters() {
            return this.ticketsDataState;
        },
        ticketDetailsState: (state) => state.ticketDetails, 
        ticketsDetailsListGetters() {
            return this.ticketDetailsState;
        },
        ticketConfigState: (state) => state.ticketConfig, 
        ticketsConfigListGetters() {
            return this.ticketConfigState;
        },
        ticketSLAState: (state) => state.ticketSLA, 
        ticketsSLAListGetters() {
            return this.ticketSLAState;
        },
        ticketRulesState: (state) => state.ticketRules,
        ticketsRulesListGetters() {
            return this.ticketRules;
        },
    },
    actions: {
        fetchTicketsHistoryData(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/get_ticket_task_admins", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchDashboardHelpdeskDatas() {
            Nprogress.start();
            return new Promise((resolve, reject) => {
                axios.get('/helpdesk/dashboard_list')
                    .then(res => {
                        Nprogress.done();
                        if (res.data.status == "redirect") {
                            router.push(res.data.message);
                        } else {
                            this.dashBoardData = res.data;
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 403) {
                                window.location = "/dashboard";
                            }
                        }
                    })
            })
        },
        getRecentHelpDeskLogs(postData) {
            Nprogress.start();
            return new Promise((resolve, reject) => {
                const vueData = true;
                axios
                    .post("/helpdesk/data/" + vueData, qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        Nprogress.done();
                        this.recentData = response.data    
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTicketsData(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/all_tickets_data", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        if (postData.export == true) {
                            if (response.data.status != "success") {
                            reject(response.data.message);
                            return;
                            }
                            window.open("/backend" + response.data.message, "_blank");
                            window.focus();
                            return;
                        }
                        this.ticketsData = response.data    
                        resolve(response.data);
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 403) {
                                window.location = "/dashboard";
                            }
                        }
                    });
            });
        },
        editTicketLabel(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/editTicketLabel", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        if (response.data.status != "success") {
                            reject(response.data);
                            return;
                        }
                        var ticketId = postData.ticketId;
                        var label = postData.label;
                        if(label == null){
                            label = "";
                        }
                        if (Object.keys(this.ticketsData).length != 0) {
                            var index = this.ticketsData.allData.findIndex(tickt => tickt.Tickets.id == ticketId);
                            if (index != -1) {
                                this.ticketsData.allData[index].Tickets.label = label;
                            }   
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/deleteTickets", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        if (response.data.status != "success") {
                            reject(response.data);
                            return;
                        }
                        if(postData.type == 'selected') {
                            var successData = {
                                ticketId: postData.ticketId,
                            }
                        } else {
                            var successData = {
                                ticketId: response.data.ticketIds,
                            }
                        }                        
                        var ticketId = successData.ticketId;
                        if (Object.keys(this.ticketsData).length != 0) {
                            for (let i = 0; i < ticketId.length; i++) {
                                var index = this.ticketsData.allData.findIndex(tickt => tickt.Tickets.id == ticketId[i].id);
                                if (index != -1) {
                                    this.ticketsData.allData.splice(index, 1);
                                }   
                            }
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        sendMessageTicket(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios
                    .post("/helpdesk/send_ticket_message", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        JsLoadingOverlay.hide();;
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        editPreferredTimeField(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/editPreferredTimeField", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        if (response.data.status != "success") {
                            reject(response.data);
                            return;
                        }
                        var ticketId = postData.ticketId;
                        var preferreddate = postData.preferreddate;
                        var preferredtime = postData.preferredtime+":00:00";
                        if (Object.keys(this.ticketsData).length != 0) {
                            var index = this.ticketsData.allData.findIndex(tickt => tickt.Tickets.id == ticketId);
                            if (index != -1) {
                                this.ticketsData.allData[index].Tickets.preferred_time = preferreddate;
                                this.ticketsData.allData[index].Tickets.time_interval = preferredtime;
                            }   
                        }                        
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        getTicketDetailsData(authData) {
            Nprogress.start();
        return new Promise((resolve, reject) => {
            axios.get('/helpdesk/ticket_details_list/' + authData)
                .then(res => {
                    Nprogress.done();
                    if(!(res.data.status=="error" && res.data.isDatabaseAccessibleCheck=="yes")){
                        this.ticketDetails = res.data
                    }
                    resolve(res.data);
                })
                .catch(error => console.log(error))
            })
        },
        getHeldeskAllSubjectFromTicket(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/getAllSubjects", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        getMessageTicketResolution(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/getAllTicketResolutionSubjects", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        sendcloseticketotp(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/send_close_ticket_otp", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveChangesinTicketDetails(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/change_properties", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTicketConfigDatas() {
            Nprogress.start();
            axios.get('/helpdesk/ticket_config_list')
                .then(res => {
                    Nprogress.done();
                    this.ticketConfig = res.data
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        getSubjectInfoDetals(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/get_subject_info_details", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteDetailsTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        configEditSubject(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        configEditPriority(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_priority", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        configEditLabel(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_label_name", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSubjectInfoDetals(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/submit_info_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubjectTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addNewLabelTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_label_name", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        editOthersTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_others", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        editVisibleadminsTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_only_visible_for_admin", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteTicketSubject(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_fields", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveResSubjectDetails(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_resolution_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        resSubjectRemove(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_resolution_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubjectOneLabel(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_resolution_subject", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubjectOneSource(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_ticket_source", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        removeOneSource(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_ticket_source", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveAddOnesubject(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/customize_ticket_resolution", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        removeAllsubresolution(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_resolution_fields", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveenableTickets(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/enable_ticket_resolution", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addToSubzoneSave(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_ticket_config_subacc", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteTypeVisibilityAdmin(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_admin_view_type_ticket_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        newAddField(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/customize_cp", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTicketSLAData() {
            Nprogress.start();
            axios.get('/helpdesk/ticket_slas_list')
                .then(res => {
                    Nprogress.done();
                    this.ticketSLA = res.data
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        saveSLAEmail(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_field/email", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveSLASms(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/edit_field/sms", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addAssignSlaFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/admin_view_assignedall_tickets_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addNotifyAdminFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/admin_reopen_tickets_notify_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addUnAssignSlaFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/admin_view_unassigned_tickets_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addAssignOtpFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/admin_view_assigned_otp_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addExcludeOtpFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/admin_view_assigned_exclude_otp_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addTypeVisibilityForAdmins(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/adminTypeVisible", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteUnAssignedAdmin(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_admin_view_unassigned_ticket_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteViewOtpFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_admin_otp_ticket_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteExcludeOtpFn(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_admin_exclude_otp_ticket_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteAssignedAdmin(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_admin_view_assignedall_ticket_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteReopenTicketNotifyAdmin(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/delete_reopen_ticket_notify_admin_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        saveAllDataTicketSla(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios
                    .post("/helpdesk/store_sla_values", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubAccountTIcketSla(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_ticket_sla_to_all_accounts", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchTicketRulesData() {
            Nprogress.start();
            axios.get('/helpdesk/ticket_rules_list')
                .then(res => {
                    Nprogress.done();
                    this.ticketRules = res.data;
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            window.location = "/dashboard";
                        }
                    }
                })
        },
        addRulesTicketsPost(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/ticket_rule_settings", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        deleteRulesTicketsPost(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/settings/delete_custom_rule", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        addSubAccountTIcketRules(postData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/helpdesk/add_ticket_rules_to_all_accounts", qs.stringify(postData, {
                        parseArrays: false
                    }))
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => console.log(error));
            });
        },
        
    }
})