<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="taskSettingsForm" id="taskSettingsForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Approval Tasks</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> User Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="userApprovalEnable" :checked="taskSettingsData.userApproval!=undefined && taskSettingsData.userApproval == 'yes' ? true : false" @click="toggleAccountsDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showUserApproveDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for user approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="userApprovalIds" v-model="selectedAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Create Order Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="createOrderApprovalEnable" :checked="taskSettingsData.orderApproval!=undefined && taskSettingsData.orderApproval == 'yes' ? true : false" @click="toggleOrdersDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showCreateOrderDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for order approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="orderApprovalIds" v-model="selectedOrderAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>

                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Discount/Penalty Approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="createDiscountApprovalEnable" :checked="taskSettingsData.dicountApproval!=undefined && taskSettingsData.dicountApproval == 'yes' ? true : false" @click="toggleDicountDiv($event)">
                  </div>
                </div>
                <div class="appRow" v-if="showCreateDiscountDiv==true">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign"> Select the accounts for discount/penalty approval </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <treeselect name="discountApprovalIds" v-model="selectedDiscountAccounts" :multiple="true" :options="subAccOptions" :searchable="true" :clearable="false" :defaultExpandLevel="1" :disable-branch-nodes="true"></treeselect>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Admin View Assigned Tasks </label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input type="checkbox" name="adminViewAssignedTasksEnable" :checked="taskSettingsData.adminViewAssignedTasks!=undefined && taskSettingsData.adminViewAssignedTasks == 'yes' ? true : false" @click="toggleAdminViewAssignedTasksDiv($event)">
                  </div>
                </div>
                <div v-if="showAdminViewAssignedTasksDiv">
                  <table class="jazeTable jazeTable--hoverable noBorder mt-1 is-not-responsive">
                    <thead>
                      <tr>
                        <th>
                          <span></span>
                        </th>
                        <th>
                          <span>Admin Classification Groups</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="jazeTableBody">
                      <tr>
                        <td>Admin View all Tasks</td>
                        <td>
                          <ul>
                            <li v-for="(admin , index) in adminToassignedAllLevels" :key="index">
                              <strong>{{admin.name}}</strong>&nbsp;&nbsp;({{admin.levels}})
                              <button title="Delete" @click="deleteAssignedTaskAdmin(admin.id)" type="button" class="danger jazeButton jazeButton--link jazeButton--noWrap mr-4 floatRight"><span class="button__icon icon jaze-icon--trashcan"></span></button>
                            </li>
                          </ul>
                          <button title="Add" @click="showAddAssign = !showAddAssign" type="button" class="jazeButton jazeButton--link jazeButton--noWrap mr-4 floatRight"><span class="button__icon icon  jaze-icon--plus"></span></button>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </fieldset>
        </form>
        <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" style="display:flex" dialog-layout="confirmLayout" v-if="showAddAssign">
          <div class="jazeModal unifiConfirm jazeModal--medium">
            <div class="jazeModal__header pb-0">
              <div class="jazeHeader jazeHeader--centered">
                <div class="jazeHeader__title">Add admin classification to tasks group</div>
                <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="showAddAssign = false">
                  <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                    <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                  </g>
                </svg>
              </div>
            </div>
            <div class="jazeModal__main">
              <div class="jazeModalContent pb-1">
                <div class="appRow col--sm12">
                  <label class="appLabel col--sm4 appLabel--primary appLabel--boxInline">Admin Classifications</label>
                  <div class="col--sm8 appFormGroup">
                    <select class="width-full" v-model="postassigned1">
                      <option v-for="admin in adminToGroups" :value="admin.AdminClassification.id" :key="admin.AdminClassification.id">{{admin.AdminClassification.group_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="appRow col--sm12">
                  <label class="appLabel col--sm4 appLabel--primary appLabel--boxInline">Levels</label>
                  <div class="col--sm8 appFormGroup">
                    <treeselect v-model="postassigned2" :multiple="true" :options="restrictOption" />
                  </div>
                </div>
              </div>
            </div>
            <div class="jazeModal__footer">
              <div class="jazeButtonGroup jazeButtonGroup--cozy">
                <button class="jazeButton jazeButton--secondary" type="button" @click="showAddAssign = false">Cancel</button>
                <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addAssignTasksFn()">Ok</button>
              </div>
            </div>

          </div>
        </div>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveApprovalSettings"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { uselayoutStore } from '@/store/layoutstore'
import { usesettingsStore } from '@/store/settingsstore'
import { storeToRefs } from "pinia";

    const taskSettingsData = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const showUserApproveDiv = ref( false )
    const showCreateOrderDiv = ref( false )
    const showCreateDiscountDiv = ref( false )
    const showAdminViewAssignedTasksDiv = ref(false)
    const selectedAccounts = ref( new Array() )
    const selectedOrderAccounts = ref( new Array() )
    const selectedDiscountAccounts = ref( new Array() )
    const subAccOptions = ref( [{
              id: 'selectAll',
              label: 'Select All',
              children: []
          }] )
    const adminToGroups = ref([]);
    const postassigned2 = ref(null);
    const postassigned1 = ref('');
    const restrictOption = ref([
      {
        id: '1',
        label: 'Level 1'
      },
      {
        id: '2',
        label: 'Level 2'
      },
      {
        id: '3',
        label: 'Level 3'
      }
    ]);
    const adminToassignedAllLevels = ref([]);
    const showAddAssign = ref(false);
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const settingsStore = usesettingsStore()
    const { approvalTaskData } = storeToRefs(settingsStore)
    const approvalTasksGetter = computed(() => approvalTaskData.value);

    const applyMixin = () => {
      const mixin = overrideIsSelected();
      const context = {
        $options: {
          components: { Treeselect },
        },
      };
      mixin.created.call(context);
    };

    onMounted(() => {
      getTaskDetails();
      applyMixin();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })
      watch(subaccDetailsGetter, () => {
       if (
        subaccDetailsGetter.value != undefined &&
        subaccDetailsGetter.value.length != 0
      ) {
      if(subaccDetailsGetter.value!=undefined && subaccDetailsGetter.value.length !=0) {
          for(let i=0; i<subaccDetailsGetter.value.length-1; i++) {
             subAccOptions.value[0].children.push({id:subaccDetailsGetter.value[i].id, label:subaccDetailsGetter.value[i].name})
          }
        }
      }
    })
    watch(approvalTasksGetter, () => {
      taskSettingsData.value = approvalTasksGetter.value;
      if(taskSettingsData.value.userApprovalAccounts!=undefined && taskSettingsData.value.userApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.userApprovalAccounts.length; i++) {
              selectedAccounts.value.push(taskSettingsData.value.userApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.orderApprovalAccounts!=undefined && taskSettingsData.value.orderApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.orderApprovalAccounts.length; i++) {
              selectedOrderAccounts.value.push(taskSettingsData.value.orderApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.discountApprovalAccounts!=undefined && taskSettingsData.value.discountApprovalAccounts.length !=0) {
          for(let i=0; i<taskSettingsData.value.discountApprovalAccounts.length; i++) {
              selectedDiscountAccounts.value.push(taskSettingsData.value.discountApprovalAccounts[i])
          }
      }
      if(taskSettingsData.value.userApproval!=undefined && taskSettingsData.value.userApproval == 'yes') {
          showUserApproveDiv.value = true;
      }      
      if(taskSettingsData.value.orderApproval!=undefined && taskSettingsData.value.orderApproval == 'yes') {
          showCreateOrderDiv.value = true;
      }
      if(taskSettingsData.value.dicountApproval!=undefined && taskSettingsData.value.dicountApproval == 'yes') {
          showCreateDiscountDiv.value = true;
      }
      if(taskSettingsData.value.adminViewAssignedTasks!=undefined && taskSettingsData.value.adminViewAssignedTasks == 'yes') {
        showAdminViewAssignedTasksDiv.value = true;
      }
      if(taskSettingsData.value.adminToGroups!=undefined) {
        adminToGroups.value = taskSettingsData.value.adminToGroups
      }  
      if(taskSettingsData.value.adminToassignedAllLevels!=undefined) {
        adminToassignedAllLevels.value = taskSettingsData.value.adminToassignedAllLevels
      }  
    })

    const getTaskDetails = () => {
        // $store.dispatch("fetchapprovalTasks"); 
      if (subaccDetailsGetter.value != undefined && subaccDetailsGetter.value.length != 0) {
        if(subaccDetailsGetter.value!=undefined && subaccDetailsGetter.value.length !=0) {
          for(let i=0; i<subaccDetailsGetter.value.length-1; i++) {
             subAccOptions.value[0].children.push({id:subaccDetailsGetter.value[i].id, label:subaccDetailsGetter.value[i].name})
          }
        }
      }
        settingsStore.fetchapprovalTasks();      
    }
    const saveApprovalSettings = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      errorShow.value = false;
      var form = document.getElementById('taskSettingsForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
        var accounts = [];
        if(selectedAccounts.value.length==1 && selectedAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                accounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            accounts = selectedAccounts.value;
        }
        var orderAccounts = [];
        if(selectedOrderAccounts.value.length==1 && selectedOrderAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                orderAccounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            orderAccounts = selectedOrderAccounts.value;
        }
        var discAccounts = [];
        if(selectedDiscountAccounts.value.length==1 && selectedDiscountAccounts.value[0] == 'selectAll' &&subAccOptions.value[0].children.length!=0) {
            for(let i =0;i<subAccOptions.value[0].children.length;i++) {
                discAccounts.push(subAccOptions.value[0].children[i].id)
            }
        } else {
            discAccounts = selectedDiscountAccounts.value;
        }
        postData.userApprovalIds = accounts;
        postData.orderApprovalIds = orderAccounts;
        postData.discountApprovalIds = discAccounts;
      // $store.dispatch("taskSettingsSubmit", postData).then(
      settingsStore.taskSettingsSubmit(postData).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const toggleAccountsDiv = (event) => {
         if(event.target.checked == true) {
            showUserApproveDiv.value = true;
        } else {
            showUserApproveDiv.value = false;
        }
    }
    const toggleOrdersDiv = (event) => {
         if(event.target.checked == true) {
            showCreateOrderDiv.value = true;
        } else {
            showCreateOrderDiv.value = false;
        }
    }
    const toggleDicountDiv = (event) => {
         if(event.target.checked == true) {
            showCreateDiscountDiv.value = true;
        } else {
            showCreateDiscountDiv.value = false;
        }
    }
    const toggleAdminViewAssignedTasksDiv = (event) => {
         if(event.target.checked == true) {
            showAdminViewAssignedTasksDiv.value = true;
        } else {
            showAdminViewAssignedTasksDiv.value = false;
        }
    }
    const addAssignTasksFn = ()=> {
      const postData = {
        adminClassificationId: postassigned1.value,
        level: JSON.stringify(postassigned2.value)
      };
      settingsStore
        .addAssignTasksFn(postData)  
        .then(response => {
          console.log(response);
          if (response.status == "success") {
            setTimeout(() => {
              settingsStore.fetchapprovalTasks()
              showAddAssign.value = false;
            }, 1000);
          }
          successtoaster.value = true;
          successMessage.value = response.message;
          setTimeout(() => {
            successtoaster.value = false;
          }, 2000);
        })
        .catch(error => {
          setTimeout(() => {
            errortoaster.value = true;
            errorMessage.value = error;
          }, 4000);
        });
    };
    const deleteAssignedTaskAdmin = (id)=> {
      const postData = {
        adminClassificationId: id
      };
      settingsStore
        .deleteAssignedTaskAdmin(postData)  
        .then(response => {
          console.log(response);
          if (response.status == "success") {
            var index = adminToassignedAllLevels.value.findIndex(
              e => e.id == id
            );
            if (index != -1) {
              adminToassignedAllLevels.value.splice(index, 1);
            }
          }
          successtoaster.value = true;
          successMessage.value = response.message;
          setTimeout(() => {
            successtoaster.value = false;
          }, 2000);
        })
        .catch(error => {
          setTimeout(() => {
            errortoaster.value = true;
            errorMessage.value = error;
          }, 4000);
        });
    };

</script>