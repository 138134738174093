<template>
  <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-show="switchAccountModalShow" style="display:flex">
    <div class="jazeModal unifiConfirm jazeModal--medium">
      <div class="jazeModal__header pb-0">
        <div class="jazeHeader jazeHeader--centered">
          <div class="jazeHeader__title" v-if="edition =='isp'">Switch Zone</div>
          <div class="jazeHeader__title" v-else>Switch Account</div>
          <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="onclosesubmit">
            <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
              <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
            </g>
          </svg>
        </div>
      </div>
      <div class="jazeModal__main" style="height:400px;overflow:auto">
        <form class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="jazeModalContent pb-1">
            <fieldset class="appFieldset appFieldset--quiet">
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div v-if="edition =='isp'">Select the zone to switch to</div>
              <div v-else>Select the account to switch to</div>
              <br />
              <treeselect v-model="accountToSwitch" :options="zoneOptions"  :always-open="true" :searchable="true" virtual  :virtual-row-height="25" :default-expand-level="Infinity" :placeholder="(zoneOptions != undefined && zoneOptions.length == 0) ? 'Loading...' : 'Search'" noOptionsText="Loading data from server" disableFuzzyMatching></treeselect>
            </fieldset>
          </div>
        </form>
      </div>
      <div class="jazeModal__footer">
        <div class="jazeButtonGroup jazeButtonGroup--cozy">
          <button class="jazeButton jazeButton--secondary" @click="onclosesubmit">Cancel</button>
          <button class="jazeButton jazeButton--primary submitForm" v-if="edition =='isp'" @click="switchAccount"> Switch Zone </button>
          <button class="jazeButton jazeButton--primary submitForm" v-else @click="switchAccount">Switch Account</button>
        </div>
      </div>
    </div>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,toRef,defineProps,defineEmits,computed,watch,onMounted,onBeforeUnmount } from 'vue'
import axios from "@/api/index";
import Treeselect from "jaze-vue3-treeselect";
import qs from "qs";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { useRouter } from 'vue-router';
import   '../../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
import { uselayoutStore } from '@/store/layoutstore'
import {storeToRefs} from "pinia";

  const emit = defineEmits(['extend-clicked-show-detail']);
  const props = defineProps(["edition", "allSubZones", "authDetails"]);

  const router = useRouter();
  const edition=toRef(props,'edition');
  const allSubZones=toRef(props,'allSubZones');
  const authDetails=toRef(props,'authDetails');
  const accountToSwitch=ref(null);
  const zoneOptions=ref([]);
  const switchAccountModalShow=ref(true);
  const formClass=ref("");
  const errorShow=ref(false);
  const errorMessage=ref("");
  const errortoaster=ref(false);
  const layoutStore = uselayoutStore() 
  const { generateSubAccountsTreeVue } = storeToRefs(layoutStore)
  const generateSubAccountsTreeVueGetter= computed(() => generateSubAccountsTreeVue.value);
  onMounted(() => {
    getAccDetails()
  })
  watch(generateSubAccountsTreeVueGetter,()=>{
    if (generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0 && zoneOptions.value != undefined && zoneOptions.value.length == 0) {
      zoneOptions.value = generateSubAccountsTreeVueGetter.value;
    }
  });
  const getAccDetails = () => {
    if(generateSubAccountsTreeVueGetter.value != undefined && generateSubAccountsTreeVueGetter.value.length > 0) {
      zoneOptions.value = generateSubAccountsTreeVueGetter.value;
    }else{
      layoutStore.fetchGenerateSubAccountsTreeVue();
    }
  }
  const onclosesubmit = () => {
    switchAccountModalShow.value = false;
    emit("extend-clicked-show-detail", false);
  }
  const switchAccount = () => {
    const formData = new FormData();
    if (accountToSwitch.value == null || accountToSwitch.value == "") {
      errorMessage.value = "No account has been selected";
      errorShow.value = true;
      formClass.value = "is-form-validation-visible";
      return;
    } else {
      formData.append("account_id",accountToSwitch.value);
      JsLoadingOverlay.show({
                  'spinnerIcon': 'ball-clip-rotate',
                  "overlayOpacity": "0.6",
                  "spinnerSize": "2x",
                  "lockScroll": true,
              });
      axios
        .post("/dashboard/switch_account", formData)
        .then((response) => {
          if (response.data.status == "success") {
            localStorage.setItem("token", response.data.api);
            JsLoadingOverlay.hide();
            emit("extend-clicked-show-detail", false);
            let pathCurrent = router.currentRoute.value.path;
            window.location.href += pathCurrent.substring(1);
            location.reload();
          } else {
            errortoaster.value = true;
            errorMessage.value = response.data.message;
            return;
          }
        })
        .catch((error) => {
          JsLoadingOverlay.hide();
          console.log(error);
        });
    }
  }

</script>
