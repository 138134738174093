<template>
  <div class="jazeMainPanel" style="height: 100%;" v-if="showMessage">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="tallyConfigForm" id="tallyConfigForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">Tally Configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Company Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="companyName" autofocus="true" v-model="companyName" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">State</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="stateName" v-model="stateName" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Pincode</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="pincode" v-model="pincode" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Voucher Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="voucherName" v-model="voucherName" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">GSTIN</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="Gstin" v-model="Gstin" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">CGST%</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="cgst" v-model="cgst" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">SGST%</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="sgst" v-model="sgst" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">IGST%</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="igst" v-model="igst" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Goods Tax%</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="goodsTax" v-model="goodsTax" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Other Tax%</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="otherTax" v-model="otherTax" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">HSN Code</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="hsn" v-model="hsn" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Sales Voucher Type</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="vch_type" v-model="vch_type" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Sales Ledger</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="sales_ledger_name" v-model="sales_ledger_name" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">SGST Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="sgst_ledger_name" v-model="sgst_ledger_name" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">CGST Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="cgst_ledger_name" v-model="cgst_ledger_name" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">IGST Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="igst_ledger_name" v-model="igst_ledger_name" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Rounded Off Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="round_off_product" v-model="round_off_product" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Discount Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="discount_product" v-model="discount_product" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Group Ledger Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="group_ledger" v-model="group_ledger" />
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <label class="appLabel appLabel--primary appLabel--boxInline">
                    Enable Export Payment to Tally
                  </label>
                </div>
                <div class="col--sm1 appFormGroup appFormGroup--cozy">
                  <input checked type="checkbox" name="enablePaymentTallyConfig" v-model="enablePaymentTallyConfig" true-value="on" false-value="off">
                </div>
              </div>

              <div v-if="enablePaymentTallyConfig=='on'">
                <div class="appRow">
                  <div class="col--sm3">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Voucher Type</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="payment_vch_type" v-model="payment_vch_type" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Cash Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="cash_ledger" v-model="cash_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Cheque Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="cheque_ledger" v-model="cheque_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Demand Draft Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="demand_draft_ledger" v-model="demand_draft_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Swipe Machine Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="swipe_machine_ledger" v-model="swipe_machine_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Enach Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="enach_ledger" v-model="enach_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">EDC Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="edc_ledger" v-model="edc_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Payment Gateway Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="payment_gateway_ledger" v-model="payment_gateway_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Debit Card Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="debit_card_ledger" v-model="debit_card_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Credit Card Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="credit_card_ledger" v-model="credit_card_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">TDS Payment Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="tds_payment_ledger" v-model="tds_payment_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Online Payment Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="online_payment_ledger" v-model="online_payment_ledger" />
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm3 mandatory-field">
                    <label class="appLabel appLabel--primary appLabel--boxAlign">Bank Transfer Ledger</label>
                  </div>
                  <div class="col--sm5 appFormGroup appFormGroup--cozy">
                    <input class="input--full" type="text" name="bank_transfer_ledger" v-model="bank_transfer_ledger" />
                  </div>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <label class="appLabel appLabel--primary appLabel--boxInline">
                    Party Name Format
                  </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect v-model="party_name_format" :options="partyNameFormatOption" :searchable="true" :clearable="false" :default-expand-level="1" :disable-branch-nodes="true" placeholder="Search" name="party_name_format"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <label class="appLabel appLabel--primary appLabel--boxInline">
                    State Name Format
                  </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect v-model="state_name_format" :options="stateNameFormatOption" :searchable="true" :clearable="false" :default-expand-level="1" :disable-branch-nodes="true" placeholder="Search" name="state_name_format"></treeselect>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-if="tallyConfigDetails.edition == 'isp'">SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" :title="subzoneType" v-else>SubAccounts Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="allSubAccountsModalShow=true" v-if="tallyConfigDetails.edition == 'isp'">Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="allSubAccountsModalShow=true" v-else>Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveTallyConfig">Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title">Save</div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="tallyConfigDetails.edition =='isp'">Allow/Deny sub-zones to change tally configuration</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change tally configuration</label>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" name="switchcheckbox" id="switchcheckbox" :checked="tallyConfigDetails.subAccountsCanNotChangeTallyInformation == 'Yes' ? true : false" />
                          <label for="switchcheckbox"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="tallyConfigDetails.edition =='isp'">Note: If it is 'ON', sub-zones can not change the tally configuration.</div>
                    <div class="appType--micro appType--quiet italicized" v-else>Note: If it is 'ON', sub-accounts can not change the tally configuration.</div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="allSubAccountsModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title">Save</div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="allSubAccountsModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class>
                      <label class="jazeLabel jazeLabel--primary">Are You sure want to use this settings to all sub zones?</label>
                      <br />
                      <br />
                      <div class="appType--micro appType--quiet italicized" v-if="tallyConfigDetails.edition =='isp'">Note: Once you use these settings in all sub-zones, you cannot undo it. If you want to undo it then it has to be done manually .</div>
                      <div class="appType--micro appType--quiet italicized" v-else>Note: If it is 'ON', sub-accounts can not change the tally configuration.</div>
                      <br />
                      <treeselect :multiple="true" :always-open="true" v-model="selectedAccVal" :options="subAccOptions" :searchable="true" :clearable="false" :default-expand-level="1" :disable-branch-nodes="true"></treeselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="allSubAccountsModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addToAllSubAccounts">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage="successMessage" :sucesstoasterShow="successtoaster" v-if="successtoaster"></SuccessToaster>
    <ErrorToaster :errorMessage="errorMessage" :errortoasterShow="errortoaster" v-if="errortoaster"></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import { overrideIsSelected } from "@/overrides/vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { uselayoutStore } from '@/store/layoutstore'
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const formClass = ref( "" )
  const subzoneType = ref( "" )
  const allSubAccountsModalShow = ref( false )
  const subZoneModalShow = ref( false )
  const selectedAccVal = ref( null )
  const subAccOptions = ref( [
          {
            id: "selectAll",
            label: "Select All",
            children: []
          }
        ] )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const companyName = ref( "" )
  const stateName = ref( "" )
  const pincode = ref( "" )
  const voucherName = ref( "" )
  const Gstin = ref( "" )
  const cgst = ref( "" )
  const sgst = ref( "" )
  const igst = ref( "" )
  const goodsTax = ref( "" )
  const otherTax = ref( "" )
  const hsn = ref( "" )
  const vch_type = ref( "" )
  const sales_ledger_name = ref( "" )
  const cgst_ledger_name = ref( "" )
  const sgst_ledger_name = ref( "" )
  const igst_ledger_name = ref( "" )
  const round_off_product = ref( "" )
  const discount_product = ref( "" )
  const group_ledger = ref( "" )
  // const tallyConfigDetails = ref( [] )
  const partyNameFormatOption = ref( [
          {
            id: "",
            label: "Select Format",
            children: []
          }
        ] )
  const payment_vch_type = ref( "" )
  const enablePaymentTallyConfig = ref( "off" )
  const party_name_format = ref( "1" )
  const stateNameFormatOption = ref( [
          {
            id: "",
            label: "Select Format",
            children: []
          }
        ] )
  const state_name_format = ref( "short" )
  const cash_ledger = ref( "" )
  const cheque_ledger = ref( "" )
  const demand_draft_ledger = ref( "" )
  const swipe_machine_ledger = ref( "" )
  const enach_ledger = ref( "" )
  const edc_ledger = ref( "" )
  const payment_gateway_ledger = ref( "" )
  const debit_card_ledger = ref( "" )
  const tds_payment_ledger = ref( "" )
  const online_payment_ledger = ref( "" )
  const bank_transfer_ledger = ref( "" )
  const credit_card_ledger = ref( "" )
  const showMessage = ref( false )        
  const settingsStore = usesettingsStore()
  const { tallyConfigDetails } = storeToRefs(settingsStore)
  const tallyConfigGetter = computed(() => tallyConfigDetails.value);
  const layoutStore = uselayoutStore()
  const { subaccDetails } = storeToRefs(layoutStore)
  const subaccDetailsGetter= computed(() => subaccDetails.value);

  onMounted(() => {
    getConfigDetails();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    tallyConfigDetails.value = tallyConfigGetter.value;
    if (
      tallyConfigDetails.value.subAccountsCanNotChangeTallyInformation == "Yes"
    ) {
      subzoneType.value = "Enabled";
    } else {
      subzoneType.value = "Disabled";
    }
    if (tallyConfigDetails.value.tallyInfo != undefined) {
      companyName.value = tallyConfigDetails.value.tallyInfo.companyName;
      stateName.value = tallyConfigDetails.value.tallyInfo.stateName;
      pincode.value = tallyConfigDetails.value.tallyInfo.pincode;
      voucherName.value = tallyConfigDetails.value.tallyInfo.voucherName;
      Gstin.value = tallyConfigDetails.value.tallyInfo.Gstin;
      cgst.value = tallyConfigDetails.value.tallyInfo.cgst;
      sgst.value = tallyConfigDetails.value.tallyInfo.sgst;
      igst.value = tallyConfigDetails.value.tallyInfo.igst;
      goodsTax.value = tallyConfigDetails.value.tallyInfo.goodsTax;
      otherTax.value = tallyConfigDetails.value.tallyInfo.otherTax;
      hsn.value = tallyConfigDetails.value.tallyInfo.hsn;
      vch_type.value = tallyConfigDetails.value.tallyInfo.vch_type;
      sales_ledger_name.value = tallyConfigDetails.value.tallyInfo.sales_ledger_name;
      sgst_ledger_name.value = tallyConfigDetails.value.tallyInfo.sgst_ledger_name;
      cgst_ledger_name.value = tallyConfigDetails.value.tallyInfo.cgst_ledger_name;
      igst_ledger_name.value = tallyConfigDetails.value.tallyInfo.igst_ledger_name;
      round_off_product.value = tallyConfigDetails.value.tallyInfo.round_off_product;
      discount_product.value = tallyConfigDetails.value.tallyInfo.discount_product;
      group_ledger.value = tallyConfigDetails.value.tallyInfo.group_ledger;
      payment_vch_type.value = tallyConfigDetails.value.tallyInfo.payment_vch_type;
      enablePaymentTallyConfig.value = tallyConfigDetails.value.tallyInfo.enablePaymentTallyConfig;
      party_name_format.value = tallyConfigDetails.value.tallyInfo.party_name_format;
      state_name_format.value = tallyConfigDetails.value.tallyInfo.state_name_format;
      //payment ledger mapping
      cash_ledger.value = tallyConfigDetails.value.tallyInfo.cash_ledger;
      cheque_ledger.value = tallyConfigDetails.value.tallyInfo.cheque_ledger;
      demand_draft_ledger.value = tallyConfigDetails.value.tallyInfo.demand_draft_ledger;
      swipe_machine_ledger.value = tallyConfigDetails.value.tallyInfo.swipe_machine_ledger;
      enach_ledger.value = tallyConfigDetails.value.tallyInfo.enach_ledger;
      edc_ledger.value = tallyConfigDetails.value.tallyInfo.edc_ledger;
      payment_gateway_ledger.value = tallyConfigDetails.value.tallyInfo.payment_gateway_ledger;
      debit_card_ledger.value = tallyConfigDetails.value.tallyInfo.debit_card_ledger;
      tds_payment_ledger.value = tallyConfigDetails.value.tallyInfo.tds_payment_ledger;
      online_payment_ledger.value = tallyConfigDetails.value.tallyInfo.online_payment_ledger;
      bank_transfer_ledger.value = tallyConfigDetails.value.tallyInfo.bank_transfer_ledger;
      credit_card_ledger.value = tallyConfigDetails.value.tallyInfo.credit_card_ledger;
    }
    if (
      subaccDetailsGetter.value != undefined &&
      subaccDetailsGetter.value.length != 0
    ) {
      for (let i = 0; i < subaccDetailsGetter.value.length-1; i++) {
        var deactivated = subaccDetailsGetter.value[i].deactivated == 0 ? " (Deactivated)" : "";
        subAccOptions.value[0].children.push({
          id: subaccDetailsGetter.value[i].id,
          label: subaccDetailsGetter.value[i].name + deactivated, 
        });
      }
    }
    if (
      tallyConfigDetails.value.partyNameFormatOption != undefined &&
      tallyConfigDetails.value.partyNameFormatOption.length != 0
    ) {
      partyNameFormatOption.value[0].children = tallyConfigDetails.value.partyNameFormatOption;
    }
    if (
      tallyConfigDetails.value.stateNameFormatOption != undefined &&
      tallyConfigDetails.value.stateNameFormatOption.length != 0
    ) {
      stateNameFormatOption.value[0].children = tallyConfigDetails.value.stateNameFormatOption;
    }
  })


  const checkForm = (e, postData) => {
    if (postData.cash_ledger == "") {
      errorMessage.value = "Cash Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.cheque_ledger == "") {
      errorMessage.value = "Cheque Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.demand_draft_ledger == "") {
      errorMessage.value = "Demand Draft Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.swipe_machine_ledger == "") {
      errorMessage.value = "Swipe Machine is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.enach_ledger == "") {
      errorMessage.value = "Enach Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.edc_ledger == "") {
      errorMessage.value = "EDC Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.payment_gateway_ledger == "") {
      errorMessage.value = "Payment Gateway Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.debit_card_ledger == "") {
      errorMessage.value = "Debit Card Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.tds_payment_ledger == "") {
      errorMessage.value = "TDS Payment Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.online_payment_ledger == "") {
      errorMessage.value = "Online Payment Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.bank_transfer_ledger == "") {
      errorMessage.value = "Bank Transfer Ledger is Required";
      errorShow.value = true;
      return "error";
    }
    if (postData.credit_card_ledger == "") {
      errorMessage.value = "Credit Card Ledger is Required";
      errorShow.value = true;
      return "error";
    }
  }
  const getConfigDetails = () => {
    if (Object.keys(tallyConfigGetter.value).length == 0) {
      // $store.dispatch("fetchTallyDetails");
      settingsStore.fetchTallyDetails();
    } else {
      tallyConfigDetails.value = tallyConfigGetter.value;
    }
    setTimeout(() => {
      showMessage.value = true;
    }, 150);
  }
  const allowSubZones = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    if (document.getElementById("switchcheckbox").checked == true) {
      var check = "on";
    } else {
      var check = "off";
    }
    var data = {
      switchCheckBoxValue: check,
      attribute: "subAccountsCanNotChangeTallyInformation",
      type: "tally"
    };
    // $store.dispatch("saveSubAccountPermissionSettings", data).then(
    settingsStore.saveSubAccountPermissionSettings(data).then(  
      response => {
        subZoneModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const addToAllSubAccounts = () => {
    successtoaster.value = false;
    errortoaster.value = false;
    var accounts = [];
    if (
      selectedAccVal.value.length == 1 &&
      selectedAccVal.value[0] == "selectAll" &&
      subAccOptions.value[0].children.length != 0
    ) {
      for (let i = 0; i < subAccOptions.value[0].children.length; i++) {
        accounts.push(subAccOptions.value[0].children[i].id);
      }
    } else {
      accounts = selectedAccVal.value;
    }
    var data = {
      value: accounts
    };
    // $store.dispatch("addTallyToAllSubZones", data).then(
    settingsStore.addTallyToAllSubZones(data).then(  
      response => {
        allSubAccountsModalShow.value = false;
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }
  const saveTallyConfig = (e) => {
    successtoaster.value = false;
    errortoaster.value = false;
    e.preventDefault();
    errorShow.value = false;
    var form = document.getElementById('tallyConfigForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    var status = checkForm(e, postData);
    if (status == "error") {
      formClass.value =
        "appForm appForm--cozy is-validation-hidden is-form-validation-visible";
      errortoaster.value = true;
      setTimeout(
        function() {
          errortoaster.value = false;
        }.bind(this),
        3000
      );
      return;
    }
    
    // $store.dispatch("tallyConfigurationSubmit", postData).then(
    settingsStore.tallyConfigurationSubmit(postData).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          2000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          15000
        );
        console.log(error);
      }
    );
  }

</script>