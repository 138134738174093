<template>
  <div class="jazeMainPanel">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader">
          <div class="unifiSettingsHeader__left">
            <div class="unifiSettingsHeader__title"> SMS Management </div>
          </div>
        </div>
        <form ref="smsConfigForm" id="smsConfigForm" class="appForm appForm--cozy">
        <fieldset class="appFieldset appFieldset--quiet">
          <div class="unifiSettingsSection">            
            <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
              <thead>
                <tr>
                  <th class="center"> Zone Name </th>
                  <th class="center"> Total No of SMS Used </th>
                  <th class="center"> Total No of SMS Remaining </th>
                  <th class="center"> SMS Limit </th>
                </tr>
              </thead>
              <tbody class="jazeTableBody">
                <tr v-if="smsManagementCloudData.smsDetails != undefined && smsManagementCloudData.smsDetails.length !=0" v-for="smsDetail in smsManagementCloudData.smsDetails">
                  <td> {{smsDetail.companyName}} </td>
                  <td> {{smsDetail.totalSmsUsed}} </td>
                  <td v-if="smsDetail.unlimitedSMS != undefined && smsDetail.unlimitedSMS == 'no'"> {{smsDetail.totalSmsRemain}} </td>
                  <td v-else> UnLimited </td>
                  <td > <input type="text" :name="smsDetail.accountId" v-model="smsDetail.totalSmsLimit" /> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveSmsManagement"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="addLimitModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Add SMS Limit </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="addLimitModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="addSMSLimitForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Enter SMS Limit</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <input type="number" class="input--full" name="smsCount" v-model="smsCount" required oninvalid="setCustomValidity(' ')" oninput="setCustomValidity('')" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm4">
                              <label class="appLabel appLabel--boxInline">Unlimited SMS</label>
                            </div>
                            <div class="col--sm8 appFormGroup appFormGroup--cozy">
                              <input type="checkbox" name="unlimitedSMS" v-model="unlimitedSMS" />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="addLimitModal=false">Close</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addSmsLimitConfirm">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  // const smsManagementCloudData = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const addLimitModal = ref( false )
  const accId = ref( "" )
  const smsCount = ref( "" )
  const unlimitedSMS = ref( true )

  const settingsStore = usesettingsStore()
  const { smsManagementCloudData } = storeToRefs(settingsStore);
  const cloudSmsManagementGetter = computed(() => smsManagementCloudData.value);

  onMounted(() => {
    getSmsMgtData();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watchEffect(() => {
    smsManagementCloudData.value = cloudSmsManagementGetter.value;
    console.log("smsManagementCloudData", smsManagementCloudData.value);
  })


  const getSmsMgtData = () => {
    if (Object.keys(cloudSmsManagementGetter.value).length == 0) {
      // $store.dispatch("fetchsmsManagementCloudData");
      settingsStore.fetchsmsManagementCloudData();
    } else {
      smsManagementCloudData.value = cloudSmsManagementGetter.value;
    }
  }
  const addSmsLimit = (smsDetail) => {
    accId.value = smsDetail.accountId;
    smsCount.value = "";
    unlimitedSMS.value = true;
    addLimitModal.value = true;
  }
  const checkForm = (postData) => {
    if (postData.smsCount == "") {
      errorMessage.value = "SMS limit is Required";
      errorShow.value = true;
      return "error";
    }
  }
  const saveSmsManagement = (e) => {
    e.preventDefault();
    successtoaster.value = false;
    errortoaster.value = false;
    var form = document.getElementById('smsConfigForm');
    const formData = new FormData(form);
    const postData = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(postData, { [key]: val });
    }
    errorShow.value = false;
    // $store.dispatch("smsManagementCloudSubmit", postData).then(
    settingsStore.smsManagementCloudSubmit(postData).then(  
      response => {
        successtoaster.value = true;
        successMessage.value = response;
        setTimeout(
          function() {
            successtoaster.value = false;
          }.bind(this),
          5000
        );
      },
      error => {
        errortoaster.value = true;
        errorMessage.value = error;
        setTimeout(
          function() {
            errortoaster.value = false;
          }.bind(this),
          1500
        );
        console.log(error);
      }
    );
  }

</script>
