<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="unifiSettingsContainer">
      <div>
        <form class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader ">
              <div class="unifiSettingsHeader__title">Payment Virtual Account</div>
          </div>&nbsp;
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="form-group appRow">
              <div class="col--sm2">
                  <label class="appLabel appLabel--primary">Vendor</label>
              </div>
              <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <select class="width-full" name="vendorType" v-model="vendorType" @change="vendorTypeData">
                      <option value="icici">ICICI</option>
                      <option value="cashfree">Cashfree</option>
                  </select>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div v-if="vendorType == 'cashfree'">
        <div class="form-group appRow">
        <div class="col--sm2">
            <label class="appLabel appLabel--primary">Client Secret</label>
        </div>
        <div class="col--sm3 appFormGroup appFormGroup--cozy">
            <input class="input--full " type="text" name="clientsecret" autofocus="true" v-model="clientSecret">
        </div>
        </div>

      </div>
      <div v-if="vendorType1 == 'icici'">
        <form class="appForm appForm--cozy is-validation-hidden">
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="form-group appRow">
              <div class="col--sm2">
                  <label class="appLabel appLabel--primary">Encryption Type</label>
              </div>
              <div class="col--sm3 appFormGroup appFormGroup--cozy">
                  <select class="width-full" name="encryptionType" v-model="encryptionType" @change="encryptionTypeChange">
                      <option value="asymmetric">Asymmetric</option>
                      <option value="hybrid">Hybrid</option>
                  </select>
              </div>
            </div>
          </fieldset>
        </form>
        <label class="appLabel appLabel--primary">Uploaded Certificates and Keys</label><br><br>
        <div v-if="apiDetails.certificates != undefined && apiDetails.certificates.length!=0">
        <div class="appRow" v-for='(certificate,index) in apiDetails.certificates' :key="certificate">
          <div class="col--sm3" >
            <label class="appLabel appLabel--primary" v-if="index==0"> Certificates </label>
          </div>
          <div class="col--sm5 appFormGroup appFormGroup--cozy">
            <label class="appLabel"> {{certificate}} </label>
          </div>
        </div> 
        </div>
        <div class="appRow" v-if="apiDetails.certificates != undefined && (apiDetails.certificates==''|| apiDetails.certificates.length == 0)">
          There are no Uploaded Certificates.
          <br><br>
        </div>       
      </div>
      <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
        <form ref="certificateForm" class="row col--sm12" role="form" enctype="multipart/form-data">
            <div class="col--sm2"  v-if="vendorType1 == 'icici'">
                  <input style="display:none" ref="changeCertFile" type="file" @change="previewImageCert" name="crtFile">
                  <button class='appMainButton busyToggle' @click="chooseImageCert">
                    <span v-if='selectCerFile'> Select cer file </span>
                    <span v-else>Change </span>
                  </button>&emsp;
                  <div v-if="certImage != ''">
                    {{cerFileData.name}}
                  </div>
            </div>
            <div class="col--sm2"  v-if="vendorType1 == 'icici'">
                  <input style="display:none" ref="changeKeyFile" type="file" @change="previewImageKey" name="keyFile">
                  <button class='appMainButton busyToggle' @click="chooseImageKey">
                    <span v-if='selectKeyFile'> Select key file </span>
                    <span v-else>Change </span>
                  </button>&emsp;
                  <div v-if="keyImage != ''">
                    {{keyFileData.name}}
                  </div>
            </div>
            <div v-if="vendorType1 == 'icici'" class="col--sm5">
            </div>
            <div  v-if="vendorType1 != 'icici'" class="col--sm8" >
            </div>

            <div class="col--sm2" >
                  <button  v-if="vendorType1 == 'icici'" class='appMainButton appMainButton--primary' type="button" @click="uploadFiles">
                    <span> Upload Files </span>
                  </button>&emsp;
              <!-- <button class="appMainButton appMainButton--primary" @click="changeApiShow=true"><span>Change API Key</span></button> -->
            </div>
            <div class="col--sm1">
            <button class='appMainButton appMainButton--primary' type="button" @click="saveFiles">
                  <span> save </span>
            </button>
            </div>
        </form>
      </div> <br><br>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script setup>
import { ref,computed,watch,onMounted,onBeforeUnmount } from 'vue';
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import axios from "@/api/index";
import qs from "qs";

import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'

  const apiDetails = ref( [] )
  const formClass = ref( "" )
  const errorShow = ref( false )
  const errorMessage = ref( "" )
  const successtoaster = ref( false )
  const successMessage = ref( "" )
  const errortoaster = ref( false )
  const cerFileData = ref( "" )
  const keyFileData = ref( "" )
  const selectCerFile = ref( true )
  const selectKeyFile = ref( true )
  const certImage = ref( "" )
  const keyImage = ref( "" )
  const vendorType = ref('icici')
  const vendorType1 = ref('icici')
  const clientSecret = ref( null )
  const encryptionType  = ref( 'asymmetric' )
  const changeCertFile = ref( null )
  const changeKeyFile = ref( null )
  const settingsStore = usesettingsStore()
  const apiData = storeToRefs(settingsStore)
  const apiDataGetter = computed(() => apiData.apiData.value);
  const postmanCollectionLink = ref( "" )

  onMounted(() => {
    getApiDetails();
  })
  onBeforeUnmount(() => {
    settingsStore.clearSettingsState();
  })

  watch(apiDataGetter,() => {
    apiDetails.value = apiDataGetter.value;
    if(apiDetails.value.encryptionType != null && apiDetails.value.encryptionType != undefined && apiDetails.value.encryptionType != '' ){
      if(apiDetails.value.encryptionType['Setting']['value'] == 'asymmetric'){
        encryptionType.value = 'asymmetric';
      }
      if(apiDetails.value.encryptionType['Setting']['value'] == 'hybrid'){
        encryptionType.value = 'hybrid';
      }
    }
    if(apiDetails.value.data != null){
      vendorType.value = apiDetails.value.data.vendorName;
      vendorType1.value = apiDetails.value.data.vendorName;
    }
    if(apiDetails.value.data != null){
      clientSecret.value = apiDetails.value.data.clientSecret;
    }
    if(apiDetails.value.postmanCollectionLink != null && apiDetails.value.postmanCollectionLink != undefined && apiDetails.value.postmanCollectionLink != '' ) {
      postmanCollectionLink.value = apiDetails.value.postmanCollectionLink;
    }
  })

  const getApiDetails = () => {
    if(apiDataGetter.value == null || apiDataGetter.value == undefined || apiDataGetter.value == ''){
      // if (Object.keys(apiDataGetter.value).length == 0) {
        // $store.dispatch("fetchApiDetails");
        settingsStore.fetchApiDetails();
      // }
    }
      else {
      apiDetails.value = apiDataGetter.value;
      if(apiDetails.value.encryptionType != null && apiDetails.value.encryptionType != undefined && apiDetails.value.encryptionType != '' ){
        if(apiDetails.value.encryptionType['Setting']['value'] == 'asymmetric'){
          encryptionType.value = 'asymmetric';
        }
        if(apiDetails.value.encryptionType['Setting']['value'] == 'hybrid'){
          encryptionType.value = 'hybrid';
        }
        if(apiDetails.value.data.vendorName != null){
          vendorType.value = apiDetails.value.data.vendorName;
          vendorType1.value = apiDetails.value.data.vendorName;
        }
        if(apiDetails.value.data.clientSecret != null){
          clientSecret.value = apiDetails.value.data.clientSecret;
        }
      }
      if(apiDetails.value.postmanCollectionLink != null && apiDetails.value.postmanCollectionLink != undefined && apiDetails.value.postmanCollectionLink != '' ) {
        postmanCollectionLink.value = apiDetails.value.postmanCollectionLink;
      }
    }
  }
  const chooseImageCert = (e) => {
    e.preventDefault();
    changeCertFile.value.click();
  }
  const chooseImageKey = (e) => {
    e.preventDefault();
    changeKeyFile.value.click();
  }
  const previewImageCert = (event) => {
    var input = event.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = e => {
        certImage.value = e.target.result;
        selectCerFile.value = false;
      };
      reader.readAsDataURL(input.files[0]);
      cerFileData.value = input.files[0];
    }
  }
  const previewImageKey = (event) => {
    var input = event.target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = e => {
        keyImage.value = e.target.result;
        selectKeyFile.value = false;
      };
      reader.readAsDataURL(input.files[0]);
      keyFileData.value = input.files[0];
    }
  }
  const vendorTypeData = ()=>{
    vendorType1.value = vendorType.value

  }
  const encryptionTypeChange = () =>{
    var data = {
        encryptionType: encryptionType.value
    };
    axios
        .post(
          "/settings/save_encryption_type",qs.stringify(data, {
            parseArrays: false
          }))
        .then(response => {
          if (response.data.status != "success") {
            errortoaster.value = true;
            errorMessage.value = response.data.message;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              3000
            );
            return;
          }
          successtoaster.value = true;
          successMessage.value = response.data.message;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            1500
          );
        })
        .catch(error => {
          console.log(error);
        });
  }
  const saveFiles = () =>{
    if(vendorType.value == "cashfree" && clientSecret.value == null ){
      errortoaster.value = true;
      errorMessage.value = " Client Secret cannot be empty";
      setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              3000
            );
            return;
    }
    if(vendorType.value == "cashfree" ){
      var data = {
        vendorName: vendorType.value,
        clientsecret: clientSecret.value
      };
      } else {
        var data = {
        vendorName: vendorType.value,
      };
      }
    
    axios
        .post(
          "/settings/save_virtual_payment_account",qs.stringify(data, {
            parseArrays: false
          }))
        .then(response => {
          if (response.data.status != "success") {
            errortoaster.value = true;
            errorMessage.value = response.data.message;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              3000
            );
            return;
          }
          successtoaster.value = true;
          successMessage.value = response.data.message;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            1500
          );
        })
        .catch(error => {
          console.log(error);
        });

  }
  const uploadFiles = () => {
      var form = document.getElementById('certificateForm');
      const formData = new FormData(form);
      var formArr = new Object();
      formArr.crtFile = cerFileData.value;
      formArr.keyFile = keyFileData.value;
      formData.append("form",formArr);
      axios
        .post(
          "/settings/upload_pub_and_pri_certificates",formData)
        .then(response => {
          if (response.data.status != "success") {
            errortoaster.value = true;
            errorMessage.value = response.data.message;
            setTimeout(
              function() {
                errortoaster.value = false;
              }.bind(this),
              5000
            );
            return;
          }
          successtoaster.value = true;
          successMessage.value = response.data.message;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
          cerFileData.value = "";
          keyFileData.value = "";
          selectCerFile.value = false;
          selectKeyFile.value = false;
        })
        .catch(error => {
          console.log(error);
        });
  }

</script>